
import style from "./style.module.css";
import { Link, useHistory, } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { Form, Input, Button, Checkbox } from 'antd';

import ChangePass from 'components/changePassword'


const currentConfig = Auth.configure();
const ForgotPassword = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCodeView, setShowCodeView] = useState('true');
  const [loader, setLoader] = useState(false);

  const [values, setValues] = useState({ password: "", showPassword: false });
  const [confirmPass, setconfirmPass] = useState({ confirmPassword: "", showConfirmPassword: false });

  const continuee = (e) => {
    // event.preventDefault();
    setShowCodeView('false')
    forgotPassword(e.email);
    setUserName(e.email)
  };


  async function forgotPassword(username) {
    console.log(username);
    Auth.forgotPassword(username)
      .then(data => console.log(data))
      .catch(err => console.log(err));
  }

  const reSendOTP = () => {
    Auth.forgotPassword(userName)
      .then(data => console.log(data))
      .catch(err => console.log(err));
  }

  // const getConfirmationCode = () => {
  //   setLoader(true);
  //   console.log(code, password, confirmPassword);
  //   if (password == confirmPassword) {
  //     Auth.forgotPasswordSubmit(userName, code, password)
  //       .then(data => {
  //         console.log(data);
  //         setLoader(false)
  //         history.push("/");
  //         setShowCodeView('true')
  //       })
  //       .catch(err => {
  //         console.log(err);
  //         setLoader(false)
  //       });
  //   } else {
  //     setLoader(false);
  //     alert("password do not match")
  //   }
  // }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowConfirmPassword = () => {
    setconfirmPass({ ...confirmPass, showConfirmPassword: !confirmPass.showConfirmPassword });
  };

  const onFinish = (values: any) => {
    console.log('Success:', values);
    continuee(values)
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (

    <div className={style.div_container}>
      {showCodeView == 'true' ? null :
        <div className="alert alert-success" role="alert" style={{ width: 450 }}>
          Please check your email for verification code
        </div>
      }
      <div className={style.div_content}>
        <div className={style.div_image}>
          <div>
            {/* <p className="p_name">Welcome</p> */}
            <p className={style.p_name}>Forgot Password ?</p>
          </div>
          <div className={style.div_round}>
            <img
              src="Images/logo.png"
              className={style.imgFluid}
              alt="Doccure Login"
            />
          </div>
        </div>

        <div className={style.div_form}>
          {
            showCodeView == 'true' ? (
              <>
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <div className="form-group form-focus">
                    <Form.Item name="email" rules={[{ required: true, type: 'email', message: 'Please enter your email!' }]} >
                      <Input size="large" placeholder="Email" />
                    </Form.Item>
                    {/* <input type="email" className="form-control floating" onChange={(text: any) => setEmail(text.target.value)} />
                  <label className="focus-label">Email</label> */}
                  </div>

                  {
                    !loader ?
                      <button
                        className={`${style.btnDashboard} btn btn-primary btn-block`}
                      // type="submit"
                      // onClick={continuee}
                      >
                        Continue
                      </button> :
                      <button
                        className="btn btn-primary btn-block"
                      // type="submit"
                      >
                        <Spinner animation="border" role="status" style={{ color: "white", width: 25, height: 25 }} />
                      </button>
                  }
                </Form>
              </>
            ) : (
              <>
                <ChangePass username={userName} reSendOTP={reSendOTP} />
                {/* <div className="form-group form-focus">
                  <input type="code" className="form-control floating" onChange={(text: any) => setCode(text.target.value)} />
                  <label className="focus-label">Code</label>
                </div>
                <div className="form-group form-focus">
                  <input type={values.showPassword ? "text" : "password"} className="form-control floating" onChange={(text: any) => setPassword(text.target.value)} />
                  <label className="focus-label">Password</label>
                  <div className={style.iconEye}>
                    {values.showPassword ? <FontAwesomeIcon icon={faEye} onClick={handleClickShowPassword} /> : <FontAwesomeIcon icon={faEyeSlash} onClick={handleClickShowPassword} />}
                  </div>
                </div>
                <div className="form-group form-focus">
                  <input type={confirmPass.showConfirmPassword ? "text" : "password"} className="form-control floating" onChange={(text: any) => setConfirmPassword(text.target.value)} />
                  <label className="focus-label">Confirm Password</label>
                  <div className={style.iconEye}>
                    {confirmPass.showConfirmPassword ? <FontAwesomeIcon icon={faEye} onClick={handleClickShowConfirmPassword} /> : <FontAwesomeIcon icon={faEyeSlash} onClick={handleClickShowConfirmPassword} />}
                  </div>
                </div> */}
              </>
            )
          }
        </div>
      </div>
      <div className={`text-center ${style.dontHave}`}>
        <Link to="/" style={{ color: "#1a6ab1" }}>
          {" "}
          Login
        </Link>
      </div>
      <div className={`text-center  ${style.copy}`}>© 2022 ONRx</div>
    </div>

  );
};

export default ForgotPassword;
