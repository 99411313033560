import { all, takeEvery, put, call } from "redux-saga/effects";
import * as AuthService from "../../services/auth";
import * as userService from "../../services/user";
import { ActionTypes } from "../constants/action-types";
import { setUser, authentication } from "../actions/userActions";
import SSEHandler from "../../lib/SSEHandler";
import getJwtToken from "../../lib/jwthelper";
import store, { history } from "store";
import storeToken from 'store'

export function* LOAD_CURRENT_ACCOUNT() {
  const response = yield call(AuthService.currentAccount);
  const responseToken = yield call(AuthService.GetAuthenticationToken);

  console.log(response);
  console.log(responseToken);

  if (response) {
    const { sub } = response;

    const userRoleResponse = yield call(userService.getUser, {
      cognitoid: sub,
      }, responseToken.idToken);
    // }, storeToken.get('auth_token_refresh'));
    const { body: userDetails } = userRoleResponse;
    store.set("userid", sub)
    store.set("name",userDetails.name?userDetails.name:'' )
    const ROLES = [];

    if (userDetails.CompanyEmployee.length > 0) {
      userDetails.CompanyEmployee.forEach((roles) => {
        ROLES.push(roles.role);
      });
    }

    console.log(userDetails);

    if (!userDetails) {
      yield put(authentication(false));
      history.push("/inactive");
    } else {
      localStorage.setItem("user", JSON.stringify(userDetails));
      userDetails.userId = sub;
      userDetails.sub = sub;
      // Call subscribe here
      if (
        userDetails?.CompanyEmployee[0]?.role === "DOCTOR" ||
        userDetails?.CompanyEmployee[0]?.role === "PATIENT" ||
        userDetails?.CompanyEmployee[0]?.role === "STAFF"
      ) {
        const payloadData = {
          type: userDetails?.CompanyEmployee[0]?.Role?.role.toLowerCase(),
          id: sub,
          uuid: SSEHandler.getInstance().getUUID(),
        };
        const jwtToken = getJwtToken(payloadData);
        SSEHandler.getInstance().subscribeToSSE(jwtToken, userDetails);
        SSEHandler.getInstance().dispatch = store.dispatch;
      }

      yield put(setUser(userDetails));
    }
  } else {
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
