import Dropdown from "react-bootstrap/Dropdown";
import style from "./style.module.scss";
import IMG02 from "../../images/user.png";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  authentication,
  bookAppointmentDoctor,
  callbackAppointment,
  loadWhichVersion,
  selectAppointmentType,
  selectedService,
  selectPaymentProvider,
  setProblem,
  setUser,
  setMeetingEndData,
  setCallID,
  setEndModal,
  setSelectedFamilyMember,
  AppointmentRequestType,
  setInsurance,
  setChatHead
} from "../../redux/actions/userActions";
import SSEHandler from "../../lib/SSEHandler";
import WebrtcCallHandler from "../../lib/WebrtcCallHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCalendar, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { ActionTypes } from "../../redux/constants/action-types";
import * as AuthService from "../../services/auth";
import store from "store";

export const TopBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  let userInfo = useSelector((state: any) => state.userReducer.user);
  const userData = useSelector((state: any) => state.userReducer.user);

  useEffect(() => {
  }, []);

  const logout = () => {
    // console.log("click to logout");
    AuthService.logout();
    store.clearAll();
    dispatch(authentication(false));
    WebrtcCallHandler.getInstance().cleanUp();
    SSEHandler.getInstance().cleanUp();
    dispatch(setUser([]));
    dispatch(selectPaymentProvider([]));
    dispatch(selectAppointmentType([]));
    dispatch(bookAppointmentDoctor([]));
    dispatch(selectedService([]));
    dispatch(loadWhichVersion([]));
    dispatch(callbackAppointment([]));
    dispatch(setProblem([]));
    dispatch(setMeetingEndData([]));
    dispatch(setCallID([]));
    dispatch(setEndModal(false));
    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));
    dispatch(setChatHead(null));
  };

  const selectType = () => {
    // history.push("/selectType");
    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));
    dispatch(selectPaymentProvider({}));
    dispatch(selectAppointmentType({}));
    dispatch(setInsurance({}))
    history.push("/appoiment-type");
  };

  const goToDashboard = () => {
    history.push("/");
  };

  return (
    <>
      <header className="header">
        <nav className={`navbar navbar-expand-lg header-nav`}>
          <div className={style.mainContaner}>
            <div className={style.logoDiv} onClick={goToDashboard}>
              <img src="../Images/logo.png" height="50" alt="" />
            </div>
            <div className="row smallDivices">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  type="submit"
                  className={`btnDashboardBook ${style.btnBookAppointment}`}
                  onClick={selectType}
                >
                  <FontAwesomeIcon className={`mr-3 ${style.iconCal}`} icon={faCalendarAlt} />
                  Book Appointment
                </button>
              </div>

              {/* <div className={style.borderRightSame}></div>
              <div className={style.divIcon}>
                <img src="../Images/bell.png" className={style.iconNoti} />
                <FontAwesomeIcon icon={faBell} className={style.iconNoti} />
              </div> */}
              <div className={style.borderRightSame}></div>
              <Dropdown className={`user-drop nav-item dropdown has-arrow logged-item`}>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  style={{
                    textAlign: "right",
                    marginRight: 10,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "auto",
                  }}
                >
                  {userData?.profilepicture != "" &&
                    userData?.profilepicture != null ? (
                    <img
                      className={style.image_round}
                      src={`${process.env.REACT_APP_ASSET_URL}/${userData.profilepicture}`}
                      alt="User"
                    />
                  ) : (
                    <img
                      className={style.image_round}
                      src={IMG02}
                      width="31"
                      alt="Darren Elder"
                    />
                  )}
                  <div>
                    <div>
                      <div className="row">
                        <div className="col-sm-12 d-none d-lg-block">
                          <p style={{ margin: 0, marginLeft: 10 }}>
                          {userInfo?.FirstName?.charAt(0).toUpperCase() + userInfo?.FirstName?.slice(1)} {userInfo?.LastName?.charAt(0).toUpperCase() + userInfo?.LastName?.slice(1)}
                          </p>
                          {/* <p
                            style={{
                              margin: 0,
                              marginLeft: 10,
                              textAlign: "left",
                              fontSize: 13,
                            }}
                          >
                            Patient
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="user-header">
                    <div className="">
                      {userData?.profilepicture != "" &&
                        userData?.profilepicture != null ? (
                        <img
                          className={style.userProfile}
                          src={`${process.env.REACT_APP_ASSET_URL}/${userData.profilepicture}`}
                          alt="User"
                        />
                      ) : (
                        <img
                          src={IMG02}
                          alt="User"
                          className={style.image_round}
                        />
                      )}
                    </div>
                    <div className="user-text">
                      <h6 className={style.userNamee}>
                        {userInfo?.FirstName?.charAt(0).toUpperCase() + userInfo?.FirstName?.slice(1)} {userInfo?.LastName?.charAt(0).toUpperCase() + userInfo?.LastName?.slice(1)}
                      </h6>
                      {/* <p className={`${style.pspeciality} text-muted mb-0`}>
                        Patient
                      </p> */}
                    </div>
                  </div>
                  <Dropdown.Item href="/dashboard" className={style.item_down}>
                    Dashboard
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/profile"
                    className={style.item_down}
                  >
                    Edit Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/view-profile"
                    className={style.item_down}
                  >
                    Profile Settings
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/"
                    className={style.item_down}
                    onClick={logout}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
