export const ActionTypes = {
  SET_USER: "SET_USER",
  SET_DOCTOR_LIST: "SET_DOCTOR_LIST",
  SELECTED_DOCTOR: "SELECTED_DOCTOR",
  SELECTED_DOCTOR_PROFILE: "SELECTED_DOCTOR_PROFILE",
  CURRENT_APPOINTMENT: "CURRENT_APPOINTMENT",
  SELECT_PAYMENT_PROVIDER: "SELECT_PAYMENT_PROVIDER",
  APPOINTMENT_TYPE: "APPOINTMENT_TYPE",
  SET_PROBLEMS: "SET_PROBLEMS",
  BOOK_APPOINTMENT_DOCTOR: "BOOK_APPOINTMENT_DOCTOR",
  BOOK_APPOINTMENT: "BOOK_APPOINTMENT",
  CALLBACK_TYPE: "CALLBACK_TYPE",
  LOAD_CURRENT_ACCOUNT: "user/LOAD_CURRENT_ACCOUNT",
  WHICH_VERSION: "WHICH_VERSION",
  SHOW_VIRTUAL_WAITING_ROOM: "SHOW_VIRTUAL_WAITING_ROOM",
  IS_PROFILE_COMPLATE: "IS_PROFILE_COMPLATE",
  AUTHENTICATION: "AUTHENTICATION",
  SET_COGNITO_USER_DATA: "SET_COGNITO_USER_DATA",
  CALL_END: "meeting/CALL_END",
  SET_CALL_ID: "meeting/SET_CALL_ID",
  FAMILY_DOCTOR: "FAMILY_DOCTOR",
  SELECTED_FAMILY_MEMBER: "SELECTED_FAMILY_MEMBER",
  APPOINTMENT_REQUEST_TYPE: "APPOINTMENT_REQUEST_TYPE",
  App_Config: "APP_Config",
  INSURANCE: "INSURANCE",
  USER_TOKEN: "USER_TOKEN",
  CHATHEAD: "CHATHEAD",
  CHAT_USER: "CHAT_USER"
};
