import { Auth } from "aws-amplify";
import store from 'store'

export function currentAccount() {
  // const authResponse = store.get('cognitoResponse')
  return Auth.currentAuthenticatedUser()
    .then((response) => {
     // console.log("currentAuthenticatedUser response: ", response);
      if (response) {
        const userDetails =
          (response &&
            response.attributes && {
            ...response.attributes,
            id: response.username,
          }) ||
          {};
        // store.set('accessToken', accessToken)
        return userDetails;
      }
      return ;
    })
    .catch((err) => {console.log(err); return });
}

export async function GetAuthenticationToken() {
  return await Auth.currentSession().then((response) => {
    console.log(" Token response: ", response);
    store.set('userauth',true);
    if (response) {
      const res =
        (response) ||
        {};
      return res;
    }
    return false;
  })
    .catch((err) => {
      console.log(err);
      // return err.name;
      return err;
    }
    );
}


export function logout() {
  try {
    Auth.signOut().then(e=>{
      store.set('userauth',false);
    });
   
  } catch (error) {
    console.log('error signing out: ', error);
  }
}