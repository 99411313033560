import { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Modal,
  notification,
  Button,
  Alert,
} from "antd";
import _ from "lodash";
import SearchLocationInput from "../searchAddress/index";
import { GET, POST, PUT } from "../../services/common.api";
import {
  relationShip,
  province
} from "../../constants/patientProfile.constant";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import style from "../../pages/dashboard/dependent/style.module.css";
import { setFamilyDoctor } from "redux/actions/userActions";
import MaskInput from "react-maskinput";
import { find } from 'lodash'
import { IMG01 } from "./img";

const AddFamilyMember = ({
  open,
  close,
  title,
  editFamilyMember,
  getFamilyMember,
}) => {
  let user = JSON.parse(localStorage.getItem("user"));
  const { Option } = Select;
  const dispatch = useDispatch();
  const [, setLat] = useState(null);
  const [, setLng] = useState(null);
  const [address, setAddress] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(undefined);
  const [familyDoc, setFamilyDoc] = useState(undefined);
  // const familyD = useSelector((state: any) => state?.userReducer?.familyDoc);
  const [form] = Form.useForm();
  const [familyMemberDetails, setFamilyMemberDetails] = useState({} as any);
  const [btnLoader, setBtnLoader] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [relationship, setRelationship] = useState({} as any);
  const [formValues, setFormValues] = useState({} as any);
  const [loader, setLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [allDoctor, setAllDoctor] = useState([]);
  const [selectedFamilyMemberID, setSelectFamilyMemberID] = useState("");
  let config = useSelector((state: any) => state.AppConfig.config);

  const currentAppointmentInfo = useSelector(
    (state: any) => state.curentAppointment
  );

  const getPatientDetails = async () => {

    console.log(editFamilyMember, "/////??????")

    try {
      const data = await GET(
        `user/profile/${editFamilyMember?.familymemberid}`
      );
      console.log(data?.data[0][0], "Family data");
      // setFamilyMemberDetails(data?.data[0][0]);
      setFamilyMemberDetails(data?.data[0][0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // alert(",kl")
    console.log(editFamilyMember, familyMemberDetails, "getFamilyDoctor();");
    getFamilyDoctor(editFamilyMember?.familymemberid);
    console.log(editFamilyMember, "editFamilyMember?.familymemberid editFamilyMember?.familymemberid")
    if (title == "Add Family Member") {
      form.resetFields();
      setPhoneNumber("");
    }
    if (!_.isEmpty(editFamilyMember)) {

      console.log("editFamilyMember.dateOfBirth");

      getPatientDetails();

      // form.setFieldsValue(editFamilyMember);
      form.setFieldsValue({
        FirstName: familyMemberDetails?.firstname,
        LastName: familyMemberDetails?.lastname,
        Email: familyMemberDetails?.email,
        // phoneNumber: familyMemberDetails?.phoneNumber,
        birth_date: editFamilyMember?.dateOfBirth == undefined ? undefined : moment(editFamilyMember?.dateOfBirth),
        gender: editFamilyMember?.gender != null ? editFamilyMember?.gender : "",
        relationship: editFamilyMember.relationship != null ? editFamilyMember?.relationship : "",
        province: editFamilyMember?.province != null ? editFamilyMember?.province : "",
        // ohipNumber_name: editFamilyMember?.ohipNumber != null ? editFamilyMember?.ohipNumber : "",
      });

      getInsuranceDetails(editFamilyMember?.familymemberid);
      // birth_date: dateOfBirth == undefined ? undefined : moment(dateOfBirth),


      setAddress(editFamilyMember?.address);
      setPhoneNumber(familyMemberDetails?.phoneNumber)
      setDateOfBirth(editFamilyMember?.dateOfBirth);
      // console.log("editFamilyMember.dateOfBirth", dateOfBirth);

    } else {
      console.log(dateOfBirth)
      setDateOfBirth(undefined);
      console.log(dateOfBirth)
      setAddress("");
    }
  }, [open]);

  const getInsuranceDetails = async (familyMemberID) => {

    try {
      setLoader(true);
      const res = await GET(`user/insurance/${familyMemberID}`);
      console.log(res?.data[0], "getInsuranceInfo");

      let iData = res?.data[0];
      form.setFieldsValue({
        ohipNumber_name: iData?.insurance_number != null ? iData?.insurance_number : "",
        insuranceVersionCode: iData?.insurance_vc != null ? iData?.insurance_vc : "",
      });

      setLoader(false);
    } catch (err) {
      console.log("error", err);
      setLoader(false);
    }
  };

  const getFamilyDoctor = async (id) => {
    try {
      console.log(`patient/familydoc/${id}`, "67676767676676766767676767")
      await GET(`patient/familydoc/${id}`).then((response) => {

        if (response?.data?.body != null && response?.data?.body != 'Family member not linked.') {
          console.log("🚀 ~ file: index.tsx ~ line 110 ~ awaitGET ~ response", response)
          let N = response?.data?.body?.CompanyEmployee?.CompanyEmployee;
          form.setFieldsValue({ familyDoctor: `Dr.${N?.FirstName} ${N?.LastName}` })
          console.log(`Dr.${N?.FirstName} ${N?.LastName}`, "NNNNNNNNNNNNNNNNNNN");
        } else {
          form.setFieldsValue({ familyDoctor: `` })
        }
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  }

  useEffect(() => {

    console.log(familyMemberDetails, "familyMemberDetailsfamilyMemberDetailsfamilyMemberDetailsfamilyMemberDetails")

    form.setFieldsValue({
      Email: familyMemberDetails?.email,
      FirstName: familyMemberDetails?.firstname,
      LastName: familyMemberDetails?.lastname,
      // phoneNumber: familyMemberDetails?.phoneNumber
    });
    setPhoneNumber(familyMemberDetails?.phoneNumber)
    getDoctorList();
  }, [familyMemberDetails]);

  const handleChangeMemeber = (event) => {
    console.log(event);
    setSelectFamilyMemberID(event);
  };

  const getDoctorList = async () => {
    const doctorList = await GET(`employee/doctors`);
    console.log(doctorList, "-*-*-*All doctorList");

    if (doctorList?.data?.hasOwnProperty("errorMessage")) {

    } else {

      setAllDoctor(doctorList?.data);
    }
  };

  const checkAddress = () => {
    // if (address == "" || address == null) {
    //   setAddress(null);
    // }
  }

  const onFinish = async (values) => {
    // let abc = relationship == "other" ? values.addRelation : values.relationship
    // console.log(abc, "relationship relationship")

    // if (address == "" || address == null) {
    //   setAddress(null);
    // } else {
      setLoader(true);
      console.log(values);
      setBtnLoader(true);
      // let ohipNumber = values?.ohipNumber?.replaceAll('-', '');
      let ohipNumber = values?.ohipNumber_name?.replaceAll('-', '');
      console.log(ohipNumber, "ohipnumberohipnumber")
      if ((ohipNumber == undefined || ohipNumber == "") && (values.insuranceVersionCode == undefined || values.insuranceVersionCode == "")) {

        let responseOfPatien;
        if (_.isEmpty(editFamilyMember)) {
          const data = {
            Type: "PATIENT",
            FirstName: values.FirstName,
            LastName: values.LastName,
            Email: values.Email ? values.Email : '',
            Phone: phoneNumber ? phoneNumber : '',
            createDate: moment().utc(),
            // "cognitoid": user.userSub,
            gender: values.gender,
            address: address ? address : '',
          };
          console.log(data, "dataaaaaaaa undefined");
  
          const response = await POST("user/patient", data);
          console.log(response, "ressrssrrs");
          responseOfPatien = response;
        }else{
          const data1 = {
            FirstName: values.FirstName,
            LastName: values.LastName,
            Email: values.Email ? values.Email : '',
            phoneNumber: phoneNumber,
            DOB:moment(values.birth_date).local().format("YYYY-MM-DD"),
            familyDoctor : values.familyPhysician,
            gender: values.gender,
            province: values.province,
            Address1: address ? address : '',
          };
          console.log(data1, "dataaaaaaaa undefined");
  
          const responseEdit = await PUT(`user/profile/${editFamilyMember?.familymemberid}`, data1);
          console.log(responseEdit, "ressrssrrs");

          // `user/profile/${editFamilyMember?.familymemberid}`
        }
      
        let abc = relationship == "other" ? values.addRelation : values.relationship
        console.log(abc, "relationship relationship")

        let familyAddObject = {
          userName: `${values.FirstName} ${values.LastName}`,
          relationship: abc,
          province: values.province,
          ohipNumber: ohipNumber ? ohipNumber : null,
          insuranceServiceNumber: '',
          insuranceProviderName: values.FirstName + "" + values.LastName,
          gender: values.gender,
          familyPhysician: values.familyPhysician,
          address,
          phoneNumber: phoneNumber,
          dateOfBirth: moment(values.birth_date).local().format("YYYY-MM-DD"),
          userid: user?.id,
          familymemberid: responseOfPatien?.data?.body?.user?.id || editFamilyMember?.familymemberid,
          // familymemberid: null,
          active: 1,
          createdBy: user.role,
          id: undefined,
        };
        console.log(familyAddObject, "familyAddObject");
        setLoader(false);
        setBtnLoader(false);
        Addfamily(familyAddObject);
      } else if ((ohipNumber == undefined || ohipNumber == "") || (values.insuranceVersionCode == undefined || values.insuranceVersionCode == "")) {
        if (ohipNumber == undefined || values.insuranceVersionCode == undefined || ohipNumber == "" || values.insuranceVersionCode == "") {
          setLoader(false);
          setBtnLoader(false);
          setErrorMsg("Please Enter all Ohip Details")
          setShowError(true);

          setTimeout(() => {
            setShowError(false);
          }, 5000);
        } else {

          let ohipNumberNew = ohipNumber?.replaceAll('-', '');

          let data = {
            // 'Provider-number': formValues.Insurancesvcnum, 
            // const str = '185-51-671';
            // console.log(str.replaceAll('-', ''));
            "Provider-number": "020497",
            HCN: ohipNumber ? ohipNumberNew : null,
            VC: values.insuranceVersionCode ? values.insuranceVersionCode : null,
            // User: values.fname + " " + values.lname,
            User: values.FirstName + " " + values.LastName,
          };

          await axios
            .post("https://api.mdmax.ca/api/1.1/wf/api-validation-call", data, {
              headers: {
                Authorization: `Bearer cee53e7aa78674f078e300e6d96d98ec`,
                // Authorization: `Bearer 894da2b4b1760319ae94cbfa73db5a10`,
              },
            })
            .then(
              async (response: any) => {
                console.log(response, "responce");
                if (response?.data?.response["MOH-card-eligible"] == false) {
                  setLoader(false);
                  setBtnLoader(false);
                  setErrorMsg("Invalid OHIP Card")
                  setShowError(true);
                } else {
                  if (response?.data?.response["MOH-Message"] == 'Invalid version code') {
                    setLoader(false);
                    setErrorMsg("Invalid version code");
                    setShowError(true);
                    setBtnLoader(false);
                  } else {
                    if (response?.data?.response['MOH-card-status'] == 'invalid') {
                      setLoader(false);
                      setErrorMsg("Invalid or Expired health card");
                      setShowError(true);
                      setBtnLoader(false);
                    } else {

                      let D = values.birth_date.format("YYYY-MM-DD");

                      if (response?.data?.response.DOB !== D) {
                        setLoader(false);
                        setErrorMsg("Date of birth should match with OHIP")
                        setShowError(true);
                        setBtnLoader(false);
                      } else {

                        if (
                          response?.data?.response["First-name"].toLowerCase() !==
                          values?.FirstName.toLowerCase()
                        ) {
                          setLoader(false);
                          setErrorMsg("First name should match with OHIP")
                          setShowError(true);
                          setBtnLoader(false);

                        } else {
                          if (
                            response?.data?.response["Last-name"].toLowerCase() !==
                            values?.LastName.toLowerCase()
                          ) {
                            setLoader(false);
                            setErrorMsg("Last name should match with OHIP")
                            setShowError(true);
                            setBtnLoader(false);
                          } else {

                            let responseOfPatien;
                            if (_.isEmpty(editFamilyMember)) {
                              const data = {
                                Type: "PATIENT",
                                FirstName: values.FirstName,
                                LastName: values.LastName,
                                Email: values.Email ? values.Email : '',
                                Phone: phoneNumber ? phoneNumber : '',
                                createDate: moment().utc(),
                                // "cognitoid": user.userSub,
                                gender: values.gender,
                                address: address ? address : '',
                              };
                              console.log(data, "dataaaaaaaa or undefined");

                              const response = await POST("user/patient", data);
                              console.log(response);
                              responseOfPatien = response;
                            }else{
                              const data1 = {
                                FirstName: values.FirstName,
                                LastName: values.LastName,
                                Email: values.Email ? values.Email : '',
                                phoneNumber: phoneNumber,
                                DOB:moment(values.birth_date).local().format("YYYY-MM-DD"),
                                familyDoctor : values.familyPhysician,
                                gender: values.gender,
                                province: values.province,
                                Address1: address ? address : '',
                              };
                              console.log(data1, "dataaaaaaaa undefined");
                      
                              const responseEdit = await PUT(`user/profile/${editFamilyMember?.familymemberid}`, data1);
                              console.log(responseEdit, "ressrssrrs");
                    
                              // `user/profile/${editFamilyMember?.familymemberid}`
                            }

                            const Ohip = {
                              name: values.FirstName + " " + values.LastName,
                              number: ohipNumber,
                              provider: "NA",
                              type: 'OHIP',
                              vc: values.insuranceVersionCode,
                              user_id: responseOfPatien?.data?.body?.user?.id || editFamilyMember?.familymemberid,
                              DOB: dateOfBirth,
                              is_valid: 1,

                            };
                            console.log(Ohip, "Ohip");
                            try {
                              const res = await POST("user/insurance", Ohip);
                              console.log(res, "family insurances------0 ");

                              let abc = relationship == "other" ? values.addRelation : values.relationship
                              console.log(abc, "relationship relationship")

                              let familyAddObject = {
                                userName: `${values.FirstName} ${values.LastName}`,
                                relationship: abc,
                                province: values.province,
                                ohipNumber: ohipNumber,
                                insuranceServiceNumber: '',
                                insuranceProviderName: values.FirstName + "" + values.LastName,
                                gender: values.gender,
                                familyPhysician: values.familyPhysician,
                                address,
                                dateOfBirth: moment(values.birth_date).local().format("YYYY-MM-DD"),
                                userid: user?.id,
                                familymemberid: responseOfPatien?.data?.body?.user?.id || editFamilyMember?.familymemberid,
                                active: 1,
                                createdBy: user.role,
                                id: undefined,
                              };
                              console.log(familyAddObject, "familyAddObject");
                              setLoader(false);
                              setBtnLoader(false);
                              Addfamily(familyAddObject);
                            } catch (err) {
                              setLoader(false);
                              setBtnLoader(false);
                              console.log("error", err);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              (err) => {
                setBtnLoader(false);
                setLoader(false);
                setErrorMsg("Please Enter Ohip Details")
                setShowError(true);

                setTimeout(() => {
                  setShowError(false);
                }, 5000);
                console.log(err, "error in on finish");
              }
            );
        }
      } else if (ohipNumber != undefined && ohipNumber != "" && values.insuranceVersionCode != undefined && values.insuranceVersionCode != undefined) {
        let ohipNumberNew = ohipNumber?.replaceAll('-', '');
        let data = {
          // 'Provider-number': formValues.Insurancesvcnum,
          "Provider-number": "020497",
          HCN: ohipNumber ? ohipNumberNew : null,
          VC: values.insuranceVersionCode,
          // User: values.fname + " " + values.lname,
          User: values.FirstName + " " + values.LastName,
        };

        await axios
          .post("https://api.mdmax.ca/api/1.1/wf/api-validation-call", data, {
            headers: {
              Authorization: `Bearer cee53e7aa78674f078e300e6d96d98ec`,
              // Authorization: `Bearer 894da2b4b1760319ae94cbfa73db5a10`,
            },
          })
          .then(
            async (response: any) => {
              console.log(response, "responce");

              if (response?.data?.response["MOH-card-eligible"] == false) {
                setLoader(false);
                setBtnLoader(false);
                setErrorMsg("Invalid OHIP Card")
                setShowError(true);
              } else {
                if (response?.data?.response["MOH-Message"] == 'Invalid version code') {
                  setLoader(false);
                  setErrorMsg("Invalid version code");
                  setShowError(true);
                  setBtnLoader(false);
                } else {
                  if (response?.data?.response['MOH-card-status'] == 'invalid') {
                    setLoader(false);
                    setErrorMsg("Invalid or Expired health card");
                    setShowError(true);
                    setBtnLoader(false);
                  } else {

                    let D = values.birth_date.format("YYYY-MM-DD");

                    console.log(values.birth_date)
                    console.log(D)
                    console.log(response?.data?.response.DOB)

                    if (response?.data?.response.DOB !== D) {
                      setLoader(false);
                      setErrorMsg("Date of birth should match with OHIP")
                      setShowError(true);
                      setBtnLoader(false);
                    } else {

                      if (
                        response?.data?.response["First-name"].toLowerCase() !==
                        values?.FirstName.toLowerCase()
                      ) {
                        setLoader(false);
                        setErrorMsg("First name should match with OHIP")
                        setShowError(true);
                        setBtnLoader(false);

                      } else {
                        if (
                          response?.data?.response["Last-name"].toLowerCase() !==
                          values?.LastName.toLowerCase()
                        ) {
                          setLoader(false);
                          setErrorMsg("Last name should match with OHIP")
                          setShowError(true);
                          setBtnLoader(false);
                        } else {
                          let responseOfPatien;
                          if (_.isEmpty(editFamilyMember)) {
                            const data = {
                              Type: "PATIENT",
                              FirstName: values.FirstName,
                              LastName: values.LastName,
                              Email: values.Email ? values.Email : '',
                              Phone: phoneNumber ? phoneNumber : '',
                              createDate: moment().utc(),
                              // "cognitoid": user.userSub,
                              gender: values.gender,
                              address: address ? address : '',
                            };
                            const response = await POST("user/patient", data);
                            console.log(response);
                            responseOfPatien = response;
                          }else{
                            const data1 = {
                              FirstName: values.FirstName,
                              LastName: values.LastName,
                              Email: values.Email ? values.Email : '',
                              phoneNumber: phoneNumber,
                              DOB:moment(values.birth_date).local().format("YYYY-MM-DD"),
                              familyDoctor : values.familyPhysician,
                              gender: values.gender,
                              province: values.province,
                              Address1: address ? address : '',
                            };
                            console.log(data1, "dataaaaaaaa undefined");
                    
                            const responseEdit = await PUT(`user/profile/${editFamilyMember?.familymemberid}`, data1);
                            console.log(responseEdit, "ressrssrrs");
                  
                            // `user/profile/${editFamilyMember?.familymemberid}`
                          }

                          const Ohip = {
                            name: values.FirstName + " " + values.LastName,
                            // number: values?.ohipNumber,
                            number: values?.ohipNumber_name,
                            provider: "NA",
                            type: 'OHIP',
                            vc: values.insuranceVersionCode,
                            user_id: responseOfPatien?.data?.body?.user?.id || editFamilyMember?.familymemberid,
                            DOB: dateOfBirth,
                            is_valid: 1,

                          };
                          console.log(Ohip, "Ohip");
                          try {
                            const res = await POST("user/insurance", Ohip);
                            console.log(res, "family insurances------0 ");

                            let abc = relationship == "other" ? values.addRelation : values.relationship
                            console.log(abc, "relationship relationship")

                            let familyAddObject = {
                              userName: `${values.FirstName} ${values.LastName}`,
                              relationship: abc,
                              province: values.province,
                              ohipNumber: ohipNumber,
                              insuranceServiceNumber: '',
                              insuranceProviderName: values.FirstName + "" + values.LastName,
                              gender: values.gender,
                              familyPhysician: values.familyPhysician,
                              address,
                              dateOfBirth: moment(values.birth_date).local().format("YYYY-MM-DD"),
                              userid: user?.id,
                              familymemberid: responseOfPatien?.data?.body?.user?.id || editFamilyMember?.familymemberid,
                              active: 1,
                              createdBy: user.role,
                              id: undefined,
                            };
                            console.log(familyAddObject, "familyAddObject");
                            setLoader(false);
                            setBtnLoader(false);
                            Addfamily(familyAddObject);
                          } catch (err) {
                            setLoader(false);
                            setBtnLoader(false);
                            console.log("error", err);
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            (err) => {
              setBtnLoader(false);
              setLoader(false);
              console.log(err);
            }
          );
      }
      // await axios
      //   .post("https://api.mdmax.ca/api/1.1/wf/api-validation-call", data, {
      //     headers: {
      //       Authorization: `Bearer 894da2b4b1760319ae94cbfa73db5a10`,
      //     },
      //   })
      //   .then(
      //     async (response: any) => {
      //       setLoader(false);
      //       console.log(response, "responce");
      //       if (
      //         response?.data?.response["MOH-card-eligible"] == false &&
      //         response?.data?.response["First-name"]?.toLowerCase() !=
      //         values?.FirstName.toLowerCase() &&
      //         response?.data?.response["Last-name"]?.toLowerCase() !=
      //         values?.LastName.toLowerCase()
      //       ) {
      //         notification.warning({
      //           message: "Invalid Insurance Card",
      //         });
      //       }
      //       else {
      //         const data = {
      //           Type: "PATIENT",
      //           FirstName: values.FirstName,
      //           LastName: values.LastName,
      //           Email: values.Email,
      //           phoneNumber: phoneNumber,
      //           createDate: moment().utc(),
      //           // "cognitoid": user.userSub,
      //           gender: values.gender,
      //           address: address,
      //         };
      //         console.log(data, "dataaaaaaaa");
      //         const response = await POST("user/patient", data);
      //         console.log(response);
      //         let familyAddObject = {
      //           userName: `${values.FirstName} ${values.LastName}`,
      //           relationship: values.relationship,
      //           province: values.province,
      //           ohipNumber: values.ohipNumber,
      //           insuranceServiceNumber: values.insuranceServiceNumber,
      //           insuranceProviderName: values.insuranceProviderName,
      //           gender: values.gender,
      //           familyPhysician: values.familyPhysician,
      //           address,
      //           dateOfBirth: values.birth_date,
      //           userid: user?.id,
      //           familymemberid: response?.data?.body?.user?.id,
      //           active: 1,
      //           createdBy: user.role,
      //           id: undefined,
      //         };
      //         console.log(familyAddObject, "familyAddObject");
      //         Addfamily(familyAddObject);
      //       }
      //     },
      //     (err) => {
      //       setLoader(false);
      //       notification.warning({
      //         message: "Missing parameter for Validation",
      //       });
      //     }
      //   );
    // }
  };

  const getDateFormated = (date) => {
    if (date) {
      return date.replace("/", "-").replace("/", "-")
    } else {
      return date
    }
  }

  const handleChange = (event) => {
    console.log(event.target.name, "jsdfhjsfhhdsfhfgjh handle chaneg");
    console.log(event.target.value, "jsdfhjsfhhdsfhfgjh ");
    // if (errorMsg == 'Invalid OHIP Card' && event.target.name == 'ohipNumber') {
    if (errorMsg == 'Invalid OHIP Card' && event.target.name == 'ohipNumber_name') {
      setShowError(false);
    } else if (errorMsg == 'Invalid version code' && event.target.name == 'insuranceVersionCode') {
      setShowError(false);
    }
    else if ((errorMsg == 'First name should match with OHIP' || errorMsg == "Your account profile details should match with the OHIP card details") && event.target.name == 'FirstName') {
      setShowError(false);
    }
    // else if ((errorMsg == 'First name should match with OHIP' || errorMsg == "Change the profile details according to the OHIP card") && event.target.name == 'FirstName') {
    //   setShowError(false);
    // }
    // else if ((errorMsg == 'First name should match with OHIP' || errorMsg == 'First name should match with profile info') && event.target.name == 'FirstName') {
    //   setShowError(false);
    // }
    else if ((errorMsg == 'Last name should match with OHIP' || errorMsg == "Your account profile details should match with the OHIP card details") && event.target.name == 'LastName') {
      setShowError(false);
    }
    // else if ((errorMsg == 'Last name should match with OHIP' || errorMsg == "Change the profile details according to the OHIP card") && event.target.name == 'LastName') {
    //   setShowError(false);
    // }
    // else if ((errorMsg == 'Last name should match with OHIP' || errorMsg == 'Last name should match with profile info') && event.target.name == 'LastName') {
    //   setShowError(false);
    // } 
    else if (errorMsg == 'Invalid or Expired health card') {
      setShowError(false);
    }

    const name = event?.target?.name;
    const value = event?.target?.value;
    console.log(name, value, "-=-=-=-=-=-=-==-=--=-==-=--=-=-=-==--=-==--==-");
    setFormValues((formValues) => ({ ...formValues, [name]: value }));
    console.log(formValues, "formValues of Ohip Card");
  };

  const updateOrCreateFamilyMember = async (id) => {
    console.log(selectedFamilyMemberID, id, "-=-=-=-=-=-=-=- avyu");
    const familyAddObject = {
      patient_id: id,
      provider_id: selectedFamilyMemberID,
    };
    console.log(familyAddObject);
    try {
      const res = await POST("patient/familydoc", familyAddObject);
      console.log(res, "family doc api ");
    } catch (err) {
      console.log("error", err);
    }
  };

  const Addfamily = async (familyAddObject: any) => {
    console.log(familyAddObject, "familyadd object family add object")
    setLoader(false);
    try {
      if (_.isEmpty(editFamilyMember)) {
        console.log("in isEmpty edit family member")
        let resultt = await POST("userfamilymembers", familyAddObject);
        setDateOfBirth(undefined);
        console.log(resultt)
        updateOrCreateFamilyMember(familyAddObject?.familymemberid);
        notification.success({
          message: "Your Data Successfully Added",
        });
        setBtnLoader(false);
      } else {
        console.log("out isEmpty edit family member")
        console.log("a call thayu", editFamilyMember)
        console.log("a call thayu", familyAddObject)
        const { id } = editFamilyMember;
        familyAddObject.id = id;
        console.log(familyAddObject.id = id, "ididididididididididididididididididid")
        await PUT("userfamilymembers", familyAddObject);
        // setDateOfBirth(undefined);
        setFamilyDoc(undefined)
        // setPhoneNumber()

        if (selectedFamilyMemberID) {
          const found = allDoctor.find(
            (element) => element.companyemployeeid == selectedFamilyMemberID
          );
          console.log(found, "-*-*-*-**");
          dispatch(
            setFamilyDoctor(`Dr. ${found?.FirstName} ${found?.LastName}`)
          );
        }
        // updateOrCreateFamilyMember(familyAddObject.id);
        updateOrCreateFamilyMember(familyAddObject.familymemberid);

        notification.success({
          message: "Details updated successfully ",
        });
        setBtnLoader(false);
      }
      form.resetFields();
      setPhoneNumber("");
      setAddress(null);
      // setDateOfBirth(undefined);
      getFamilyMember();
      close();
    } catch (err) {
      setBtnLoader(false);
      close();
      console.log("error", err);
    }
  };

  const onChangeDateOfBirth = (date, dateString) => {
    setDateOfBirth(dateString);

    if (errorMsg == 'Date of birth should match with OHIP') {
      setShowError(false);
    }
  };

  const handleChangePhonenumber = (event) => {
    console.log(event.target.name, "jsdfhjsfhhdsfhfgjh ", title);
    console.log(event.target.value, "jsdfhjsfhhdsfhfgjh ");
    setPhoneNumber(event.target.value);

    var mobile = document.getElementById('mobile');
    if (title == "Add Family Member") {
      var message = document.getElementById('message');
    } else {
      var message1 = document.getElementById('message1');
    }

    console.log(mobile, "mmmm")
    console.log(message, ",memememem")

    var badColor = "#FF0000";

    if (event.target.value.length != 10) {
      if (title == "Add Family Member") {
        message.style.color = badColor;
        message.innerHTML = "Please Enter 10 Digit Phone Number!"
      } else {
        message1.style.color = badColor;
        message1.innerHTML = "Please Enter 10 Digit Phone Number!"
      }
    } else {
      if (title == "Add Family Member") {
        message.innerHTML = ""
      } else {
        message1.innerHTML = ""
      }
    }
  };

  const addressSelect = (datee) => {
    console.log(datee,"eeeeeeee")
    setAddress(datee.formatted_address);

     if (datee && datee.address_components) {
      const state = find(datee.address_components, {
        types: ['administrative_area_level_1'],
      })

      console.log(state?.long_name,"state");
      if (state) {
        form.setFieldsValue({
          province: state.long_name
        });
      }
    }

  }

  const getUserImageDoc = (imageUrll) => {
    if (imageUrll?.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) != null) {
      return imageUrll
    }
    return `${process.env.REACT_APP_ASSET_URL}/${imageUrll}`
  }

  return (
    <>
      <Modal
        title={title}
        visible={open}
        footer={null}
        onOk={close}
        onCancel={close}
        width={1100}
        centered>

        {
          showError ? <Alert style={{ marginBottom: "15px", width: "70%" }} message={errorMsg} type="error" /> : null
        }
        {/* {dateOfBirth} */}

        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={onFinish}
          initialValues={{
            birth_date: dateOfBirth == undefined ? undefined : moment(dateOfBirth),
            familyDoctor: familyDoc == undefined ? "" : familyDoc,
          }}
        >
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name="FirstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter First Name",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" name="FirstName" onChange={handleChange} disabled={editFamilyMember?.ohipNumber != null} />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="LastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Last Name",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" name="LastName" onChange={handleChange} disabled={editFamilyMember?.ohipNumber != null} />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name="Email"
                    label="Email"
                    rules={[
                      {
                        type: "email"
                      },
                    ]}
                  >
                    <Input placeholder="Email" name="Email" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    label="Date Of Birth"
                    name="birth_date"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Date Of Birth"
                      },
                    ]}>

                    {dateOfBirth ? <DatePicker
                    disabled={editFamilyMember?.ohipNumber != null}
                      style={{ width: "100%" }}
                      name="birth_date"
                      onChange={onChangeDateOfBirth}
                      format="YYYY/MM/DD"
                      // format={"MM-DD-YY"}
                      defaultValue={
                        dateOfBirth != undefined ? moment(dateOfBirth) : undefined
                      }
                      disabledDate={(current) => {
                        return current && current > moment().endOf('day')
                        // return moment().add(-1, "days") <= current;
                      }}
                    /> :
                      <DatePicker
                        disabled={editFamilyMember?.ohipNumber != null}
                        style={{ width: "100%" }}
                        name="birth_date"
                        onChange={onChangeDateOfBirth}
                        format="YYYY/MM/DD"
                        disabledDate={(current) => {
                          return current && current > moment().endOf('day')
                        }}
                      />
                    }

                  </Form.Item>
                </div>
              </div>
              <div className="row">

                {/* <div className="col-sm-6">
              <Form.Item name="familyPhysician" label="Family Physicians">
                <Select
                  showSearch
                  placeholder="Family Physicians"
                  optionFilterProp="children"
                >
                  {doctorNames.map((doctorData) => (
                    <Option value={doctorData.id}>{doctorData.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div> */}
                {/* <div className="col-sm-6">
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter phone number",
                      },
                    ]}
                  >
                    <p className="p-0 mb-1">Phone number</p>
                    <PhoneInput
                      country={"us"}
                      value={formValues.phoneNumber || ""}
                      onChange={(phone) => setPhoneNumber(phone)}
                    />
                  </Form.Item>
                </div> */}

                <div className="col-sm-6">
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                  >
                    {config?.code == 'ca' ? <label style={{ position: "absolute", top: 11, left: 15 }}>+1</label> : <label style={{ position: "absolute", top: 11, left: 15 }}>+91</label>}
                    <input type="text" className={`${style.inputbox}`} name="phoneNumber"
                      value={phoneNumber || ""} style={{ paddingLeft: 40 }} pattern="\d{10}" id="mobile"
                      onChange={handleChangePhonenumber}
                    />
                    {
                      title == "Add Family Member" ? <span id="message"></span> : <span id="message1"></span>
                    }

                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  {
                    relationship == "other" ?
                      <div className="row">
                        <div className="col-sm-12" style={{ marginTop: "10px" }}>
                          <Form.Item
                            name="addRelation"
                            label="Add Relationship"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Relationship"
                              },
                            ]}>
                            <Input placeholder="Enter Relationship" name="addRelation" />
                          </Form.Item>
                        </div>
                      </div> : null
                  }
                  <Form.Item
                    name="relationship"
                    label="Choose Relationship"
                    rules={[
                      { required: true, message: "Please Select Relationship" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="RelationShip"
                      optionFilterProp="children"
                      onChange={(e) => {
                        console.log(e);
                        setRelationship(e);
                      }}
                    >
                      {relationShip.map((relation) => (
                        <Option value={relation.id} key={relation.id}>
                          {relation.name}
                        </Option>
                      ))}
                    </Select>

                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[{ required: true, message: "Please Select Gender" }]}
                  >
                    
                    <Select
                      showSearch
                      placeholder="Select Gender"
                      optionFilterProp="children"
                    >
                      {/* {patientGender.map((genderData) => (
                        <Option value={genderData.id}>{genderData.name}</Option>
                      ))} */}

                      {/* <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                      <Option value="intersex">Other</Option> */}
                      {
                        !form.getFieldValue("relationship") || form.getFieldValue("relationship") == null || form.getFieldValue("relationship") == '' ? 
                        <>
                          <Option value="male">Male</Option>
                          <Option value="female">Female</Option>
                          <Option value="other">Other</Option>
                        </> : 

                        <>
                         {
                        form.getFieldValue("relationship") == "brother" || form.getFieldValue("relationship") == "cousin" || form.getFieldValue("relationship") == "father" ||
                          form.getFieldValue("relationship") == "grandfather" || form.getFieldValue("relationship") == "husband" || form.getFieldValue("relationship") == "nephew" ||
                          form.getFieldValue("relationship") == "uncle" || form.getFieldValue("relationship") == "son" ?
                          <>
                            <Option value="male">Male</Option>
                            {/* <Option value="intersex">Other</Option> */}
                            <Option value="other">Other</Option>
                          </>
                          :
                          <>
                            <Option value="female">Female</Option>
                            <Option value="other">Other</Option>
                          </>
                      }
                        </>
                      }

                     
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="province"
                    label="Choose Province"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Company Province",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Province"
                      optionFilterProp="children"
                    >
                      {province.map((provinceData) => (
                        <Option value={provinceData.id}>{provinceData.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <Form.Item name="familyDoctor" label="Family Doctor">
                    {/* {familyDisplayId ? null : */}
                    <Select
                      showSearch
                      placeholder="Select Service"
                      className={style.selectMember}
                      optionFilterProp="children"
                      size="large"
                      style={{ width: "100%" }}
                      onChange={handleChangeMemeber}>
                      {allDoctor?.map((itemm, index) => (
                        <>
                          {itemm.FirstName != null ? (
                            <Option key={index} value={itemm?.companyemployeeid}>
                              <img
                                className={style.bgImg}
                                src={getUserImageDoc(itemm?.profilepicture)}
                                onError={(e: any) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    {IMG01};
                                }}
                                alt=""
                                height="20"
                                width="20"
                              />
                              Dr. {itemm?.FirstName} {itemm?.LastName}
                              <p className="p-0 m-0 pl-5">
                                {itemm?.ClinicAddress}
                              </p>

                            </Option>
                          ) : null}
                        </>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <label>
                    {" "}
                   Address
                  </label>
                  <Form.Item>
                    <SearchLocationInput
                      styleProps={{ minHeight: 30 }}
                      name="address"
                      address={address}
                      setlat={(e) => setLat(e)}
                      setlng={(e) => setLng(e)}
                      setAddress={(e) => addressSelect(e)}
                      onBlur={(e) => setAddress(e)}
                    />
                    {/* {address == null ?
                      <span style={{ color: "#ff0000" }}>Please Enter Address</span>
                      : null} */}
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              {/* <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name="fname"
                    label="Provider First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Insurance Service Number",
                      }
                    ]}
                  >
                    <Input
                      placeholder="Firstname"
                      name="fname"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="lname"
                    label="Provider Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Insurance Service Number",
                      }
                    ]}
                  >
                    <Input
                      placeholder="Lastname"
                      name="lname"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
              </div> */}
              <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name="ohipNumber_name"
                    label="Ohip Number"
                    rules={[
                      // { required: true, message: "Please Enter Ohip Number" },
                      {
                        min: 10,
                        message: "OHIP number must be minimum 12 characters.",
                      },
                    ]}
                  >
                    {/* <Input
                      placeholder="Ohip Number"
                      name="ohipNumber"
                      maxLength={10}
                      onChange={handleChange}
                    /> */}

                    {/* <MaskInput alwaysShowMask maskChar="_" mask="0000-000-000" value="ohipNumber" onChange={handleChange} /> */}
                    <MaskInput alwaysShowMask maskChar="_" mask="0000-000-000" onChange={handleChange} />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="insuranceVersionCode"
                    label="Insurance Version Code"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter Insurance Version Code",
                  //   },
                  // ]}
                  >
                    <Input
                      style={{textTransform : "uppercase"}}
                      placeholder="Insurance Version Code"
                      name="insuranceVersionCode"
                      maxLength={2}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
              </div>



              {/* card design start */}
              <div className={style.div_row_flex}>

                <div className={style.div_card_main} style={{
                  backgroundImage: `url("Images/problems/card.png")`
                }}>
                  <div>
                    <div className={style.div_card_below} style={{ paddingTop: 0 }}>
                      <div style={{ width: "100%" }}>
                        <label className={style.lbl_card}>Name</label>
                        <div className={style.div_row_name}>
                          <label className={style.lbl_card_name}>{formValues.FirstName} &nbsp;</label>
                          {/* <label className={style.lbl_card_name}>{formValues.middlename} &nbsp;</label> */}
                          <label className={style.lbl_card_name}>{formValues.LastName}</label>
                        </div>
                      </div>
                    </div>
                    <div className={style.div_card_below}>
                      <div style={{ width: "67%" }}>
                        <label className={style.lbl_card}>Card Number</label>
                        <Form.Item style={{ marginBottom: "5px" }}
                          // name="ohipNumber">
                          name="ohipNumber_name">
                          <Input size="large" onChange={handleChange} className={style.input_card} disabled={true} />
                        </Form.Item>
                      </div>
                      <div style={{ width: "25%" }}>
                        <label className={style.lbl_card}>Version Code</label>
                        <Form.Item style={{ marginBottom: "5px" }}
                          name="insuranceVersionCode"
                        >
                          <Input size="large" onChange={handleChange} className={style.input_card_vcode} disabled={true} />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={style.div_card_below} style={{ paddingTop: 0 }}>
                      <div style={{ width: "100%" }}>
                        <label className={style.lbl_card}>DOB</label>
                        <div className={style.div_row_name}>
                          <label className={style.lbl_card_name}>{getDateFormated(dateOfBirth)}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* card design end */}

            </div>
          </div>

          <div
            className="row ml-1 mr-1 border-top"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="pt-4 pr-3">
              <Form.Item>
                <Button
                  style={{ width: 155, height: "auto" }}
                  htmlType="submit"
                  className="btn btn-primary px-5"
                  loading={btnLoader}
                  onClick={checkAddress}
                >
                  {/* {_.isEmpty(editFamilyMember) ? "Submit" : "Edit"} */}
                  Submit
                </Button>
              </Form.Item>
            </div>
            <div className="pt-4 pr-3">
              <Form.Item>
                <Button
                  style={{ width: 155, height: "auto" }}
                  htmlType="button"
                  onClick={close}
                  className="btn btn-light px-5"
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form >
      </Modal >
    </>
  );
};

export default AddFamilyMember;
