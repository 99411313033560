import { Modal, Tabs, Button, notification, Spin } from "antd";
import style from "./style.module.scss";
import {
  VideoCameraFilled,
  PhoneFilled,
  CustomerServiceFilled,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useState, useEffect } from "react";
import { GET, POST, PUT } from "../../services/common.api";
// import { Editor } from 'primereact/editor'
import { sanitize } from "dompurify";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faHistory,
  faNotesMedical,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faNoteSticky } from "@fortawesome/free-regular-svg-icons";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { base64toBlob } from "../../utils/common";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const PatientDetail = ({ title, open, close, appointmentId, type }) => {
  const { TabPane } = Tabs;
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [doctorNoteEditMode, setDoctorNoteEditMode] = useState(true);
  const [patientNoteEditMode, setPatientNoteEditMode] = useState(true);
  const [todayDoctorNote, setTodayDoctorNote] = useState([]);
  const [todayPatientNote, setTodayPatientNote] = useState([]);
  const [doctorNote, setDoctorNote] = useState();
  const [patientNote, setPatientNote] = useState();
  const [patientData, setPatientData] = useState([] as any);
  const [prescription, setPrescription] = useState([] as any);
  const [doctorPreviousNotes, setDoctorPreviousNotes] = useState([]);
  const [patientPreviousNotes, setPatientPreviousNotes] = useState([]);
  const [doctorDetails, setDoctorDetails] = useState([] as any);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [insuranceData, setInsuranceData] = useState(undefined);

  const userData = useSelector((state: any) => state.userReducer.user);
  const currentAppointmentInfo = useSelector(
    (state: any) => state.curentAppointment
  );
  const [PdfString, setPdfString] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { id } = useParams();

  const saveDoctorNote = (noteType, noteId) => {
    let noteData;
    if (noteType === "DOCTOR") {
      noteData = doctorNote;
    } else if (noteType === "PATIENT") {
      noteData = patientNote;
    }
    if (doctorNote || patientNote) {
      if (noteId) {
        const updatePaylod = {
          notes: noteData?.notes || "<p>Please enter note</p>",
          id: noteId,
        };
        try {
          PUT("note", updatePaylod).then((response) => {
            if (response?.data?.statusCode === 200) {
              if (noteType === "DOCTOR") {
                setDoctorNoteEditMode(false);
              } else {
                setPatientNoteEditMode(false);
              }
              getPreiviousNotes();
              // notification.success({
              //   message: 'Note has been updated successfully.',
              // })
            } else {
              // notification.error({
              //   message: 'Error while update note.',
              // })
            }
          });
        } catch (error) {
          // notification.error({
          //   message: 'Error while update note.',
          // })
        }
      } else {
        const paylod = {
          notetype: noteType,
          createdate: Date.now(),
          createuserid: 1,
          notes: noteData?.notes || "<p>Test</p>",
          appointmentid: id,
        };
        try {
          POST("/note", paylod).then((response) => {
            if (response?.data?.statusCode === 200) {
              if (noteType === "DOCTOR") {
                setDoctorNoteEditMode(false);
              } else {
                setPatientNoteEditMode(false);
              }
              getPreiviousNotes();
              // notification.success({
              //   message: 'Note has been saved successfully.',
              // })
            } else {
              // notification.error({
              //   message: 'Error while save note.',
              // })
            }
          });
        } catch (error) {
          // notification.error({
          //   message: 'Error while save note.',
          // })
        }
      }
    } else {
      // notification.error({
      //   message: 'Please enter note.',
      // })
    }
  };

  const selectedDoctorDetails = async () => {
    console.log(appointmentId,"-=-=-=-=-=-=-=-=-=-===-=-=-=- in selected Detail doctor");
    console.log(appointmentId?.providerid);
    console.log(appointmentId?.doctor_id);
    console.log(appointmentId?.doctorid,"doctorid");

    if (appointmentId?.providerid !== undefined || appointmentId?.doctorid !== undefined) {
      try {
        setLoader(true);
        const data = await GET(`companyemployee/${appointmentId?.providerid || appointmentId?.doctorid}`);
        console.log(data?.data?.[0], "doctor Details");
        setDoctorDetails(data?.data?.[0]);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        notification.warning({
          message: error.message,
        });
      }
    } else {
      try {
        setLoader(true);
        const data = await GET(`companyemployee/${appointmentId?.doctor_id}`);
        console.log(data?.data?.[0], "doctor Details");
        setDoctorDetails(data?.data?.[0]);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        notification.warning({
          message: error.message,
        });
      }
    }
  };

  const getPreiviousNotes = async () => {
    try {
      setTodayDoctorNote([]);
      setTodayPatientNote([]);

      console.log(appointmentId,"appointmentId")
  

      let aid;
      if (type === 'prescription') {
        aid = appointmentId?.appoinment_id || appointmentId?.appointment_id
      } else {
        aid = appointmentId?.id
      }


      console.log(aid,"aiddidiidididd",type)

      const preiviousNotesData = await GET(
        `note?appoinmentId=${aid}`
      );
      console.log(preiviousNotesData.data?.body, "preiviousNotesData");
      const todayDate = moment(new Date()).format("MM/DD/YYYY");
      const notes = preiviousNotesData?.data?.body;
      const previousDoctorNotes = [];
      const previousPatientNotes = [];

      const todayDoctorNote = [];
      const todayPatientNote = [];
      // if (notes.toString() === 'Note type is required!') {
      notes.forEach(async (note) => {
        const noteDate = moment(note.createdate).format("MM/DD/YYYY");
        if (note.notetype === "DOCTOR") {
          if (todayDate === noteDate) {
            // await setTodayDoctorNote(note)
            await todayDoctorNote.push(note);

          } else {
            previousDoctorNotes.push(note);
          }
        } else if (note.notetype === "PATIENT") {
          if (todayDate === noteDate) {
            await todayPatientNote.push(note);
          } else {
            previousPatientNotes.push(note);
          }
        }
      });

      setTodayDoctorNote(todayDoctorNote);
      setTodayPatientNote(todayPatientNote);

      setDoctorPreviousNotes(previousDoctorNotes);
      setPatientPreviousNotes(previousPatientNotes);
      console.log(previousDoctorNotes, "doc note");
      console.log(previousPatientNotes, "PATIENT note");


      // }
    } catch (error) {
      // notification.warning({
      //   message: error.message,
      // })
    }
  };

  const changeTab = (noteType) => {
    if (noteType === "DOCTOR" || noteType === "PATIENT") {
      if (id) {
        // getPreiviousNotes(noteType)
      }
    }
  };

  // const getPatientDetails = async () => {
  //   try {
  //     const appointPatient = await GET(`appointment/${appointmentId}`)
  //     console.log('appointPatient: ', appointPatient)
  //     setPatientData(appointPatient?.data?.body)
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   getPreiviousNotes()
  // }

  const getInsuranceDetails = async () => {
    console.log(appointmentId, "appoint*-*-*-**-*-***-")
    try {
      const res = await GET(`user/insurance/${appointmentId?.patientid || appointmentId?.patient_id}`);
      console.log(res?.data[0], "getInsuranceInfo");
      setInsuranceData(res?.data[0]);
    } catch (err) {
      console.log("error", err);
    }
  };

  const getPrescription = () => {
    console.log(appointmentId, "appoint*-*-*-**-*-***- prescription",type)

    async function fetchMyAPI() {
      if (type == 'prescription') {
        let aidd;
        if(appointmentId.appoinment_id){
          aidd = appointmentId.appoinment_id;
        }else{
          aidd = appointmentId.appointment_id;
        }

        if(aidd != null){
          const prescription = await GET(
            `prescriptions?appoinmentId=` + aidd
          );
          console.log(prescription, "prescription data ");
  
          if(prescription?.data?.body?.length == 0){
            setPrescription([]);
          }else{
            let prescriptionData = [];
            let newData = prescription?.data?.body;
            newData?.forEach((element,index) => {
              element?.drugs.forEach((elementDrug) => {
                prescriptionData.push(elementDrug);
              });
              if(index == (newData?.length - 1)){
                setPrescription(prescriptionData);
              }
            });
          }
        }
      } else {
        if(appointmentId?.id != null){
          const prescription = await GET(
            `prescriptions?appoinmentId=` + appointmentId?.id
          );
          console.log(prescription, "prescription data");
          if(prescription?.data?.body?.length == 0){
            setPrescription([]);
          }else{
            let prescriptionData = [];
            let newData = prescription?.data?.body;
            newData?.forEach((element,index) => {
              element?.drugs.forEach((elementDrug) => {
                prescriptionData.push(elementDrug);
              });
              if(index == (newData?.length - 1)){
                setPrescription(prescriptionData);
              }
            });
          }
        }
      }
    }
    fetchMyAPI();
  };

  useEffect(() => {
    if (open == true) {

      selectedDoctorDetails();
      getPreiviousNotes();

      if (currentAppointmentInfo?.appointmentRequestType == 'familyMember') {
        setPatientData(currentAppointmentInfo?.familyMember);
      } else {
        setPatientData(userData);
      }
      getPrescription();
      getInsuranceDetails();
    }
  }, [open]);

  const showPdf = async (item) => {
    console.log("preId: ", item);
    console.log("preId: ", item.prescription_id);
    let preId = item.prescription_id;
    try {
      const paylod = {
        prescriptionId: preId,
      };
      POST("generatepdf", paylod).then((response) => {
        console.log("response: ", response);
        if (response?.data?.statusCode === 200) {
          const url = URL.createObjectURL(base64toBlob(response?.data?.body));
          console.log(url, "url pdf");
          setPdfString(url);
          setIsModalVisible(true);
        } else {
          notification.error({
            message: "Error while save prescription.",
          });
        }
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };

  return (
    <div>
      <Modal
        title={title}
        visible={open}
        footer={null}
        onOk={close}
        onCancel={close}
        width={1300}
        centered
      >
        <div className="content" style={{ paddingTop: 0 }}>
          <div className="p-4">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className={`${style.div_title_decorate_patient}`}>
                  <FontAwesomeIcon icon={faUser} className="mr-3" />
                  <h4 className="m-0">Patient Details</h4>
                </div>
              </div>
            </div>
            <div className="row mt-2 ml-1">
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>Name : </span>
                    {currentAppointmentInfo?.appointmentRequestType ==
                    "familyMember" ? (
                      <span>{patientData?.userName}</span>
                    ) : (
                      <span>
                        {patientData?.FirstName} {patientData?.LastName}
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>
                      Insurance Service Number :{" "}
                    </span>
                    <span>{patientData?.Insurancesvcnum || "NA"}</span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>DOB : </span>
                    {currentAppointmentInfo?.appointmentRequestType !=
                    "familyMember" ? (
                      <span>
                        {moment(patientData?.DOB).format("YYYY-MM-DD") || ""}{" "}
                      </span>
                    ) : (
                      <span>
                        {moment(patientData?.dateOfBirth).format(
                          "YYYY-MM-DD"
                        ) || ""}{" "}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="row ml-1">
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>Gender : </span>
                    <span>{patientData?.gender || "Male"}</span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>Family Physician : </span>
                    <span>{patientData?.familyphysician || "NA"}</span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>Phone Number : </span>
                    <span>
                      {patientData?.Phone ||
                        userData?.phoneNumber ||
                        "+1 11255455"}{" "}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row ml-1">
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>Address : </span>
                    {currentAppointmentInfo?.appointmentRequestType !=
                    "familyMember" ? (
                      <span>
                        {patientData?.Address1 || "Toronto"},
                        {patientData?.Country || "Canada"}
                      </span>
                    ) : (
                      <span>{patientData?.address || "Toronto"}</span>
                    )}
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>Province : </span>
                    <span>
                      {patientData?.province ||
                        userData?.State ||
                        "British Columbia"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>OHIP : </span>

                    {/* <span>{currentAppointmentInfo?.Insurance?.insurance_number || "NA"} - {currentAppointmentInfo?.Insurance?.insurance_vc || "NA"} </span> */}
                    {insuranceData ? (
                      <span>
                        {insuranceData?.insurance_number} -{" "}
                        {insuranceData?.insurance_vc}{" "}
                      </span>
                    ) : (
                      "NA"
                    )}

                    {/* <span>{currentAppointmentInfo?.Insurance?.ohipNumber || "NA"} - {currentAppointmentInfo?.Insurance?.insurance_vc || "NA"} </span> */}
                    {/* <span>{currentAppointmentInfo?.Insurance?.insurance_number || "NA"} - {currentAppointmentInfo?.Insurance?.insurance_vc || "NA"} </span> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Doctor List */}

        {loader ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            <div className="content" style={{ paddingTop: 0 }}>
              <div className="p-4">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className={`${style.div_title_decorate_patient}`}>
                      <FontAwesomeIcon icon={faUser} className="mr-3" />
                      <h4 className="m-0">Doctor Details</h4>
                    </div>
                  </div>
                </div>

                <div className="row mt-2 ml-1">
                  {doctorDetails?.firstname == null &&
                  doctorDetails?.middlename == null &&
                  doctorDetails?.lastname == null ? null : (
                    <div className="col-md-4">
                      <div className="ml-0">
                        <p>
                          <span className={style.text_bold}>Name : </span>
                          <span>
                            {doctorDetails?.firstname}{" "}
                            {doctorDetails?.middlename}{" "}
                            {doctorDetails?.lastname}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="col-md-4">
                    {doctorDetails?.address1 == null ||
                    (doctorDetails?.address1 == "" &&
                      doctorDetails?.address2 == null) ||
                    doctorDetails?.address2 == "" ? null : (
                      <div className="ml-0">
                        <p>
                          <span className={style.text_bold}>Address : </span>
                          <span>
                            {doctorDetails?.address1} {doctorDetails?.address2}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    {doctorDetails?.postalcode == null ||
                    doctorDetails?.postalcode == "" ? null : (
                      <div className="ml-0">
                        <p>
                          <span className={style.text_bold}>
                            Postal code :{" "}
                          </span>
                          <span>{doctorDetails?.postalcode} </span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row ml-1">
                  <div className="col-md-4">
                    {doctorDetails?.email == null ||
                    doctorDetails?.email == "" ? null : (
                      <div className="ml-0">
                        <p>
                          <span className={style.text_bold}>Email : </span>
                          <span>{doctorDetails?.email}</span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    {doctorDetails?.gender == null ||
                    doctorDetails?.gender == "" ? null : (
                      <div className="ml-0">
                        <p>
                          <span className={style.text_bold}>Gender : </span>
                          <span>{doctorDetails?.gender}</span>
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="col-md-4">
                    <div className="ml-0">
                      {doctorDetails?.department == null ? null : (
                        <p>
                          <span className={style.text_bold}>Department : </span>
                          <span>{doctorDetails?.department}</span>
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="ml-0">
                      {doctorDetails?.phone == null ||
                      doctorDetails?.phone == "" ? null : (
                        <p>
                          <span className={style.text_bold}>
                            Phone Number :{" "}
                          </span>
                          <span>{doctorDetails?.phone} </span>
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="ml-0">
                      {doctorDetails?.fax == null ? null : (
                        <p>
                          <span className={style.text_bold}>Fax : </span>
                          <span>{doctorDetails?.fax},</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="pl-4 pr-4 pb-4">
          <div className="row">
            <div className="col-md-6">
              <div className={`${style.div_title_decorate_patient}`}>
                <FontAwesomeIcon icon={faNotesMedical} className="mr-3" />
                <h4 className="m-0">Patient Notes</h4>
              </div>
              {todayPatientNote.length ? (
                todayPatientNote.map((note) => (
                  <>
                    {note?.notes != "null" && note?.notes != null ? (
                      <div className={`${style.previous_notes} ml-3`}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitize(note?.notes),
                          }}
                          className={style.border_btm}
                        />
                        <p className={style.mb_0}>
                          last update :{" "}
                          {moment(note?.createdate).format(
                            "YYYY-MM-DD H:mm:ss"
                          )}
                        </p>
                      </div>
                    ) : (
                      <>
                        {todayPatientNote.length == 1 ? (
                          <div className={`${style.icon_div} ml-2`}>
                            <p>No doctor notes found</p>
                          </div>
                        ) : null}
                      </>
                    )}
                  </>
                ))
              ) : (
                <div className={`${style.icon_div} ml-2`}>
                  <p>No doctor notes found</p>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className={`${style.div_title_decorate_patient}`}>
                    <FontAwesomeIcon icon={faHistory} className="mr-3" />
                    <h4 className="m-0">Prescription History</h4>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-hover table-center mb-0">
                      {prescription?.length ? (
                        <>
                          <thead>
                            <tr>
                              <th>Drug Name</th>
                              <th>Date</th>
                              <th className="text-center">View </th>
                            </tr>
                          </thead>
                          {prescription?.length > 0 ? (
                            <tbody>
                              {prescription?.map((data) => {
                                return (
                                  <tr>
                                    <td>{data?.drug_name}</td>
                                    {/* <td>{item.drugs[0]?.drug_name}</td> */}
                                    <td>
                                      {moment(data?.createdate).format(
                                        `YYYY - MM - DD`
                                      )}
                                    </td>
                                    <td
                                      className="text-center"
                                      onClick={() => showPdf(data)}
                                    >
                                      <FontAwesomeIcon
                                        className="mr-1 iconn"
                                        icon={faEye}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          ) : null}
                        </>
                      ) : (
                        // <tbody>
                        //   <tr>
                        //     <div className={style.icon_div}>
                        //       <p>Not found any Prescription History</p>
                        //     </div>
                        //   </tr>
                        // </tbody>
                        <div className={style.icon_div}>
                          <p>No prescription history found</p>
                        </div>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        centered
        visible={isModalVisible}
        width={1000}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"> */}
        {/* <div style={{ height: "100%", width: '100%' }}> */}
        {/* <Viewer fileUrl={PdfString} /> */}
        {/* </div> */}
        {/* </Worker> */}

        <div className="pt-4">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer
              fileUrl={PdfString}
              plugins={[
                // Register plugins
                defaultLayoutPluginInstance,
              ]}
            />
          </Worker>
        </div>
      </Modal>
    </div>
  );
};

export default PatientDetail;
