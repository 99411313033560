/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import UserEventWorkshop from "./UserEventWorkshop";
import { GET } from "../../services/common.api";
import { TopBar } from "components";
import DashboardSidebar from "pages/dashboard/sidebar/sidebar";
import style from "./style.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { AppointmentRequestType, setSelectedFamilyMember } from "redux/actions/userActions";

const ViewEvents = () => {
  const { user } = useSelector((state) => state.userReducer);

  const [eventWorkShopList, setEventWorkShopList] = useState([]);
  const [allEventWorkShopList, setallEventWorkShopList] = useState([]);
  const [registeredEvent, setRegisteredEvent] = useState([]);
  const [wishlistEvent, setWishlistEvent] = useState([]);
  const [filterDate, setFilterDate] = useState();
  const dispatch = useDispatch();

  const getAllWishlistedEvent = async () => {
    const eventData = await GET(`eventwishlists/${user.id}`);
    const {
      data: { body },
    } = eventData;

    if (body && body.length > 0) {
      const workshops = body.filter((item) => {
        return item.event_type === "workshop";
      });
      setWishlistEvent(workshops);
    } else {
      setWishlistEvent([]);
    }
  };

  const getAllRegisteredEvent = async () => {
    console.log("user.id: ", user.id);
    const eventData = await GET(`eventregistrations/${user.id}`);
    console.log("eventData: ", eventData);
    const {
      data: { body },
    } = eventData;
    const workshops =
      body &&
      body.length &&
      body.filter((item) => {
        return item.event_type === "workshop";
      });

    const wishlistData = await GET(`eventwishlists/${user.id}`);

    const filteredWishlistData = wishlistData.data.body.filter((item) => {
      return item.event_type === "workshop";
    });

    workshops &&
      workshops.forEach((workshop) => {
        filteredWishlistData &&
          filteredWishlistData.forEach((wishlist) => {
            if (workshop.id === wishlist.id) {
              workshop.registered = true;
            }
          });
      });
    setRegisteredEvent(workshops);
  };

  const getAllRecurranceEvemt = async () => {
    console.log("event and workshop");
    try {
      const eventData = await GET(`event/recurrence`);
      console.log("eventData: ", eventData);
      const {
        data: { body },
      } = eventData;

      const workshops =
        body.length &&
        body.filter((item) => {
          return item.event_type === "workshop";
        });

      const registeredEvent = await GET(`eventregistrations/${user.id}`);
      const filteredworkshop =
        registeredEvent &&
        registeredEvent?.data?.body?.length &&
        registeredEvent?.data?.body?.filter((item) => {
          return item.event_type === "workshop";
        });

      workshops.forEach((workshop) => {
        filteredworkshop &&
          filteredworkshop.length &&
          filteredworkshop.forEach((filtered) => {
            if (workshop.id == filtered.id) {
              console.log("sdskdhbj");
              workshop.isRegistered = true;
            }
          });
      });
      console.log("workshops", workshops);

      setEventWorkShopList(workshops);
      setallEventWorkShopList(workshops);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));

    getAllRecurranceEvemt();
  }, []);

  const { TabPane } = Tabs;
  function callback(key) {
    if (key == 1) {
      getAllRecurranceEvemt();
    }
    if (key == 2) {
      getAllRegisteredEvent();
    }
    if (key == 3) {
      getAllWishlistedEvent();
    }
  }

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    // <>Hello</>
    <div className="pb-5">
      <TopBar />
      <div className="box">
        <div className={`${style.toggebtn} d-block d-xl-none`}>
          <i>
            <FontAwesomeIcon
              className="mr-1"
              icon={faEquals}
              onClick={showDrawer}
            />
          </i>
        </div>
        <div className="d-block d-xl-none">
          <p className="pageInfoM">Workshop</p>
          <h5 className="h5NewM"> Dashboard / Workshop</h5>
        </div>
        <div className="d-none d-xl-block">
          <p className="pageInfo">Workshop</p>
          <h5 className="h5New">
            {" "}
            <Link style={{ color: "white" }} to="/dashboard">
              Dashboard
            </Link>{" "}
            / Workshop
          </h5>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div
              className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar `}
              style={{ height: "81vh" }}
            >
              <DashboardSidebar />
            </div>

            <Drawer
              closable={false}
              width={300}
              placement="left"
              onClose={onClose}
              visible={visible}
              style={{ paddingTop: 0 }}
            >
              <div className="widget-profile pro-widget-content pt-0 pb-4">
                <div className="profile-info-widget">
                  <img src="../Images/logo.png" height="50" alt="" />
                </div>
              </div>
              <DashboardSidebar />
            </Drawer>
            <div className="col-md-7 col-lg-8 col-xl-10">
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="All" key="1">
                  <Row>
                    <Col span={24}>
                      <UserEventWorkshop
                        eventType="all"
                        requiredEvent={eventWorkShopList}
                        getAllEventAndWorkShops={getAllRecurranceEvemt}
                        setFilterDate={setFilterDate}
                        getAllRegisteredEvent={getAllRegisteredEvent}
                        filterDate={filterDate}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Registered" key="2">
                  <Row>
                    <Col span={24}>
                      <UserEventWorkshop
                        eventType="registered"
                        requiredEvent={registeredEvent}
                        getAllEventAndWorkShops={getAllRecurranceEvemt}
                        getAllRegisteredEvent={getAllRegisteredEvent}
                        getAllWishlistedEvent={getAllWishlistedEvent}
                        setFilterDate={setFilterDate}
                        filterDate={filterDate}
                      />
                    </Col>
                  </Row>
                </TabPane>
                {/* <TabPane tab="Wishlist" key="3">
                  <Row>
                    <Col span={24}>
                      <UserEventWorkshop
                        eventType="wishlist"
                        requiredEvent={wishlistEvent}
                        getAllEventAndWorkShops={getAllRecurranceEvemt}
                        getAllWishlistedEvent={getAllWishlistedEvent}
                        setFilterDate={setFilterDate}
                        filterDate={filterDate}
                      />
                    </Col>
                  </Row>
                </TabPane> */}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewEvents;
