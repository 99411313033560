import React, { useEffect, useState } from "react";
import Footer from "components/footer";
import { Button, notification, Steps, Spin, Select, Input, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  loadWhichVersion,
  selectPaymentProvider,
  setInsurance,
} from "../../redux/actions/userActions";
import style from "./style.module.css";
import Type from "../../config";
import { GET, POST } from "services/common.api";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import moment, { invalid } from "moment";
import { Link } from "react-router-dom";

const SelectedType = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Step } = Steps;
  const { Option } = Select;
  const [selectedCard, setSelectedCard] = useState("");
  const whichVersionState = useSelector(
    (state: any) => state.curentAppointment.whichVersion
  );
  const userData = useSelector((state: any) => state.userReducer.user);
  const [whichVersion, setWhichVersion] = useState("with_problem");
  const [insuranceData, setInsuranceData] = useState([] as any);
  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [insuranceType, setInsuranceType] = useState("PRIVATE_INSURANCE");
  const [formValues, setFormValues] = useState({} as any);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isSlotSelected, setSlotSelected] = useState(false);
  const appointmentTypeNew = useSelector((state: any) => state);
  // const [whichVersion] = useState("without_problem");

  const cardClick = async (selectedCard, item) => {
    console.log(selectedCard, "selectedCard");
    console.log(item, "item");
    let data = {
      // 'Provider-number': formValues.Insurancesvcnum,
      'Provider-number': '020497',
      'HCN': item.insurance_number,
      'VC': item.insurance_vc,
      'User': item.name
    }
    if (item.insurance_type == "OHIP") {
      setLoader(true);
      await axios.post('https://api.mdmax.ca/api/1.1/wf/api-validation-call', data, {
        headers:
        {
          "Authorization": `Bearer cee53e7aa78674f078e300e6d96d98ec`
          // "Authorization": `Bearer 894da2b4b1760319ae94cbfa73db5a10`
        }
      })
        .then(async (response: any) => {
          setLoader(false);
          if (response.data.response["MOH-card-status"] == "invalid") {
            notification.warning({
              message: 'Invalid / Expired OHIP Card',
            })
          } else {
            setSelectedCard(selectedCard);
            dispatch(setInsurance(item));
            if (item == "PRIVATE_PAY") {
              dispatch(selectPaymentProvider("PRIVATE_PAY"));
            } else {
              dispatch(selectPaymentProvider(item.insurance_type));
            }
            setSlotSelected(true);
          }
        }, (err) => {
          setLoader(false);
          notification.warning({
            message: "Missing parameter for Validation",
          })
        });
    } else {
      setSelectedCard(selectedCard);
      dispatch(setInsurance(item));
      if (item == "PRIVATE_PAY") {
        dispatch(selectPaymentProvider("PRIVATE_PAY"));
      } else {
        dispatch(selectPaymentProvider(item.insurance_type));
      }
      setSlotSelected(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getInsuranceDetails();
    if (Type.projectType != "without_problem") {
      setWhichVersion("with_problem");
      dispatch(loadWhichVersion("with_problem"));
    } else {
      setWhichVersion("without_problem");
      dispatch(loadWhichVersion("without_problem"));
    }
  }, []);

  const getInsuranceDetails = async () => {
    try {
      setLoader(true);
      const res = await GET(`user/insurance/${userData.id}`);
      console.log(res?.data, "getInsuranceInfo");
      setInsuranceData(res?.data);
      setLoader(false);
    } catch (err) {
      console.log("error", err);
      setLoader(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleSubmitModal = async (event: any) => {
    console.log("Success:", event);

    // const name = event?.target?.name;
    // const value = event?.target?.value;
    // // console.log(name, value);
    // setFormValues(formValues => ({ ...formValues, [name]: value }));

    // console.log(formValues, "-=-=-=============forororoorororororororororor============")



    // if (formValues?.Insurancesvcnum?.length == 10) {

    if (insuranceType == "OHIP") {
      const result = !!insuranceData.find(
        (item) => item.insurance_number === event.Insurancesvcnum
      );
      console.log(`resFind(search1): ${result}`);
      if (result) {
        notification.warning({
          message: "Insurance already added !",
        });
      } else {
        let data = {
          // 'Provider-number': formValues.Insurancesvcnum,
          "Provider-number": "020497",
          HCN: event.Insurancesvcnum,
          VC: event.insuranceVersionCode,
          // User: userData.FirstName,
          User: event.fname + " " + event.lname,
        };
        console.log(data, "check Api  Data");
        setBtnLoader(true);
        await axios
          .post("https://api.mdmax.ca/api/1.1/wf/api-validation-call", data, {
            headers: {
              Authorization: `Bearer cee53e7aa78674f078e300e6d96d98ec`,
              // Authorization: `Bearer 894da2b4b1760319ae94cbfa73db5a10`,
            },
          })
          .then(
            async (response: any) => {
              console.log(response, "responce");
              let D = moment(userData.DOB).format("YYYY-MM-DD");
              if (response?.data?.response["MOH-card-eligible"] == false) {
                setLoader(false);
                notification.warning({
                  message: "Invalid OHIP Card",
                });
                setBtnLoader(false);
              } else {
                if (response?.data?.response.DOB !== D) {
                  setLoader(false);
                  notification.warning({
                    message: "Date of birth should match with OHIP",
                  });
                  setBtnLoader(false);
                } else {
                  if (
                    response?.data?.response["First-name"].toLowerCase() !==
                    event.fname.toLowerCase()
                  ) {
                    setLoader(false);
                    notification.warning({
                      message: "First name should match with OHIP",
                    });
                    setBtnLoader(false);
                  } else {
                    if (
                      response?.data?.response["Last-name"].toLowerCase() !==
                      event.lname.toLowerCase()
                    ) {
                      setLoader(false);
                      notification.warning({
                        message: "Last name should match with OHIP",
                      });
                      setBtnLoader(false);
                    } else {
                      const Ohip = {
                        name: event?.fname + " " + event?.lname,
                        number: event?.Insurancesvcnum,
                        provider: "NA",
                        type: insuranceType,
                        vc: event?.insuranceVersionCode,
                        user_id: userData?.id,
                        is_valid: 1,
                      };
                      console.log(Ohip, "Ohip");
                      try {
                        const res = await POST("user/insurance", Ohip);
                        console.log(res, "family insurances------0 ");
                        notification.success({
                          message: "OHIP details added succesfully",
                        });
                        setSelectedCard(res.data.insertId)
                        setSlotSelected(true);
                        getInsuranceDetails();
                        setBtnLoader(false);
                        form.resetFields();
                      } catch (err) {
                        console.log("error", err);
                      }
                    }
                  }
                }
                setIsModalVisible(false);
              }
            },
            (err) => {
              setBtnLoader(false);
              console.log(err);
            }
          );
      }
    } else {
      const result = !!insuranceData.find(
        (item) => item.insurance_number === event.Insurancesvcnum
      );
      console.log(`resFind(search1): ${result}`);
      if (result) {
        notification.warning({
          message: "Insurance already added !",
        });
        setBtnLoader(false);
      } else {
        const Private = {
          name: event.name,
          number: event.Insurancesvcnum,
          provider: event.provider,
          type: insuranceType,
          vc: "NA",
          user_id: userData.id,
          is_valid: 0,
        };
        console.log(Private, "data Private");

        try {
          setBtnLoader(true);
          const res = await POST("user/insurance", Private);
          console.log(res, "family insurances------0 ");
          notification.success({
            message: "Insurance details added succesfully",
          });
          setSelectedCard(res.data.insertId)
          setSlotSelected(true);
          setBtnLoader(false);
          getInsuranceDetails();
          form.resetFields();
        } catch (err) {
          setBtnLoader(false);
          console.log("error", err);
        }
        setIsModalVisible(false);
      }
    }

    // } else {
    //     notification.error({
    //         message: "Error",
    //         duration: 2,
    //         description: "please Input 10 digit Insurance Number",
    //     });
    // }
  };

  // const handleOk = async () => {
  //     if (formValues?.Insurancesvcnum?.length == 10) {
  //         if (insuranceType == "OHIP") {
  //             checkCardValid()
  //         } else {
  //             const result = !!insuranceData.find(item => item.insurance_number === formValues.Insurancesvcnum);
  //             console.log(`resFind(search1): ${result}`);
  //             if (result) {
  //                 notification.warning({
  //                     message: 'Insurance already added !',
  //                 })

  //             } else {
  //                 const Private = {
  //                     name: "NA",
  //                     number: formValues.Insurancesvcnum,
  //                     provider: formValues.provider,
  //                     type: insuranceType,
  //                     vc: "NA",
  //                     user_id: userData.id,
  //                     is_valid: 0,
  //                 }
  //                 try {
  //                     setBtnLoader(true)
  //                     const res = await POST('user/insurance', Private)
  //                     console.log(res, "family insurances------0 ");
  //                     setBtnLoader(false);
  //                     notification.success({
  //                         message: 'Insurance added successfully',
  //                     })
  //                     getInsuranceDetails()
  //                 } catch (err) {
  //                     setBtnLoader(false)
  //                     console.log('error', err);
  //                 }
  //                 setIsModalVisible(false)
  //             }
  //         }

  //     } else {
  //         notification.error({
  //             message: "Error",
  //             duration: 2,
  //             description: "please Input 10 digit Insurance Number",
  //         });
  //     }
  // };

  // const checkCardValid = async () => {
  //     let data = {
  //         // 'Provider-number': formValues.Insurancesvcnum,
  //         'Provider-number': '020497',
  //         'HCN': formValues.Insurancesvcnum,
  //         'VC': formValues.insuranceVersionCode,
  //         'User': formValues.FirstName || userData.FirstName
  //     }
  //     console.log(data, "check Api  Data")
  //     setBtnLoader(true);
  //     await axios.post('https://api.mdmax.ca/api/1.1/wf/api-validation-call', data, { headers: { "Authorization": `Bearer 894da2b4b1760319ae94cbfa73db5a10` } })
  //         .then(async (response: any) => {
  //             console.log(response, "responce");
  //             if (response?.data?.response[("MOH-card-eligible")] == false) {
  //                 notification.warning({
  //                     message: 'Invalid Insurance Card',
  //                 })
  //                 setBtnLoader(false)
  //             } else {
  //                 const Ohip = {
  //                     name: "NA",
  //                     number: formValues.Insurancesvcnum,
  //                     provider: "NA",
  //                     type: insuranceType,
  //                     vc: formValues.insuranceVersionCode,
  //                     user_id: userData.id,
  //                     is_valid: 1,
  //                 }
  //                 try {
  //                     const res = await POST('user/insurance', Ohip)
  //                     console.log(res, "family insurances------0 ");
  //                     setBtnLoader(false);
  //                     notification.success({
  //                         message: 'Insurance added successfully',
  //                     })
  //                     getInsuranceDetails()
  //                 } catch (err) {
  //                     console.log('error', err);
  //                 }
  //                 setIsModalVisible(false)

  //             }
  //         }, (err) => {
  //             setBtnLoader(false);
  //             console.log(err)
  //         });
  // }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleSubmit = (event) => {
    console.log(event);
  };
  function handleChangeSelect(value) {
    console.log(value);
    setInsuranceType(value);
  }
  const handleChange = (event) => {
    const name = event?.target?.name;
    const value = event?.target?.value;
    console.log(name, value);
    setFormValues((formValues) => ({ ...formValues, [name]: value }));
    console.log(formValues, "formValues");
  };

  return (
    <div style={{ height: "100%" }}>
      {/* <TopBar /> */}
      <div className="box" style={{ marginTop: 0 }}>
        <p className="pageInfo">Providers</p>
        <h5 className="h5New">
          <Link style={{ color: "white" }} to="/dashboard">
            Dashboard
          </Link>{" "}
          / Select Provider
        </h5>
      </div>

      <div className="content contentSelect" style={{ height: 600 }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-0 col-md-1 col-xl-2"></div>
            <div className="col-sm-12 col-md-10 col-xl-8">
              <div className="stepDiv d-none d-sm-block sm-pt-5">
                {appointmentTypeNew.curentAppointment.appointmentType != "CallBackRequest" ? (
                  <Steps size="small">
                    <Step status="finish" title="Select Type" />
                    <Step status="finish" title="Select Doctor" />
                    <Step status="finish" title="Payment" />
                    {whichVersion !== "without_problem" ? (
                      <Step status="wait" title="Problems" />
                    ) : null}
                    <Step status="wait" title="Select Slot" />
                    <Step status="wait" title="Book Appointment" />
                  </Steps>
                ) : (
                  <Steps size="small">
                    <Step status="finish" title="Select Type" />
                    {/* <Step status="finish" title="Select Doctor" /> */}
                    <Step status="finish" title="Payment" />
                    {whichVersion == "with_problem" ? (
                      <Step status="finish" title="Problems" />
                    ) : null}
                    <Step status="wait" title="Select Callback" />
                    {/* <Step status="finish" title="Book Appointment" /> */}
                  </Steps>
                )}
                <div className="divPayment">
                  <h3>Select Payment Provider</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-0 col-md-1 col-xl-2"></div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="row text-left">
                <div className="col-sm-1 col-md-1 col-xl-2"></div>
                {loader ? (
                  <div className="spinner">
                    <Spin tip="Loading..."></Spin>
                  </div>
                ) : (
                  <div className="col-sm-10 col-md-10 col-xl-8 pb-5">
                    <div className="row pb-5">
                      {insuranceData?.map((item, index) => {
                        return (
                          <>
                            {/* <div className="col-sm-4">
                                                            <div key={index} className={`${whichVersion !== 'with_problem' ? 'div_flex_HelthCard' : 'div_flex_HelthCard_Center'}`}> */}
                            {item?.insurance_type === "OHIP" ? (
                              <>
                                {whichVersionState !== "with_problem" ? (
                                  <div className="col-sm-4">
                                    <div
                                      key={index}
                                      className={`${whichVersion !== "with_problem"
                                        ? "div_flex_HelthCard"
                                        : "div_flex_HelthCard_Center"
                                        }`}
                                    >
                                      <div
                                        className={style.div_inner_flex_Dynamic}
                                      >
                                        <div
                                          onClick={() =>
                                            cardClick(item?.id, item)
                                          }
                                          className={`${selectedCard === item?.id
                                            ? "card_selected"
                                            : "card_static"
                                            }`}
                                        >
                                          <div className="text-center">
                                            <img
                                              src="Images/problems/healthCard.png"
                                              alt=""
                                              className={style.productImg}
                                            />
                                            <span className="text-muted">
                                              Health Card
                                            </span>
                                          </div>
                                        </div>
                                        <div className="doc-info-center text-center">
                                          <h4 className="p-0 m-0">
                                            {item?.name} -{" "}
                                            {item?.insurance_type}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                            {item?.insurance_type === "PRIVATE_INSURANCE" ? (
                              <>
                                <div className="col-sm-4">
                                  <div
                                    key={index}
                                    className={`${whichVersion !== "with_problem"
                                      ? "div_flex_HelthCard"
                                      : "div_flex_HelthCard_Center"
                                      }`}
                                  >
                                    <div
                                      className={style.div_inner_flex_Dynamic}
                                    >
                                      <div
                                        className={`${selectedCard === item?.id
                                          ? "card_selected"
                                          : "card_static"
                                          }`}
                                        onClick={() =>
                                          cardClick(item?.id, item)
                                        }
                                      >
                                        <div className="text-center">
                                          <img
                                            src="Images/problems/insurance.png"
                                            alt=""
                                            className={style.productImg}
                                          />
                                          <span className="text-muted">
                                            Private Insurance
                                          </span>
                                        </div>
                                      </div>
                                      <div className="doc-info-center text-center">
                                        <h4 className="p-0 m-0">
                                          {item?.name} -{" "}
                                          {item?.insurance_provider}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                            {/* </div>
                                                        </div> */}
                          </>
                        );
                      })}

                      {/* <div className={style.div_inner_flex}>
                                                <div className={`${selectedCard === 'PRIVATE_PAY' ? "card_selected" : "card_static"}`} onClick={() => cardClick('PRIVATE_PAY', "PRIVATE_PAY")}>
                                                    <div className="text-center div_flex_HelthCard">
                                                        <img src="Images/problems/privatePay.png" alt="" className={style.productImg} />
                                                    </div>
                                                </div>
                                                <div className="doc-info-center text-center">
                                                    <h4 className="p-0 m-0">Private Pay</h4>
                                                </div>
                                            </div> */}

                      <div className="col-sm-4">
                        <div className="div_flex_HelthCard">
                          <div className={style.div_inner_flex_Dynamic}>
                            <div
                              className={`${selectedCard === "PRIVATE_PAY"
                                ? "card_selected"
                                : "card_static"
                                }`}
                              onClick={() =>
                                cardClick("PRIVATE_PAY", "PRIVATE_PAY")
                              }
                            >
                              <div className="text-center">
                                <img
                                  src="Images/problems/privatePay.png"
                                  alt=""
                                  className={style.productImg}
                                />
                                <span className="text-muted"> &nbsp; </span>
                              </div>
                            </div>
                            <div className="doc-info-center text-center">
                              <h4 className="p-0 m-0"> Private Pay</h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <div className="div_flex_HelthCard">
                          <div className={style.div_inner_flex_Dynamic}>
                            <div
                              className={`${selectedCard === "ADD_CARD"
                                ? "card_selected"
                                : "card_static"
                                }`}
                              onClick={showModal}
                            >
                              <div className="text-center">
                                <img
                                  src="Images/problems/plusD2.png"
                                  alt=""
                                  className={style.PlusImg}
                                />
                                <span className="text-muted"> &nbsp; </span>
                              </div>
                            </div>
                            <div className="doc-info-center text-center">
                              <h4 className="p-0 m-0"> Add Insurance </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-sm-1 col-md-1 col-xl-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        footer={null}
        title="Insurance Info"
        visible={isModalVisible}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={handleSubmitModal} layout="vertical">
          <div>
            <label className={style.inputLable}>Insurance Type *</label>
            <Select
              defaultValue="PRIVATE_INSURANCE"
              size="large"
              style={{ width: "100%" }}
              onChange={handleChangeSelect}
            >
              {/* <Option value="PRIVATE_INSURANCE">Private insurance</Option> */}
              <Option value="OHIP">OHIP</Option>
            </Select>

            <div className="row" style={{ marginTop: 20 }}>
              <div className="col-6">
                <div className="form-group">
                  <Form.Item
                    label="Insurance Number"
                    name="Insurancesvcnum"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Insurance Number!",
                      },
                      {
                        max: 10,
                        message: "must be maximum 10 characters.",
                      },
                    ]}
                  >
                    <Input size="large" onChange={handleChange} />
                  </Form.Item>
                </div>
              </div>
              {insuranceType == "OHIP" ? (
                <>
                  <div className="col-6">
                    <div className="form-group">
                      <Form.Item
                        label="Firstname"
                        name="fname"
                        rules={[
                          { required: true, message: "Please Enter Name!" },
                        ]}
                      >
                        <Input size="large" onChange={handleChange} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <Form.Item
                        label="Lastname"
                        name="lname"
                        rules={[
                          { required: true, message: "Please Enter Name!" },
                        ]}
                      >
                        <Input size="large" onChange={handleChange} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <Form.Item
                        label="Version code"
                        name="insuranceVersionCode"
                        rules={[
                          {
                            required: true,
                            message: "Please input Version code!",
                          },
                        ]}
                      >
                        <Input size="large" onChange={handleChange} style={{textTransform : "uppercase"}} maxLength={2}/>
                      </Form.Item>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-6">
                    <div className="form-group">
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                          { required: true, message: "Please Enter Name!" },
                        ]}
                      >
                        <Input size="large" onChange={handleChange} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <Form.Item
                        label="Provider"
                        name="provider"
                        rules={[
                          { required: true, message: "Please Enter Provider!" },
                        ]}
                      >
                        <Input size="large" onChange={handleChange} />
                      </Form.Item>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              type="primary"
              loading={btnLoader}
              // onClick={() => handleOk()}
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>

      {appointmentTypeNew.curentAppointment.appointmentType == "CallBackRequest" ? (
        <div className="footer">
          {whichVersion == "with_problem" ? (
            <Footer
              location="problems"
              pageName="selectType"
              disbleFooter={isSlotSelected}
            />
          ) : (
            <Footer
              location="select-callback"
              pageName="select-callback"
              disbleFooter={isSlotSelected}
            />
          )}
        </div>
      ) :
        <div className="footer">
          {whichVersion == "with_problem" ? (
            <Footer
              location="problems"
              pageName="selectType"
              disbleFooter={isSlotSelected}
            />
          ) : (
            <Footer
              location="BookAppointment"
              pageName="selectType"
              disbleFooter={isSlotSelected}
            />
          )}
        </div>}
    </div>
  );
};

export default SelectedType;
