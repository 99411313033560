/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Skeleton, Card, Avatar, Typography, Button, Divider, Space, Tag } from 'antd'
import _ from 'lodash'
import style from "./style.module.css";

const { Meta } = Card
const { Title, Text } = Typography

const HealthHistoryCard = ({ loading, setOfQuestionsAnswers, title }) => {
  var checkboxes1 = [];
  var radios = [];
  var checkboxes2 = [];
  const [loader, setLoader] = useState(true);


  useEffect(() => {
    setOfQuestionsAnswers.medicalHistory?.forEach((element, i) => {
      console.log(element);

      if (element.type == "checkboxes" && element.index == 0) {
        checkboxes1 = element.checkboxes?.filter(item => {
          return item.answer == "yes";
        })
        element.length = checkboxes1.length
      }

      if (element.type == "radio") {
        radios = element.radio?.filter(item => {
          return item.answer == "yes";
        })
        element.length = radios.length
      }

      if (element.type == "checkboxes" && element.index == 3) {
        checkboxes2 = element.checkboxes?.filter(item => {
          return item.answer == "yes";
        })
        element.length = checkboxes2.length
      }

      if (element.type == "textArea" && element.textArea.answer == "") {
        element.length = 0
      }

      if (setOfQuestionsAnswers.medicalHistory?.length - 1 == i) {
        setLoader(false)
      }

    });

    if (_.isUndefined(setOfQuestionsAnswers?.medicalHistory)) {
      setLoader(false);
    }

  }, []);

  return (
    <>
      <div>
        <div className={style.titleHeader}>Medical History</div>

        {!_.isUndefined(setOfQuestionsAnswers?.medicalHistory) &&
          setOfQuestionsAnswers?.medicalHistory?.map((questionAndAnswer, index) => {
            return (
              <div className={style.padd}>
              <div  key={index}>

              {questionAndAnswer?.title && questionAndAnswer?.length !== 0  && questionAndAnswer?.title !== "Have You Or Are You Receiving Any Drugs In Your Veins Such As:" && <h4 className={style.subtitleHeader}>{questionAndAnswer?.title}</h4>}
                {questionAndAnswer?.title && questionAndAnswer?.length !== 0  && questionAndAnswer?.title == "Have You Or Are You Receiving Any Drugs In Your Veins Such As:" && <h4 className={style.subtitleHeader}></h4>}

                {index == 0 && questionAndAnswer.type == "checkboxes" && questionAndAnswer.length !== 0 && (
                  <div className={style.divider}>
                    <div className="row">
                      <div className="col-12">
                        <Space size={[8, 16]} wrap>
                          {!questionAndAnswer?.displayQestion && (
                            questionAndAnswer?.checkboxes?.map((option) =>
                              option.answer !== '' && option.answer !== 'no' && (
                                <Tag color="#108ee9">{option.label}</Tag>
                              ),
                            )
                          )}
                        </Space>
                      </div>
                    </div>
                  </div>
                  
                )}

                {questionAndAnswer?.type == "textArea" && questionAndAnswer?.textArea.answer != "" && (
                  <div className={style.divider} style={{paddingTop: '20px'}}>
                    <div className="row">
                      <div className="col-12">
                        <Text style={{ display: 'block' }}><strong>{questionAndAnswer.textArea.label}</strong></Text>
                        <Text>{questionAndAnswer.textArea.answer}</Text>
                      </div>
                    </div>
                  </div>
                )}

                {index === 2 && questionAndAnswer.type == "radio" && questionAndAnswer.length !== 0 && (
                  <div className={style.divider} style={{paddingTop: '20px'}}>
                  <div className="row">
                    <div className="col-12">
                      <table className="table table-bordered">
                        <tbody>
                          {questionAndAnswer?.radio?.map(
                            (option) =>
                              option.answer !== '' &&
                              option.answer !== 'no' && (
                                <>
                                  <tr>
                                    <td>
                                      <Text>
                                      {option.label}
                                      </Text>
                                    </td>
                                    <td>
                                      <Text>
                                        {option.answer}
                                      </Text>
                                    </td>
                                  </tr>
                                </>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  </div>
                )}

                {index == 3 && questionAndAnswer.type == "checkboxes" && questionAndAnswer.length !== 0 && (
                  <div className={style.divider}>
                  <div className="row">
                    <div className="col-12">
                    <table className="table table-bordered">
                        <tbody>
                          {questionAndAnswer?.checkboxes?.map(
                            (option) =>
                              option.answer !== '' &&
                              option.answer !== 'no' &&(
                                <>
                                  <tr>
                                    <td>
                                      <Text>
                                      {option.label}
                                      </Text>
                                    </td>
                                    <td>
                                      <Text>
                                        {option.answer}
                                      </Text>
                                    </td>
                                  </tr>
                                </>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  </div>
                )}

                {/* <div className={index !== 1 || index !== 4 || index !== 5 ? `card` : null}>
              <div className={index !== 5 ? `card-body` : null}>
                <div className="row ml-4">
                  <>
                    {!questionAndAnswer?.displayQestion && (
                      <ul className="mt-n3">
                        {questionAndAnswer?.type === 'checkboxes' &&
                          questionAndAnswer?.checkboxes?.map(
                            (option) =>
                              option.answer !== '' &&
                              option.answer !== 'no' && (
                                <>
                                  <li className="mt-3">
                                    <strong>{option.label}</strong>
                                  </li>
                                </>
                              ),
                          )}
                      </ul>
                    )}
                  </>
                </div>
                {index === 1 && (
                  <div className="row ml-1 mr-3">
                    <ul className="col-md-12">
                      <li>
                        <strong>{questionAndAnswer.textArea.label}</strong>
                      </li>
                      <div>
                        {questionAndAnswer.textArea.answer
                          ? questionAndAnswer.textArea.answer
                          : 'Patient has not filled any information related to this'}
                      </div>
                    </ul>
                  </div>
                )}
                {index === 2 && questionAndAnswer.length !== 0 && (
                  <ul className="mt-n3 ml-4">
                    {questionAndAnswer?.radio?.map(
                      (option) =>
                        option.answer !== '' &&
                        option.answer !== 'no' && (
                          <>
                            <div className="row">
                              <div className="col-md-12">
                                <li className="mt-3">
                                  <strong>{option.label}</strong>
                                </li>
                              </div>
                              <div className="col-md-12">
                                <p>{option.answer}</p>
                              </div>
                            </div>
                          </>
                        ),
                    )}
                  </ul>
                )}
                {index === 3 && questionAndAnswer.length !== 0 && (
                  <ul className="mt-n3 ml-4">
                    {questionAndAnswer?.type === 'checkboxes' &&
                      questionAndAnswer?.checkboxes?.map(
                        (option) =>
                          option.answer !== '' &&
                          option.answer !== 'no' && (
                            <>
                              <div className="row">
                                <div className="col-md-12">
                                  <li className="mt-3">
                                    <strong>{option.label}</strong>
                                  </li>
                                </div>
                                <div className="col-md-12">
                                  <p>{option.answer}</p>
                                </div>
                              </div>
                            </>
                          ),
                      )}
                  </ul>
                )}
                {index === 4 && (
                  <div className="row ml-1 mr-3">
                    <ul className="col-md-12">
                      <li>
                        <strong>{questionAndAnswer.textArea.label}</strong>
                      </li>
                      <div>
                        {questionAndAnswer.textArea.answer
                          ? questionAndAnswer.textArea.answer
                          : 'Patient has not filled any information related to this'}
                      </div>
                    </ul>
                  </div>
                )}
              </div>
            </div> */}

              </div>
              </div>
            )
          })}


        



        {/* {setOfQuestionsAnswers?.medicalHistory ?
          <Card loading={loader} title={title || null} className="mb-4">
            {_.isUndefined(setOfQuestionsAnswers?.medicalHistory) && (
              <Text>This patient has not filled up their Health History yet</Text>
            )}
            {!_.isUndefined(setOfQuestionsAnswers?.medicalHistory) &&
              setOfQuestionsAnswers?.medicalHistory?.map((questionAndAnswer, index) => {
                return (
                  <div key={index}>

                    {questionAndAnswer?.title && questionAndAnswer?.length !== 0 && <h5>{questionAndAnswer?.title}</h5>}

                    {questionAndAnswer?.type == "textArea" && questionAndAnswer?.textArea.answer != "" && (
                      <div className="card">
                        <div className="card-body">
                          <div className="row ml-1 mr-3">
                            <ul className="col-md-12">
                              <li>
                                <strong>{questionAndAnswer.textArea.label}</strong>
                              </li>
                              <div>
                                {questionAndAnswer.textArea.answer ? questionAndAnswer.textArea.answer : 'Patient has not filled any information related to this'}
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}

                    {index == 0 && questionAndAnswer.type == "checkboxes" && questionAndAnswer.length !== 0 && (
                      <div className="card">
                        <div className="card-body">
                          <div className="row ml-4">
                            <div className="col-12">
                              {!questionAndAnswer?.displayQestion && (
                                <ul className="mt-n3">
                                  <div className="row">

                                    {questionAndAnswer?.checkboxes?.map(
                                      (option) =>
                                        option.answer !== '' &&
                                        option.answer !== 'no' && (
                                          <div className="col-lg-6 col-md-6 col-sm-12">
                                            <>
                                              <li className="mt-3">
                                                <strong>{option.label}</strong>
                                              </li>
                                            </>
                                          </div>
                                        ),
                                    )}
                                  </div>
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {index === 2 && questionAndAnswer.type == "radio" && questionAndAnswer.length !== 0 && (
                      <div className="card">
                        <div className="card-body">
                          <div className="row ml-4">
                            <ul className="mt-n3">
                              {questionAndAnswer?.radio?.map(
                                (option) =>
                                  option.answer !== '' &&
                                  option.answer !== 'no' && (
                                    <>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <li className="mt-3">
                                            <strong>{option.label}</strong>
                                          </li>
                                        </div>
                                        <div className="col-md-12">
                                          <p>{option.answer}</p>
                                        </div>
                                      </div>
                                    </>
                                  ),
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}

                    {index == 3 && questionAndAnswer.type == "checkboxes" && questionAndAnswer.length !== 0 && (
                      <div className="card">
                        <div className="card-body">
                          <div className="row ml-4">
                            <ul className="mt-n3">
                              {questionAndAnswer?.checkboxes?.map(
                                (option) =>
                                  option.answer !== '' &&
                                  option.answer !== 'no' && (
                                    <>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <li className="mt-3">
                                            <strong>{option.label}</strong>
                                          </li>
                                        </div>
                                        <div className="col-md-12">
                                          <p>{option.answer}</p>
                                        </div>
                                      </div>
                                    </>
                                  ),
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}

                    

                  </div>
                )
              })}
          </Card> : null
        } */}

      </div>
    </>
  )
}

export default HealthHistoryCard
