import React, { useEffect } from "react";
import { Modal, Form, Input, Rate, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import { POST } from "../../services/common.api";
import { ActionTypes } from "../../redux/constants/action-types";

const Rating = ({history}) => {
  // const [rating, setRating] = useState(0)
  const { isCallEndModal, setCallID } = useSelector((state) => state?.meeting);
  // const { user: { id } } = useSelector((state) => state?.userReducer ? state?.userReducer : null);
  const { user } = useSelector((state) => state?.userReducer);
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const postRating = async (values) => {
    if(user){
      POST("userratings", {
        ...values,
        callid: setCallID,
        isPatient: 1,
        userId: user?.user?.id,
      })
        .then(() => {
          form.resetFields();
          history.push("/");
        })
        .catch(() => {
          notification.error({
            message: "Somthing Went Wrong",
          });
        });
      dispatch({
        type: ActionTypes.CALL_END,
        payload: false,
      });
    }
  };

  const clearTheRatting = () => {
    history.push("/");
    form.resetFields();
    dispatch({
      type: ActionTypes.CALL_END,
      payload: false,
    });
  }

  return (
    <div>
      <Modal
        visible={isCallEndModal}
        footer={null}
        onOk={postRating}
        onCancel={clearTheRatting}
      >
        <Form layout="vertical" form={form} onFinish={postRating}>
          <h4 className="mt-3 text-center">
            How was the audio and video until then ?
          </h4>
          <div className="row">
            <div className="col-12 text-center">
              <Form.Item name="rating">
                <Rate style={{ fontSize: "xxx-large" }} />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Form.Item name="comments">
                <TextArea
                  className="form-control"
                  rows={4}
                  placeholder="Feedback"
                />
              </Form.Item>
            </div>
          </div>
          <div className="row ml-1 mr-1">
            <>
              <div className="pt-4 pr-3">
                <Form.Item>
                  <button type="submit" className="btn btn-primary">
                    submit
                  </button>
                </Form.Item>
              </div>
              <div className="pt-4 pr-3">
                <Form.Item>
                  <button type="button" className="btn btn-light" onClick={clearTheRatting}>
                    Cancel
                  </button>
                </Form.Item>
              </div>
            </>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Rating;
