import apiClient from "../axios";
import store from "store";

export function initCallHistory(payload) {
  return apiClient.post(`/callhistory`, payload, { headers: { Authorization: store.get('auth_token_refresh') } }).then((response) => {
    if (response) {
      return response.data;
    }
    return {};
  });
}

export function updateCallHistory(payload) {
  return apiClient.put(`/callhistory`, payload, { headers: { Authorization: store.get('auth_token_refresh') } }).then((response) => {
    if (response) {
      return response.data;
    }
    return {};
  });
}
