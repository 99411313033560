/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $createdAt: String
    $doctor: String!
    $doctorName: String
    $id: String!
    $patient: String!
    $patientName: String
  ) {
    createConversation(
      createdAt: $createdAt
      doctor: $doctor
      doctorName: $doctorName
      id: $id
      patient: $patient
      patientName: $patientName
    ) {
      createdAt
      doctor
      doctorName
      id
      appointmentId
      patient
      patientName
      staff
      staffName
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $author: String!
    $authorName: String!
    $authorType: String!
    $reciver: String
    $content: String
    $conversationId: String!
    $createdAt: String!
    $id: String!
  ) {
    createMessage(
      author: $author
      authorName: $authorName
      authorType: $authorType
      reciver: $reciver
      content: $content
      conversationId: $conversationId
      createdAt: $createdAt
      id: $id
    ) {
      author
      authorName
      authorType
      content
      conversationId
      createdAt
      id
    }
  }
`;
