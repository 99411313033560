import style from "./style.module.css";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCurrentAccount, authentication, setCognitoUserData, setUserToken, setUser, selectPaymentProvider, selectAppointmentType, bookAppointmentDoctor, selectedService, loadWhichVersion, callbackAppointment, setProblem, setMeetingEndData, setCallID, setEndModal } from "../../redux/actions/userActions";
import { Modal, Spinner } from "react-bootstrap";
import { Form, Input, Button, Checkbox, notification, Spin } from 'antd';
import * as AuthService from "../../services/auth";
import store from 'store'
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import WebrtcCallHandler from "lib/WebrtcCallHandler";
import SSEHandler from "lib/SSEHandler";


const currentConfig = Auth.configure();

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [resendlnk, setresendlnk] = useState(false);
  const [email, setEmail] = useState();
  const [form] = Form.useForm();
  const [noteModal, setNoteModal] = useState(false);
  const user = useSelector((state: any) => state.userReducer);
  useEffect(() => {
    logout();
    loadCaptchaEnginge(6, 'white', 'black', 'upper');
    console.log(user);
  }, []);

  const logout = () => {
    store.set('userauth',false);
    console.log("CLEANUP BEFORE LOGIN");
    console.log(user);
    AuthService.logout(); 
    dispatch(authentication(false));
    WebrtcCallHandler.getInstance().cleanUp();
    SSEHandler.getInstance().cleanUp();
    dispatch(setUser([]));
    store.set('userauth',false);
    store.remove("userid")
    store.remove("name")
    store.remove("email")
    store.remove("user")
    store.clearAll();
    
    
    // console.log("User");
    // dispatch(selectPaymentProvider([]));
    // console.log("Prov");
    // dispatch(selectAppointmentType([]));
    // console.log("App");
    // dispatch(bookAppointmentDoctor([]));
    // console.log("Doc APP");
    // dispatch(selectedService([]));
    // console.log("selectedService");
    // dispatch(loadWhichVersion([]));
    // console.log("Ver");
    // dispatch(callbackAppointment([]));
    // console.log("Callback");
    // dispatch(setProblem([]));
    // console.log("Problem");
    // dispatch(setMeetingEndData([]));
    // console.log("Meeting");
    // dispatch(setCallID([]));
    // console.log("CallerID");
    // dispatch(setEndModal(false));
    console.log("Modal");
  };


  const signIn = async (values: any) => {

    if (validateCaptcha((values.captcha).toUpperCase()) == true) {
      console.log("captcha metched");
      // loadCaptchaEnginge(6); 
      // values.captcha = "";

      setLoader(true);
      console.log("Success:", values);
      try {
        setEmail(values.email);
        const user = await Auth.signIn(values.email, values.password)
        console.log(user, "ppppp")
        AuthService.GetAuthenticationToken().then((res) => {
          console.log(res, "token res")
          store.set('auth_token_refresh', res?.idToken?.jwtToken);
          store.set('auth_token_exp', res?.idToken?.payload?.exp);
          store.set("email",values.email)
         
        })
        console.log( "QQQQ")
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch(setCognitoUserData(user));
          history.push("/complete-new-password");
        } else {
          if (user.username) {
            console.log( "RRRRR")
            dispatch(loadCurrentAccount());
            dispatch(authentication(true));
            setLoader(false);
            store.set('userauth',true);
            history.push("/dashboard");
            setNoteModal(true);
          }
        }
      } catch (error) {
        console.log("error: ", error);
        store.set('userauth',false);
        setLoader(false);
        if (error == 'UserNotConfirmedException: User is not confirmed.') {
          setresendlnk(true)
          notification.error({
            message: "Error",
            duration: 3,
            description: "Account was not verified, Please check your email and verify the link"
          })
        } else {
          notification.error({
            message: "Error",
            duration: 2,
            description: "Invalid Username or Password",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        }
      }
    }
    else {
      notification.error({
        message: "Error",
        duration: 2,
        description: "Captcha Does Not Match",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
      form.resetFields(["captcha"]);
    }
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
  };
  /* eslint-enable no-template-curly-in-string */
  const resendLink = async (event) => { 
    event.preventDefault();
    console.log("Resend Link:", email);
   await resendConfirmationCode();
  }
  async function resendConfirmationCode() {

    try {
        await Auth.resendSignUp(email);
        notification.success({
          message: "Sent",
          duration: 4,
          description: "An email with link was sent, Please check your email and verify the link"
        })
        console.log('code resent successfully');
    } catch (err) {
      notification.error({
        message: "Error",
        duration: 4,
        description: "There was a problem"
      })
        console.log('error resending code: ', err);
    }
  }

  

  return (
    <Form
      form={form}
      name="basic"
      onFinish={signIn}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      validateMessages={validateMessages}
    >
      <div className={style.div_container}>
        <div className={style.div_content}>
          <div className={style.div_image}>
            <div>
              {/* <p className="p_name">Welcome</p> */}
              <p className={style.p_name}>Sign in to Continue in ONRx</p>
            </div>
            <div className={style.div_round}>
              <img
                src="Images/logo.png"
                className={style.imgFluid}
                alt="Doccure Login"
              />
            </div>
          </div>

          <div className={style.div_form}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  // message: "Please enter your email!",
                },
              ]}
            >
              <Input size="large" placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password size="large" placeholder="Password" />
            </Form.Item>

            <div className="row">
              <div className="col-6  mb-3">
                <LoadCanvasTemplate />
              </div>

              <div className="col-6 mt-2 mb-3">
                <Form.Item
                  name="captcha"
                  rules={[
                    { required: true, message: "Please Enter the captcha" },
                  ]}
                >
                  <Input size="large" placeholder="Enter Captcha" />
                </Form.Item>
              </div>
            </div>

            {!loader ? (
              <button
                className={`${style.btnDashboard} btn btn-primary btn-block`}
                type="submit"
              >
                Login
              </button>
            ) : (
              <button className="btn btn-primary btn-block" type="submit">
                <Spinner
                  animation="border"
                  role="status"
                  style={{ color: "white", width: 25, height: 25 }}
                />
              </button>
            )}

            <div className="text-right mt-2">
              <Link to="/forgot-password" className="forgot-link">
                Forgot Password ?
              </Link>
            </div>
           
            {resendlnk ? (
            <div className="alert alert-primary text-center">
              <p> An email with link was sent, Please check your email and verify your account </p>
              <button onClick ={resendLink} className="btn btn-sm btn-primary">
                Ressend Link?
              </button>
            </div>):''}
        <div className={`text-center ${style.dontHave}`}>
          Don’t Have an Account ?
          <Link to="/register" style={{ color: "#1a6ab1" }}>
            {" "}
            Signup Now
          </Link>
        </div>
        <div style={{ marginTop: "10px", textAlign: "center", paddingTop: "10px", borderTop: "1px solid lightgrey", fontSize: "10px", color: "darkgrey" }}>
          The ONRx platform and it's services are not meant for medical emergency, If you have a medical emergency call <a style={{color: "#1a6ab1"}} >911 immediately</a>  or visit the emergency room of the nearest hospital.
        </div>
        <div className={`text-center  ${style.copy}`}>© 2022 ONRx</div>

          </div>
        </div>
        
        {/* {resendlnk ? (
            <div className="alert alert-primary text-center">
              <p> An email with link was sent, Please check your email and verify your account </p>
              <button onClick ={resendLink} className="btn btn-sm btn-primary">
                Ressend Link?
              </button>
            </div>):''}
        <div className={`text-center ${style.dontHave}`}>
          Don’t Have an Account ?
          <Link to="/register" style={{ color: "#1a6ab1" }}>
            {" "}
            Signup Now
          </Link>
        </div>
        <div style={{ marginTop: "10px" }}>
          The ONRx platform and it's services are not meant for medical emergency,<br></br>If you have a medical emergency call 911 immediately or visit the emergency <br></br>room of the nearest hospital.
        </div>
        <div className={`text-center  ${style.copy}`}>© 2022 ONRx</div> */}
      </div>
    </Form>

  );
};

export default Login;
