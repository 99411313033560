import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Rate,
  DatePicker,
  notification,
  Button,
  Steps,
  Select,
  Calendar,
  Popover,
  Spin,
  Tag,
  Typography,
} from "antd";
import { TopBar } from "components";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GET } from "../../services/common.api";
import Footer from "components/footer";
import moment from "moment-timezone";
import { selectedService } from "../../redux/actions/userActions";
import style from "./style.module.scss";
import _ from "lodash";
import { ArrowLeftOutlined, ArrowRightOutlined, BorderColor } from "@material-ui/icons";
import { Spinner } from "react-bootstrap";
import { IMG01 } from "./img";

const BookAppointment = () => {
  const dispatch = useDispatch();
  const { Step } = Steps;
  const { Option } = Select;
  const history = useHistory();
  const [DoctorDetail, setDoctorDetail] = useState({} as any);
  const [Today, setToday] = useState("");
  const [TodayDay, setTodayDay] = useState("");
  const [slotBook, setSlotBook] = useState([]);
  const [trueSlotVariable, setTrueSlotVariable] = useState(false);
  const [slotBook1, setSlotBook1] = useState([]);
  const [selectedData, setSelectedData] = useState({} as any);

  const [selectedCard, setSelectedCard] = useState("");
  const [loaderServices, setLoaderServices] = useState(false);
  const [loaderSlot, setLoaderSlot] = useState(false);

  const [selctedMonthh, setSelectedMonth] = useState(moment().format("MMM"));
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));

  const [weekDate] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [bookingPopover, setBookingPopover] = useState({} as any);
  const [services, setServices] = useState([] as any);
  const [isSlotSelected, setSlotSelected] = useState(false);
  const [doctorSlotSelected, setDoctorSlotSelected] = useState([] as any);
  const [selectedServiceValue, setSelectedServiceValue] = useState(null);
  const [currentDate, setCurrentDate] = useState(
    moment().format("YYYY-MM-DD") as any
  );
  const [restrictedArray, setRestrictedArray] = useState([] as any);
  const [slotsDisplayLoader, setSlotsDisplayLoader] = useState(false);
  const [chooseServices, setChooseServices] = useState(false);
  const [selectedServiceByUser, setSeletedService] = useState({} as any);

  const currentAppointmentInfo = useSelector(
    (state: any) => state.curentAppointment
  );

  const { Title, Text } = Typography;

  // const SelectedDoctorUserId = useSelector(
  //   (state: any) => state.curentAppointment.bookAppointmentDoctor.user_id
  // );
  const selectedCompanyEmpID = useSelector(
    (state: any) =>
      state.curentAppointment.bookAppointmentDoctor.companyEmployeeId
  );
  const selectedDoctor = useSelector(
    (state: any) => state.curentAppointment.bookAppointmentDoctor.doctor
  );
  const CurrentAppointmentData = useSelector(
    (state: any) => state.curentAppointment.selectedService
  );
  const [timeSlotsForDoctor, setTimeSlotsForDoctor] = useState({});

  const whichVersion = useSelector(
    (state: any) => state.curentAppointment.whichVersion
  );
  // const selectedDoctorID = localStorage.getItem("DOC_ID");
  // const serviceDuration = [10, 15, 20, 30, 60, 75, 90, 105, 120];
  const serviceDuration = [5,10, 15, 20, 30, 60, 75, 90, 105, 120]

  const availabilityData = [
    // { id: "1", time: "09:00 AM", availability: "true" },
    // { id: "2", time: "09:30 AM", availability: "true" },
    // { id: "3", time: "10:00 AM", availability: "true" },
    // { id: "4", time: "10:30 AM", availability: "true" },
    // { id: "5", time: "11:30 AM", availability: "true" },
    // { id: "6", time: "12:00 PM", availability: "true" },
    // { id: "7", time: "12:30 PM", availability: "true" },
    // { id: "8", time: "01:00 PM", availability: "true" },
    // { id: "9", time: "01:30 PM", availability: "true" },
    // { id: "10", time: "02:00 PM", availability: "true" },
    // { id: "11", time: "02:30 PM", availability: "true" },
    // { id: "12", time: "03:00 PM", availability: "true" },
    // { id: "13", time: "03:30 PM", availability: "true" },
    // { id: "14", time: "04:00 PM", availability: "true" },
    // { id: "15", time: "04:30 PM", availability: "true" },
    // { id: "16", time: "05:00 PM", availability: "true" },
    // { id: "17", time: "05:30 PM", availability: "true" },
  ];

  const goToBack = () => {
    history.goBack();
  };

  const covertInto15MinutesSlots = (startString, endString) => {
    let startTime;
    let endTime;
    const timeslots = [startTime];

    if (startString == 0) {
      startTime = "00:00:00";
    }
    if (endString == 0) {
      endTime = "00:00:00";
    }

    if (startString == 30) {
      startTime = "00:30:00";
    }

    if (endString == 30) {
      endTime = "00:30:00";
    }

    if (startString?.length == 3) {
      startTime =
        "0" + startString.charAt(0) + ":" + startString.substring(1) + ":00";
    }
    if (endString?.length == 3) {
      endTime =
        "0" + endString.charAt(0) + ":" + endString.substring(1) + ":00";
    }

    if (startString?.length == 4) {
      startTime =
        startString.substring(0, 2) + ":" + startString.substring(2) + ":00";
    }
    if (endString?.length == 4) {
      if (endString == 2359) {
        // endTime = "00:00:00";
        endTime = "00:00:00";
      } else {
        endTime =
          endString.substring(0, 2) + ":" + endString.substring(2) + ":00";
      }
    }
    timeslots.push(startTime);


    // let timeEndFinal = moment(endTime, 'HH:mm').subtract(10, 'minutes').format("HH:mm:ss");
    let timeEndFinal = moment(endTime, 'HH:mm').subtract(5, 'minutes').format("HH:mm:ss");
    console.log(timeEndFinal,"endTimeendTimeendTimeendTimeendTimeendTime")

    while (startTime != timeEndFinal) {
      if (timeEndFinal == "23:59:00") {
        break;
      }
      // startTime = addMinutes(startTime, "10");
      startTime = addMinutes(startTime, "5");
      timeslots.push(startTime);
    }

    return timeslots;
  };

  function addMinutes(starttime1, minutes) {
    const date1 = new Date(
      new Date("01/01/2015 " + starttime1).getTime() + minutes * 60000
    );

    const tempTime =
      (date1.getHours().toString().length == 1
        ? "0" + date1.getHours()
        : date1.getHours()) +
      ":" +
      (date1.getMinutes().toString().length == 1
        ? "0" + date1.getMinutes()
        : date1.getMinutes()) +
      ":" +
      (date1.getSeconds().toString().length == 1
        ? "0" + date1.getSeconds()
        : date1.getSeconds());
    return tempTime;
  }

  const convertAvailibiltyToActualTime = async (doctoravailabilityArray) => {
    const finalArray = {
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    };

    let currentDay;

    doctoravailabilityArray.forEach((everyDay) => {
      currentDay = everyDay.day;

      if (everyDay?.timings?.length) {
        everyDay?.timings?.forEach((timingForParticularDay) => {
          const reponse = covertInto15MinutesSlots(
            timingForParticularDay?.openingTime,
            timingForParticularDay?.closingTime
          );

          console.log(reponse,"reponsereponse")
          reponse?.forEach((slotsforDocTor, index) => {
            if (slotsforDocTor != undefined) {
              finalArray[currentDay].push({
                id: index,
                time: slotsforDocTor.substring(0, slotsforDocTor.length - 3),
                availability: "true",
              });
            }
          });
        });
      }
    });

    console.log(finalArray[moment().format("dddd")],"finalArray")

    getAppointments(currentDate, finalArray[moment().format("dddd")])
    // .then(() => {
    //   getRestrictedTime(currentDate);
    // });

    setTimeSlotsForDoctor(finalArray);
  };

  // useEffect(() => {
  //   if (trueSlotVariable) {
  //     getRestrictedTime(currentDate);
  //   }
  // }, [trueSlotVariable])

  const getDoctorsAvailabilty = async () => {
    const getAllAvailibilities = await GET(
      `availabilities?docId=${selectedCompanyEmpID}`
    );

    const tempArray = [
      {
        day: "Monday",
        timings: [],
        urgentTiming: { openingTime: "12:00 AM", closingTime: "2:00 AM" },
      },
      {
        day: "Tuesday",
        timings: [],
        urgentTiming: { openingTime: "", closingTime: "" },
      },
      {
        day: "Wednesday",
        timings: [],
        urgentTiming: { openingTime: "", closingTime: "" },
      },
      {
        day: "Thursday",
        timings: [],
        urgentTiming: { openingTime: "", closingTime: "" },
      },
      {
        day: "Friday",
        timings: [],
        urgentTiming: { openingTime: "", closingTime: "" },
      },
      {
        day: "Saturday",
        timings: [],
        urgentTiming: { openingTime: "", closingTime: "" },
      },
      {
        day: "Sunday",
        timings: [],
        urgentTiming: { openingTime: "", closingTime: "" },
      },
    ];

    console.log(getAllAvailibilities, "getAllAvailibilities")

    getAllAvailibilities?.data?.body?.forEach((item) => {
      _.find(tempArray, (o) =>
        o.day == item.day
          ? o.timings.push({
            closingTime: `${item.endtime}`,
            openingTime: `${item.starttime}`,
            id: item.id,
          })
          : null
      );
    });


    console.log(tempArray, "temparary arrraaayyyy");

    convertAvailibiltyToActualTime(tempArray);
  };

  const getRestrictedTime = async (startingDate,BookedSlots) => {
    const endinbgDate = moment(startingDate).add(1, "day").format("YYYY-MM-DD");

    const getRestrictedTime = await GET(
      `restrictedevent?companyEmployeeId=${selectedCompanyEmpID}&startDate=${startingDate}&endDate=${endinbgDate}`
    );
    setRestrictedArray(getRestrictedTime?.data?.body);
    console.log(getRestrictedTime?.data?.body, "getRestrictedTime");

    // Get Restricted Datatataa
    let vv = getRestrictedTime?.data?.body;
    if(vv?.length != 0){

      let slotsAllFilter = [];
      vv.forEach(element => {


        let t = element?.starttime == '12:00 AM' ? '0' : (element?.starttime == '12:30 AM' ? '30' : element?.starttime?.substring(0,5)) ;
        let d = element?.endtime == '12:00 AM' ? '0' : (element?.endtime == '12:30 AM' ? '30' : element.endtime?.substring(0,5));

        const reponse = covertInto15MinutesSlots(
          t == '0' ? t :  t.replace(":",""),
          d == '30' ? d : d.replace(":","") 
        );

        console.log(reponse,"response response response in get restriction page....");

        reponse?.forEach((slotsforDocTore, index) => {
          if(slotsforDocTore){
            slotsAllFilter.push(slotsforDocTore?.substring(0,5));
          }
        });
      });
      
      let d = BookedSlots.filter(val => {
        let ff = val.time;
        if(slotsAllFilter.includes(ff.substring(0,5))){
          return;
        }else{
          return val
        }
      });

      console.log(d,"getRestrictedTimegetRestrictedTimegetRestrictedTime")
      setSlotBook(d);
      setSlotBook1(d);
    }
  }

  const getUserImage = (imageUrl) => {
    if (
      imageUrl?.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) !=
      null
    ) {
      return imageUrl;
    }
    return `${process.env.REACT_APP_ASSET_URL}/${imageUrl}`;
  };

  useEffect(() => {
    setSelectedCard(CurrentAppointmentData?.serviceId);
    setSelectedTime(CurrentAppointmentData?.selectedTime);

    setSelectedData({
      serviceId: `${CurrentAppointmentData?.serviceId}`,
      selectedDate: moment().format("YYYY-MM-DD"),
    });
    setToday(moment().format("DD MMMM YYYY"));
    setTodayDay(moment().format("dddd"));
    getCurrentWeek();
    setDoctorDetail(selectedDoctor);

    getServices();
  }, []);

  useEffect(() => {
    localStorage.setItem("newDate", Today);
  }, [Today]);
  useEffect(() => {
    getDoctorsAvailabilty();
    setSlotsDisplayLoader(true);
  }, []);

  const getServices = async () => {
    setLoaderServices(true);
    const doctorServices = await GET(`services?id=${selectedCompanyEmpID}`);
    const temp = []
    if (doctorServices.data.statusCode !== 401) {
      doctorServices.data.body.forEach((element) => {
        if (element.active === 1) {
          temp.push(element)
        }
      })
    }

    if (doctorServices.data.statusCode == 200) {
      setServices(temp);
    }
    setLoaderServices(false);
  };

  const loopNumberBasedOnTime = (duration) => {
    const durationSlots = {
			5:0,
			10: 1,
			15: 2,
			20: 3,
			30: 5,
			45: 8,
			60: 11,
			75: 14,
			90: 17,
			105: 20,
			120: 23

      // 10: 0,
      // 15: 0,
      // 20: 1,
      // 30: 2,
      // 45: 2,
      // 60: 5,
      // 75: 4,
      // 90: 8,
      // 105: 6,
      // 120: 11,
    };

    return durationSlots[duration];
  };

  const timeZoneConvetor = (time, timezone) => {
    console.log(time, timezone, "avyu");
    if (!timezone) {
      return moment(time).utc().format("HH:mm A");
    }

    const modifiedToTimeZoneTime =
      moment(time, "YYYY-MM-DDTHH:mm:ss.SSSS").format("YYYY-MM-DDTHH:mm:ss") +
      timezone;

    const date = new Date(modifiedToTimeZoneTime);
    const localTimeZone = moment.tz.guess();
    const convertedTime = date.toLocaleString("en-US", {
      timeZone: localTimeZone,
      hour12: true,
    });
    console.log("convertedTime", convertedTime);
    const dfjbdfkjb = moment(convertedTime, "MM/DD/YYYY, hh:mm:ss A").format(
      // "HH:mm:ss"
      "HH:mm A"
    );
    console.log("dfjbdfkjb", dfjbdfkjb);
    return dfjbdfkjb;
  };


  const getAppointments = async (getDate, slotsFromDoctor = []) => {
    // return new Promise(async (resolve, reject) => {

      const todate = moment(getDate).add(1, "day").format("YYYY-MM-DD");
      setSlotsDisplayLoader(true);
      try {
        const bookedSlot = [];
        setLoaderSlot(true);
        const resAppointment = await GET(
          `appointment?doctorid=${selectedCompanyEmpID}&fromdate=${getDate}&todate=${todate}`
        );

        setBookingPopover(resAppointment.data[0]);
        console.log(resAppointment.data, "Here is api datatatatat");


        if (resAppointment.data) {
          resAppointment.data.forEach((data) => {
            if (data.startdatetime) {
              bookedSlot.push({
                // time: moment(data.startdatetime).format("HH:mm A"),
                // startTime: moment(data.startdatetime).format("HH:mm A"),
                time: timeZoneConvetor(data.startdatetime, data.timezone),
                startTime: timeZoneConvetor(data.startdatetime, data.timezone),
                // endTime: moment(data.enddatetime).utc().format("HH:mm:ss"),
                endTime: moment(data.enddatetime).utc().format("HH:mm A"),
                patientname: data.patientname,
                providername: data.providername,
                status: data.status,
                startDate: data.startdatetime,
                serviceduration: data.serviceduration,
                servicedesc: data.servicedesc,
              });

              console.log(bookedSlot, "bookedSlot....")

            }
          });
        }

        const availibilityNewResponse = [];

        console.log(availabilityData, "availabilityData")
        if (availabilityData.length) {
          let reponse = covertInto15MinutesSlots("0900", "1700");

          console.log(reponse, "ewewewewewewe")
          reponse.forEach((slotsforDocTor, index) => {
            if (slotsforDocTor != undefined) {
              availibilityNewResponse.push({
                id: index,
                time: slotsforDocTor.substring(0, slotsforDocTor.length - 3),
                availability: "true",
              });
            }
          });
        }

        console.log(slotsFromDoctor,"slotsFromDoctor")
        let slots =
          slotsFromDoctor.length > 0 ? slotsFromDoctor : availibilityNewResponse;

        // bookedSlot.forEach((newArr) => {
        //   slots.forEach((oldArr , index) => {
        //     const oldTime = moment(oldArr.time, "hh:mma").format("hh:mma");
        //     const newTime = moment(newArr.startTime, "HH:mm:ss").format("hh:mma");
        //     // console.log(newTime, oldTime, "anadr--")
        //     if (newTime === oldTime) {
        //       console.log(newTime, oldTime, "button disable");
        //       oldArr.availability = "false";
        //       if (serviceDuration.includes(newArr[0].serviceduration)) {
        //         console.log(newArr[0].serviceduration, index)
        //         const response = loopNumberBasedOnTime(newArr[0].serviceduration)

        //       }
        //     }
        //     return oldArr;
        //   });
        // });

        const slotAvailableArray = [];
        const availData = [];
        const newArray = [];

        console.log(slots, "all slots");
        
        slots.map((oldArr, index) => {
          console.log(oldArr, "all slots");
          slotAvailableArray.push(moment(oldArr.time, "HH:mm A").format("hh:mm A"));
          const oldTime = moment(oldArr.time, "HH:mm A").format("hh:mm A");

          console.log(oldArr, "oldArr")
          console.log(slotAvailableArray)
          console.log(oldTime, "oldTime", bookedSlot)

          const newArr = bookedSlot.filter(
            (na) => {
              console.log(na.startTime, "na.startTime")
              return oldTime == moment(na.startTime, "HH:mm:ss").format("hh:mm A") || oldTime == moment(na.startTime, "HH:mm A").format("hh:mm A")
            }
          );

          console.log(newArr, "new Arra")

          // const newTime = moment(newArr.startTime, 'HH:mm:ss').format('hh:mma')
          if (newArr && newArr.length > 0 && (newArr[0]?.status == "CONFIRMED" || newArr[0]?.status == "DRAFT")
            || newArr[0]?.status == "COMPLETED"
          ) {
            oldArr.availability = "false";
            oldArr.patientname = newArr[0].patientname;
            oldArr.providername = newArr[0].providername;
            oldArr.status = newArr[0].status;
            oldArr.startDate = newArr[0].startDate;

            console.log(serviceDuration,"serviceDuration")
            if (serviceDuration.includes(newArr[0].serviceduration)) {
              const response = loopNumberBasedOnTime(newArr[0].serviceduration);

              for (let i = 0; i < response; i++) {
                console.log("status", "newArr[0].status", newArr[0].status);

                newArray.push({
                  index: index + 1 + i,
                  availability: "false",
                  patientname: newArr[0].patientname,
                  providername: newArr[0].providername,
                  startDate: newArr[0].startDate,
                  status: newArr[0].status,
                });
              }
            }
          } else {
            oldArr.availability = "true";
            delete oldArr.patientname;
            delete oldArr.providername;
            delete oldArr.status;
          }
          availData.push(oldArr);
          setLoaderSlot(false);
          return oldArr;
        });

        newArray.forEach((slots) => {
          if (slots.index <= availData.length - 1) {
            availData[slots.index].availability = slots?.availability || "";
            availData[slots.index].patientname = slots?.patientname || "";
            availData[slots.index].providername = slots?.providername || "";
            availData[slots.index].status = slots?.status || "";
          } else {
            console.log("when out side the available slots", slots.index);
          }
        });

        setSlotsDisplayLoader(false);
        console.log(newArray,"nweArray")

        if (
          availData.length &&
          slotsFromDoctor.length &&
          getDate == moment().format("YYYY-MM-DD")
        ) {
          let availableSortedData = [];
          let doctorSortedData = [];

          availData.forEach((data) => {
            if (!moment().isAfter(moment(data.time, "HH:mm"))) {
              availableSortedData.push(data);
            }
          });

          slotsFromDoctor.forEach((data) => {
            if (!moment().isAfter(moment(data.time, "HH:mm"))) {
              doctorSortedData.push(data);
            }
          });

          console.log("doctorSortedData", doctorSortedData);
          console.log("availableSortedData", availableSortedData);
          console.log("slotsFromDoctor", slotsFromDoctor);
          console.log("availData", availData);


          if (doctorSortedData.length <= 0) {
            console.log("reache doctorSortedData.length <= 0");
            setSlotBook([]);
            setSlotBook1([]);
            getRestrictedTime(getDate,[]);
            return;
          }
          if (availableSortedData.length <= 0) {
            console.log("availableSortedData.length <= 0");
            setSlotBook([]);
            setSlotBook1([]);
            getRestrictedTime(getDate,[]);
            return;
          }

          if (doctorSortedData.length == availableSortedData.length) {
            console.log("doctorSortedData.length == availableSortedData.length");
            setSlotBook(availableSortedData);
            setSlotBook1(availableSortedData);
            getRestrictedTime(getDate,availableSortedData);
            return;
          }

          if (doctorSortedData.length > 0) {
            console.log("doctorSortedData.length > 0");
            setSlotBook(doctorSortedData);
            setSlotBook1(doctorSortedData);
          } else {
            console.log("doctorSortedData.length > 0 else ma");
            setSlotBook(availableSortedData);
            setSlotBook1(availableSortedData);
          }
        } else if (availData.length && getDate == moment().format("YYYY-MM-DD")) {
          let availableSortedData = [];

          availData.forEach((data) => {
            if (!moment().isAfter(moment(data.time, "HH:mm"))) {
              availableSortedData.push(data);
            }
          });

          if (availableSortedData.length <= 0) {
            console.log("availableSortedData.length <= 0");
            setSlotBook([]);
            setSlotBook1([]);
            getRestrictedTime(getDate,[]);
            return;
          }

          console.log("Sav baer availableSortedData.length <= 0")

          setSlotBook(availableSortedData);
          setSlotBook1(availableSortedData);
          getRestrictedTime(getDate,availableSortedData);
          return;
        }
        if (slotsFromDoctor.length == availData.length) {
          console.log("slotsFromDoctor.length == availData.length");
          // eslint-disable-next-line eqeqeq
          setSlotBook(availData);
          setSlotBook1(availData);
          getRestrictedTime(getDate,availData);
          return;
        }

        if (slotsFromDoctor.length > 0) {
          console.log("slotsFromDoctor.length > 0");
          setSlotBook(slotsFromDoctor);
          setSlotBook1(slotsFromDoctor);
          getRestrictedTime(getDate,slotsFromDoctor);
        } else {
          console.log("slotsFromDoctor.length > 0 else ma");
          setSlotBook(availData);
          setSlotBook1(availData);
          getRestrictedTime(getDate,availData);
        }
        setSlotBook(slots);
        getRestrictedTime(getDate,availData);
      } catch (error) {
        console.log(error);
      }
    //   resolve(true);
    // });
  }

  const getCurrentWeek = () => {
    const weekStart = moment();
    for (var i = 0; i <= 6; i++) {
      // console.log(moment(weekStart).add(i, "days").format("ddd Do,dddd"));
      const data = {
        day: moment(weekStart).add(i, "days").format("ddd"),
        date: moment(weekStart).add(i, "days").format("DD MMM YYYY"),
      };
      weekDate.push(data);
    }
  };

  const timeClick = (timeSelected) => {
    console.log(timeSelected, "timeSelected")
    if (timeSelected.status == "NOTAVAILABLE") { } else {
      selectedData.selectedTime = moment(timeSelected.time, ["h:mm:ss A"]).format(
        "HH:mm A"
      );
      // selectedData.selectedDate = Today;
      setSlotSelected(true);

      dispatch(selectedService(selectedData));
      setSelectedTime(timeSelected.time);
    }
  };

  // const payment = () => {
  //   localStorage.setItem("selectedDate", Today);
  //   localStorage.setItem("selectedTime", selectedTime);

  //   if (selectedTime != "") {
  //     history.push("/checkOut");
  //   } else {
  //     notification.error({
  //       message: "Error",
  //       duration: 2,
  //       description: "Select Appoiment Slot.",
  //       onClick: () => {
  //         console.log("Notification Clicked!");
  //       },
  //     });
  //   }
  // };

  // function handleChange(value) {
  //   console.log(value);
  //   selectedData.serviceId = value;

  //   const data = services.filter((item) => item.id == value)

  //   selectedData.serviceCost = data[0].servicecost;
  //   selectedData.name = data[0].serviceName;
  //   console.log(data)

  //   dispatch(selectedService(selectedData));

  // }

  function onPanelChange(value, mode) {
    selectedData.selectedDate = value;
    dispatch(selectedService(selectedData));
  }

  // const onChangeDate = (date, dateString) => {
  //   console.log(dateString);
  //   selectedData.selectedDate = dateString
  //   dispatch(selectedService(selectedData));
  //   getAppointments(moment(date).format("YYYY-MM-DD"));
  //   if (date != null) {
  //     setToday(date.format("DD MMMM YYYY"));
  //     setTodayDay(date.format("dddd"));
  //     localStorage.setItem("newDate", date);
  //   }
  // };

  const selectDateTime = (value) => {

    // getRestrictedTime(moment(value).format("YYYY-MM-DD"));

    setSelectedServiceValue(null);
    setSelectedTime(null);
    const m = value.format("YYYY-MM-DD").split("-")[1];
    const y = value.format("YYYY-MM-DD").split("-")[0];
    setSelectedMonth(moment.monthsShort(m - 1));
    setSelectedYear(y);
    setSlotSelected(false);
    selectedData.selectedDate = value.format("YYYY-MM-DD HH:mm");
    dispatch(
      selectedService({
        ...selectedData,
        selectedTime: "",
        serviceCost: "",
        name: "",
      })
    );
    const doctorSlotsAvailable = timeSlotsForDoctor;

    console.log(timeSlotsForDoctor, "timeSlotsForDoctortime")

    const changeDay = value.format("dddd");
    if (doctorSlotsAvailable[changeDay]?.length) {
      setDoctorSlotSelected(doctorSlotsAvailable[changeDay]);
      getAppointments(
        moment(value).format("YYYY-MM-DD"),
        doctorSlotsAvailable[changeDay]
      )
      // .then(() => {
        // getRestrictedTime(moment(value).format("YYYY-MM-DD"));
      // })
    } else {
      setDoctorSlotSelected([]);
      getAppointments(moment(value).format("YYYY-MM-DD"), [])
      // .then(() => {
        // getRestrictedTime(moment(value).format("YYYY-MM-DD"));
      // })

    }
    dispatch(selectedService(selectedData));
    setToday(value.format("DD MMMM YYYY"));
    setTodayDay(value.format("dddd"));
    localStorage.setItem("newDate", value);
  };

  const content = (
    <div>
      <p>{bookingPopover?.clinicname}</p>
    </div>
  );

  const doctorServiceSelect = (e: any) => {
    const manipulatedArray = [];
    const finalArray = [];
    // const durationSlots = {
    //   10: 0,
    //   15: 0,
    //   20: 1,
    //   30: 2,
    //   45: 2,
    //   60: 5,
    //   75: 4,
    //   90: 8,
    //   105: 6,
    //   120: 11,
    const durationSlots = {
			5:0,
			10: 1,
			15: 2,
			20: 3,
			30: 5,
			45: 8,
			60: 11,
			75: 14,
			90: 17,
			105: 20,
			120: 23
    };

    setSelectedCard(e);
    selectedData.serviceId = e;
    setSlotSelected(false);

    const data = services.filter((item) => item.id == e);

    selectedData.serviceCost = data[0].servicecost;
    selectedData.name = data[0].serviceName;

    const serviceDurationOfSelectedService = data[0].serviceduration;

    slotBook1.forEach((slots) => {
      if (!slots?.status) {
        manipulatedArray.push({ ...slots, status: "NOTAVAILABLE" });
      } else if (slots?.status == "CONFIRMED" || slots?.status == "DRAFT") {
        manipulatedArray.push(slots);
      } else {
        manipulatedArray.push(slots);
      }
    });

    manipulatedArray.forEach((slots, index) => {
      if (
        !manipulatedArray[
        index + durationSlots[serviceDurationOfSelectedService]
        ]
      ) {
        finalArray.push(slots);
      }

      if (
        manipulatedArray[
        index + durationSlots[serviceDurationOfSelectedService]
        ] >= manipulatedArray.length
      ) {
        finalArray.push({ ...slots, status: "NOTAVAILABLE" });
      }

      if (slots.status == "NOTAVAILABLE") {
        if (slots.availability == "true") {
          if (
            manipulatedArray[
              index + durationSlots[serviceDurationOfSelectedService]
            ]?.availability == "true"
          ) {
            finalArray.push({ ...slots, status: "AVAILABLE" });
          } else if (
            manipulatedArray[
              index + durationSlots[serviceDurationOfSelectedService]
            ]?.availability == "false"
          ) {
            finalArray.push(slots);
          }
        } else if (slots.availability == "false") {
          finalArray.push(slots);
        }
      } else {
        finalArray.push(slots);
      }
    });

    setSlotBook(finalArray);
    dispatch(selectedService(selectedData));
  };

  const setSlotColor = (status) => {
    const colorToSelect = {
      NOTAVAILABLE: "red",
      CONFIRMED: "#52c41a",
      DRAFT: "grey",
      COMPLETED: "#52c41a",
    };
    return colorToSelect[status];
  };

  function handleChangeService(value) {
    setSelectedTime("");
    setSelectedServiceValue(value);
    const manipulatedArray = [];
    const finalArray = [];
    const durationSlots = {
      // 10: 0,
      // 15: 0,
      // 20: 1,
      // 30: 2,
      // 45: 2,
      // 60: 5,
      // 75: 4,
      // 90: 8,
      // 105: 6,
      // 120: 11,

      5:0,
			10: 1,
			15: 2,
			20: 3,
			30: 5,
			45: 8,
			60: 11,
			75: 14,
			90: 17,
			105: 20,
			120: 23
    };

    selectedData.serviceId = value;
    
    const data = services.filter((item) => item.id == value);

    const serviceDurationOfSelectedService = data[0].serviceduration;
    currentAppointmentInfo.paymentProvider == "OHIP"
      ? (selectedData.serviceCost = "0")
      : (selectedData.serviceCost = data[0].servicecost);
    setChooseServices(true);
    setSeletedService(data[0]);

    console.log(slotBook1,"slotBook1slotBook1");

    slotBook1.forEach((slots) => {
      if (!slots?.status) {
        // manipulatedArray.push({ ...slots, status: "NOTAVAILABLE" });
        manipulatedArray.push({ ...slots, status: "NOTAVAILABLE" });
      } else if (slots?.status == "CONFIRMED" || slots?.status == "DRAFT") {
        manipulatedArray.push(slots);
      } else {
        manipulatedArray.push(slots);
      }
    }); 

    console.log(manipulatedArray,"manipulated array");
    console.log(durationSlots,"manipulated array");
    console.log(serviceDurationOfSelectedService,"manipulated array");

    selectedData.serviceduration = serviceDurationOfSelectedService;

    let finalArraySlot = [];
    manipulatedArray.forEach((slots, index) => {

      if (
        !manipulatedArray[
        index + durationSlots[serviceDurationOfSelectedService]
        ]
      ) {
        finalArraySlot.push(slots);
        setSlotBook(finalArraySlot);
        dispatch(selectedService(selectedData));
        return;
      }

      if (
        manipulatedArray[
        index + durationSlots[serviceDurationOfSelectedService]
        ] >= manipulatedArray.length
      ) {
        finalArraySlot.push({ ...slots, status: "NOTAVAILABLE" });
        setSlotBook(finalArraySlot);
        dispatch(selectedService(selectedData));
        return;
      }

      if (slots.availability == 'true') {
        let iteration = index + durationSlots[serviceDurationOfSelectedService];
        let checkArray = [];
        if (iteration <= 0) {
          if (manipulatedArray[iteration]?.availability) {
            checkArray.push(manipulatedArray[iteration]?.availability);
          }
          if (checkArray.includes("false")) {
            finalArraySlot.push({ ...slots, status: "NOTAVAILABLE" });
          }
          else {
            finalArraySlot.push({ ...slots, status: "AVAILABLE" });
          }
        } else {
          for (let i = index; i <= iteration; i++) {
            if (manipulatedArray[i]?.availability != undefined) {
              checkArray.push(manipulatedArray[i]?.availability);
            }
            if (i == iteration) {
              if (checkArray.includes("false")) {
                finalArraySlot.push({ ...slots, status: "NOTAVAILABLE" });
              }
              else {
                let count = 0;
                for(let j = 0;j <= durationSlots[serviceDurationOfSelectedService];j++){
                  let FinalVarIndex = parseInt(manipulatedArray[index + j]?.time?.replace(":",""));
                  let FinalVarIndex2;
                  if(j != 0){
                    FinalVarIndex2 = manipulatedArray[index + (j - 1)]?.time?.replace(":","");
                    let splll = manipulatedArray[index+ (j - 1)]?.time?.split(":")[1];
                    if (splll == 55) {
											FinalVarIndex2 = Number(FinalVarIndex2) + 45
										} else {
											FinalVarIndex2 = Number(FinalVarIndex2) + 5 
										}
                    // if(splll == 50){
                    //   FinalVarIndex2 = parseInt(FinalVarIndex2) + 50;
                    // }else{
                    //   FinalVarIndex2 = parseInt(FinalVarIndex2) + 10;
                    // }
                  }else{
                    FinalVarIndex2 = manipulatedArray[index + (j)]?.time?.replace(":","");
                  }
                  if(FinalVarIndex === parseInt(FinalVarIndex2)){
                    count += 1;
                  }
                }
                if((count - 1) == durationSlots[serviceDurationOfSelectedService]){
                  finalArraySlot.push({ ...slots, status: "AVAILABLE" });
                }else{
                  finalArraySlot.push({ ...slots, status: "NOTAVAILABLE" });
                }
              }
            }
          }
        }
      } else if (slots.availability == 'false') {
        finalArraySlot.push(slots)
      }
    });

    setSlotBook(finalArraySlot);
    dispatch(selectedService(selectedData));
  }

  return (
    <div>
      <div className="box" style={{ marginTop: 0 }}>
        <p className="pageInfo">BookAppointment</p>
        <h5 className="h5New">
          <Link style={{ color: "white" }} to="/dashboard">
            Dashboard
          </Link>{" "}
          / BookAppointment
        </h5>
      </div>

      <div className="row">
        <div className="col-sm-0 col-md-1 col-xl-2"></div>
        <div className="col-sm-12 col-md-10 col-xl-8">
          <div className="stepDiv d-none d-sm-block">
            <Steps size="small">
              <Step status="finish" title="Select Type" />
              <Step status="finish" title="Select Doctor" />
              {/* <Step status="finish" title="Payment" /> */}
              {whichVersion == "with_problem" ? (
                <Step status="finish" title="Problems" />
              ) : null}
              <Step status="finish" title="Select Slot" />
              <Step status="wait" title="Book Appointment" />
            </Steps>
          </div>
        </div>
        <div className="col-sm-0 col-md-1 col-xl-2"></div>
      </div>

      <div className="row">
        <div className="col-sm-0 col-md-0 col-lg-0 col-xl-1" />
        <div
          className={`col-sm-12 col-md-12 col-lg-4 col-xl-3 border ${style.calander}`}
        >

          <div style={{ marginBottom: "15px" }}>
            <Calendar
              className={style.headerCalender}
              fullscreen={false}
              // defaultValue={moment(CurrentAppointmentData?.selectedDate)}
              disabledDate={(current) => {
                return moment().add(-1, "days") >= current;
              }}
              onPanelChange={onPanelChange}
              onSelect={selectDateTime}
              headerRender={({ value, onChange }) => {
                const start = 0;
                const end = 12;
                const monthOptions = [];
                const current = value.clone();
                const localeData = value.localeData();
                const months = [];
                for (let i = 0; i < 12; i++) {
                  current.month(i);
                  months.push(localeData.monthsShort(current));
                }

                for (let index = start; index < end; index++) {
                  monthOptions.push(
                    <Select.Option
                      value=""
                      className="month-item"
                      key={`${index}`}
                    >
                      {months[index]}
                    </Select.Option>
                  );
                }
                const month = value.month();
                const year = value.year();
                const options = [];
                for (let i = year - 10; i < year + 10; i += 1) {
                  options.push(
                    <Select.Option key={i} value={i} className="year-item">
                      {i}
                    </Select.Option>
                  );
                }
                return (
                  <div className="row calenderHed align-items-center m-0 pb-1">
                    <div className="col-3 text-right">
                      <Button
                        shape="circle"
                        disabled={
                          moment(Today).format("YYYY MM") <=
                            moment().format("YYYY MM")
                            ? true
                            : false
                        }
                        icon={<ArrowLeftOutlined />}
                        type="primary"
                        style={{ backgroundColor: "#1a6ab1", border: "#1a6ab1" }}
                        onClick={() => {
                          const newValue = value.clone();
                          newValue.month(month - 1);
                          onChange(newValue);
                        }}
                      />
                    </div>
                    <div className="col-6 text-center">
                      <h5 className="mb-0" style={{ color: "#1a6ab1" }}>
                        {selctedMonthh}-{selectedYear}
                      </h5>
                    </div>
                    <div className="col-3 text-left">
                      <Button
                        shape="circle"
                        icon={<ArrowRightOutlined />}
                        type="primary"
                        style={{ backgroundColor: "#1a6ab1", border: "#1a6ab1" }}
                        onClick={() => {
                          const newValue = value.clone();
                          newValue.month(month + 1);
                          onChange(newValue);
                        }}
                      />
                    </div>
                  </div>
                );
              }}
            />
          </div>



          <h4 className="card-title">Select Service *</h4>

          <Select
            showSearch
            placeholder="Select Service"
            optionFilterProp="children"
            onChange={handleChangeService}
            value={selectedServiceValue}
            style={{
              fontSize : 15,
              width: 300,
              borderWidth: 1,
              borderColor: "rgba(0,0,0,0.)",
              borderRadius: 5,
              marginRight: 15,
              marginBottom: 25,
              whiteSpace:"pre-wrap"
            }}
          >
            {services?.map((itemm, index) => (
              <>
                {currentAppointmentInfo.paymentProvider !== "PRIVATE_PAY" ? 
                  <>
                  {itemm.serviceName != null && itemm.servicecost === '0.00' ? (
                  // {itemm.serviceName != null  ? (
                    <Option key={index} value={itemm.id}>
                      <span style={{whiteSpace:"pre-wrap"}}>
                        {itemm.serviceName} -{itemm.serviceduration} min - OHIP
                      </span>
                    </Option>
                  ) : null}
                  </> : 
                  <>
                  
                  {itemm.serviceName != null && itemm.servicecost !== '0.00' ? (
                  // {itemm.serviceName != null  ? (
                    <Option key={index} value={itemm.id}>
                      <span style={{whiteSpace:"pre-wrap"}}>
                        {itemm.serviceName} -{itemm.serviceduration} min - PRIVATE PAY
                      </span>
                    </Option>
                  ) : null}
                  </>
                }
              </>
            ))}
          </Select>
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-8 col-xl-7"
          style={{ paddingTop: 50 }}
        >
          <div className={style.div_container}>
            <h4 style={{ marginTop: 20, marginLeft: 40 }}>Doctor Details</h4>
            <div className={style.div_doctor_details}>
              <div className={style.doc_img}>
                <div className={style.img}>
                  {DoctorDetail?.profilepicture != "" &&
                    DoctorDetail?.profilepicture != null ? (
                    <img
                      // src={DoctorDetail?.profilepicture}
                      src={getUserImage(DoctorDetail?.profilepicture)}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src =
                          {IMG01};
                      }}
                      alt=""
                      style={{
                        height: 70,
                        width: 70,
                        border: 1,
                        borderRadius: 30,
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      src={IMG01}
                      alt=""
                      style={{
                        height: 70,
                        width: 70,
                        border: 1,
                        borderRadius: 30,
                        objectFit: "cover",
                      }}
                    />
                  )}
                  {/* <img src="https://shreethemes.in/doctris/layouts/assets/images/doctors/03.jpg" style={{ height: 70, width: 70, border: 1, borderRadius: 30 }} alt="" /> */}
                </div>
                <div className={style.doc_name}>
                  <h4 style={{ color: "black", fontSize: 20 }}>
                    Dr. {DoctorDetail?.FirstName} {DoctorDetail?.LastName}
                  </h4>
                  <p className={style.specialist}>{DoctorDetail?.Speciality}</p>
                </div>
              </div>
              <div className={style.div_date}>
                <p className={style.date}>{Today}</p>
                <div className={style.div_service}>
                  {/* <Select showSearch placeholder="Select Service" optionFilterProp="children"
                    onChange={handleChange}
                    style={{
                      width: 300,
                      borderWidth: 1,
                      borderColor: "rgba(0,0,0,0.)",
                      borderRadius: 5,
                      marginLeft: 20
                    }}
                  >
                    {services?.map((itemm, index) => (
                      <>
                        {itemm.serviceName != null ?
                          <Option key={index} value={itemm.id}>{itemm.serviceName}</Option> :
                          null}
                      </>
                    ))}

                  </Select> */}
                </div>
              </div>
            </div>

            {whichVersion !== "without_problem" ? (
              <>
                <h4
                  style={{ marginTop: 20, marginLeft: 40, paddingBottom: 10 }}
                >
                  Services
                </h4>
                <div
                  className="row"
                  style={{
                    paddingLeft: 45,
                    paddingRight: 35,
                    marginBottom: 15,
                    cursor: "pointer",
                  }}
                >
                  {loaderServices ? (
                    <div className="spinner">
                      <Spin />
                    </div>
                  ) : (
                    <>
                      {services?.map((itemm, index) => (
                        <>
                          {itemm.serviceName != null ? (
                            <div
                              className="col-sm-4 col-md-4 col-lg-4 col-xl-3 col-xxl-3 p-2"
                              onClick={() =>
                                doctorServiceSelect(itemm.id as any)
                              }
                            >
                              <div
                                className={`${selectedCard === itemm.id
                                  ? "card selectCardServices mb-0"
                                  : "card mb-0"
                                  }`}
                              >
                                <div className="card-body p-2">
                                  <div className="d-flex justify-content-between">
                                    <h5
                                      className={`card-title ${style.customCard}`}
                                    >
                                      {itemm.serviceName}
                                    </h5>
                                    <h6 className="card-subtitle mt-1 text-muted">
                                      ${itemm.servicecost}
                                    </h6>
                                  </div>
                                  <p className="card-text">
                                    {itemm.servicedesc}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ))}
                    </>
                  )}

                  {/* <div className="col-3">
                <div className="card" style={{ marginBottom: 15 }}>
                  <div className="card-body p-2">
                    <div className="d-flex justify-content-between">
                      <h5 className={`card-title ${style.customCard}`}>Consultation</h5>
                      <h6 className="card-subtitle mt-1 text-muted">$55.26</h6>
                    </div>
                    <p className="card-text">Some quick example</p>
                  </div>
                </div>
              </div> */}
                </div>
              </>
            ) : null}

            <div className="row px-5">
              <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <Tag
                  color="#52c41a"
                  key="booked"
                  style={{ width: 10, height: 15, textAlign: "center" }}
                />

                <Text>
                  <strong>BOOKED</strong>
                </Text>
              </div>

              <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <Tag
                  color="#d3d3d3"
                  key="draft"
                  style={{ width: 10, height: 15, textAlign: "center" }}
                />

                <Text>
                  <strong>DRAFT</strong>
                </Text>
              </div>

              <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <Tag
                  color="#1890ff"
                  key="selected"
                  style={{ width: 10, height: 15, textAlign: "center" }}
                />

                <Text>
                  <strong>SELECTED</strong>
                </Text>
              </div>
            </div>

            {selectedServiceValue == null ? (
              <div style={{marginTop : 45,marginLeft : 45}}>Service not selected, please select a service!</div>
            ) : (
              <>
              <h4 style={{ marginTop: 20, marginLeft: 40 }}>Slots</h4>
            <div className={style.schedule_btn}>
              <div className={style.btn}>
                {slotsDisplayLoader ? (
                  <>
                    <div className="spinner">
                      <Spin />
                    </div>
                  </>
                ) : null}
                {!slotsDisplayLoader && !slotBook.length ? (
                  <>Sorry, currently there are no slots available!</>
                ) : (
                  <></>
                )}
                {loaderSlot ? (
                  <div className="spinner"></div>
                ) : (
                  <>
                    <>
                      {slotBook?.map((item) => {
                        return (
                          <>
                            {item.availability == "true" ? (
                              <button
                                onClick={() => timeClick(item)}
                                className={
                                  selectedTime == item.time
                                    ? style.active_btn
                                    : style.timing_btn
                                }
                                style={{
                                  width: 110,
                                  height: 35,
                                  backgroundColor: setSlotColor(item.status),
                                  borderRadius: 5,
                                }}
                              >
                                {moment(item.time, "hh:mm a").format("hh:mma")}
                              </button>
                            ) : (
                              <span>
                                {/* <Popover
                                content={content}
                                placement="top"
                                title={`${bookingPopover?.providername} (${moment(
                                  bookingPopover?.startdatetime
                                ).format("YYYY-MM-DD")})`}
                              > */}
                                {/* <span> */}
                                <button
                                  disabled
                                  className={style.timing_btn_disable}
                                  style={{
                                    width: 110,
                                    height: 35,
                                    backgroundColor: setSlotColor(item.status),
                                    color: "white",
                                    borderRadius: 5,
                                  }}
                                >
                                  {moment(item.time, "hh:mm a").format(
                                    "hh:mma"
                                  )}
                                </button>
                                {/* </span> */}
                                {/* </Popover> */}
                              </span>
                            )}
                          </>
                        );
                      })}
                    </>
                  </>
                )}
              </div>
            </div>
              </>
            )}

          </div>
        </div>
        <div className="col-sm-0 col-md-0 col-lg-0 col-xl-1" />
      </div>

      <div className="footer">
        <Footer
          disbleFooter={isSlotSelected && chooseServices}
          location="checkOut"
          pageName="bookAppointment"
          book={selectedTime}
          services={selectedData}
        />
      </div>
    </div>
  );
};

export default BookAppointment;
