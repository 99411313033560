import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faCoffee,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import style from "./style.module.css";
import { Button } from "antd/lib/radio";
import { notification } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { selectAppointmentType, selectedService, selectPaymentProvider } from "redux/actions/userActions";
import {
  setStartTimeBasedOnCallBackRequest,
  setStartTimeBasedOnCallBackRequestWithDate,
} from "../../utils/common";
import { GET, POST } from "../../services/common.api";
import moment from "moment";

const Footer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appointmentInfo = useSelector((state: any) => state.curentAppointment);
  const userData = useSelector((state: any) => state.userReducer.user);
  const paymentProvider = useSelector(
    (state: any) => state.curentAppointment.paymentProvider
  );
  const familyMemberState: any = useSelector(
    (state: any) => state?.curentAppointment?.familyMember
  );
  const currentAppointmentState: any = useSelector(
    (state: any) => state?.curentAppointment?.appointmentRequestType
  );
  const appointmentTypeNew = useSelector((state: any) => state);
  const [hideAlert, setHideAlert] = useState(false);

  const continuee = () => {
    if (props.pageName == "selectType") {
      history.push(`/${props.location}`);
    } else if (props.pageName == "select-callback") {
      if (props.value != "") {
        history.push(`/${props.location}`);
      } else {
        notification.error({
          message: "Error",
          duration: 2,
          description: "Please Select Problem.",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      }
    } else if (props.pageName == "problems") {
      if (props.value != "") {
        // localStorage.setItem("problem_id", props.value);

        dispatch(selectAppointmentType("fastService"));
        history.push(`/${props.location}`);
      } else {
        notification.error({
          message: "Error",
          duration: 2,
          description: "Please Select Problem.",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      }
    } else if (props.pageName == "appoiment-type") {
      history.push(`/${props.location}`);
    } else if (props.pageName == "bookAppointment") {
      console.log(props.services, "SER");
      if (
        props.book != "" &&
        props.services.serviceId != "" &&
        appointmentInfo.selectedService.selectedTime
      ) {
        history.push(`/${props.location}`);
      } else {
        notification.error({
          message: "Error",
          duration: 2,
          description: "Select Appoiment Slot and services.",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      }
    } else if (props.pageName == "Submit") {
      if (props.valueReason != "") {
        requestCallBackAndSaveAsAppointment(props.valueReason);
        // notification.success({
        //   message: "Callback Requested Succesfully",
        //   duration: 2,
        //   // description: "Callback Requested Succesfully.",
        // });
        // console.log(props.valueReason, "footer")
      } else {
        notification.error({
          message: "Error",
          duration: 2,
          description: "Please Enter Reason.",
        });
      }
    }else if (props.pageName === "selectPrivateService") {

      const selectedData = {
        serviceId : props?.selectedServiceData?.id,
        reason: props?.selectedServiceData?.description,
        selectedDate : moment(props.appoimentDate, "dddd, MMM DD, YYYY").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        serviceCost: props?.selectedServiceData?.fees,
        serviceduration: 10, 
        selectedTime: props.slotsTime,
        selectslot: props.selectslot,
        selectedServiceName : props?.selectedServiceData?.service,
      }
      dispatch(selectedService(selectedData));
      history.push(`/${props.location}`);

    }
  };
  const requestCallBackAndSaveAsAppointment = (reason) => {
    let UserId;
    if (currentAppointmentState == 'Own') {
      UserId = userData.id;
    } else {
      UserId = familyMemberState?.familymemberid;
    }

    const requestedDataforCallBack = {
      // patientid: userData.id,
      // providerid: 356,
      // startdatetime: setStartTimeBasedOnCallBackRequest(appointmentTypeNew.curentAppointment.callback, "start"),
      // enddatetime: setStartTimeBasedOnCallBackRequest(appointmentTypeNew.curentAppointment.callback, "end"),
      // detail: reason,
      // service: 106,
      // status: "PENDING",
      // bookedby: userData.id,
      // appointmenttype: "CallBack",
      // isfamilymember: 0,
      // createdate: moment().format("YYYY-MM-DD HH:mm:ss"),
      // createdby: userData.id,
      // source: "",
      patientid: UserId,
      callbackstatus: "NEW",
      paymenttype: paymentProvider,

      service : paymentProvider == 'PRIVATE_PAY' ? props?.selectedServiceData?.id : null,
      
      // callbacktime: setStartTimeBasedOnCallBackRequestWithDate(appointmentTypeNew.curentAppointment.callback, "start", props.appoimentDate),
      callbacktime: props.slotsTime,
      doctorid: 356,
      created_at: moment(props.appoimentDate, "dddd, MMM DD, YYYY").format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      updated_at: moment(props.appoimentDate, "dddd, MMM DD, YYYY").format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      updated_by: userData.id,
      created_by: userData.id,
      callbackreason: reason,
      // callbackreason: "testing1",
      callbackdate: moment(props.appoimentDate, "dddd, MMM DD, YYYY").format(
        "YYYY-MM-DD"
      ),
      comments: "NA",
      paymentid: appointmentInfo?.Insurance?.id,
      callbackstart: moment(setStartTimeBasedOnCallBackRequestWithDate(
        props.selectslot,
        "start",
        props.appoimentDate
      ), "YYYY-MM-DD HH:mm A").local().format("YYYY-MM-DD HH:mm A"),
      callbackend: moment(setStartTimeBasedOnCallBackRequestWithDate(
        props.selectslot,
        "end",
        props.appoimentDate
      ), "YYYY-MM-DD HH:mm A").local().format("YYYY-MM-DD HH:mm A"),
    };
    
    console.log(requestedDataforCallBack, "param");
    console.log(props.slotsTime, "slotsTime");
    console.log("requestedDataforCallBack: ", requestedDataforCallBack);

    POST("callbackrequests", requestedDataforCallBack).then(() => {
      notification.success({
        message: "Success",
        duration: 2,
        description: "Callback request created Successfully",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
      dispatch(selectAppointmentType({}))
      dispatch(selectPaymentProvider({}))
      history.push("/dashboard");
    });
  };

  const goBack = () => {
    history.goBack();
  };
  const cancelBack = () => {
    setHideAlert(true);
  };
  const handleBack = () => {
    setHideAlert(false);
    history.push("/dashboard");
  };
  const onCancel = () => {
    setHideAlert(false);
  };
  return (
    <div
      className="text-center py-4 new"
      style={{ backgroundColor: "#ffffff ", zIndex: 200 }}
    >
      <div className="row pl-2">
        <div className="col-5">
          <div className={style.div_btn}>
            <Button
              type="primary"
              className="btnBgred"
              onClick={() => cancelBack()}
            >
              Cancel
            </Button>
          </div>
        </div>
        <div className="col-7">
          <div className="row">
            <div className={`${style.div_btn} col-sm-12`}>
              <Button type="primary" className="btnBg1" onClick={goBack}>
                Previous
              </Button>
              {props.hide !== "false" ? (
                <Button
                  type="primary"
                  className={
                    props.disbleFooter || props.disbleFooter == undefined
                      ? "btnBg2 ml-2"
                      : "btnBg2_disabled ml-2"
                  }
                  onClick={() => continuee()}
                >
                  Next
                </Button>
              ) : // <Button
                //   type="primary"
                //   className={"btnBg2"}
                //   onClick={() => continuee()}
                // >
                //   Next
                // </Button>
                null}
            </div>
          </div>
        </div>
      </div>

      {hideAlert && (
        <SweetAlert
          warning
          title="Cancel the appointment or booking?"
          onConfirm={handleBack}
          onCancel={onCancel}
          showCancel={true}
          // focusCancelBtn={true}
          // confirmBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      )}
    </div>
  );
};

export default Footer;
