import { TopBar } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEquals,
  faMapMarked,
  faMapMarkedAlt,
  faMapPin,
  faMapSigns,
  faMarker,
} from "@fortawesome/free-solid-svg-icons";
import DashboardSidebar from "../sidebar/sidebar";
import style from "./style.module.scss";
import { useEffect, useState } from "react";
import IMG01 from "../../../images/user.png";
// import SearchLocationInput from "../searchAddress/index";
import SearchLocationInput from "../../../components/searchAddress/index";
import { PUT, GET, POST } from "../../../services/common.api";
import Footer from "components/footerFix";
import CountrysJson from "../../../jsonFiles/country.json";
import { find } from "lodash";

import {
  Badge,
  Button,
  Cascader,
  Drawer,
  Form,
  Input,
  notification,
  Popconfirm,
  Rate,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";
import { PanoramaFishEyeOutlined } from "@material-ui/icons";

import DatePicker from "react-datepicker";
import {
  AppointmentRequestType,
  setSelectedFamilyMember,
} from "redux/actions/userActions";
import MaskInput from "react-maskinput/lib";

const ViewProfile = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { Option } = Select;
  let config = useSelector((state: any) => state.AppConfig.config);
  const [selectCountry, setSelectCountry] = useState({} as any);
  const [countryJson, setCountryJson] = useState({} as any);
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [familyDoc, setFamilyDoc] = useState({} as any);
  const [address, setAddress] = useState("");
  const [, setLat] = useState(null);
  const [, setLng] = useState(null);
  const [insuranceData, setInsuranceData] = useState([] as any);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [loader, setLoader] = useState(false);

  const userData = useSelector((state: any) => state.userReducer.user);
  // let config = useSelector((state: any) => state.AppConfig.config);

  const getSelectdFamilyDoctor = async () => {
    try {
      const familyDoctor = await GET(`patient/familydoc/${userData.id}`);
      console.log(familyDoctor.data.body, "Family Doctor data");
      setFamilyDoc(familyDoctor?.data?.body?.CompanyEmployee?.CompanyEmployee);
      console.log(
        familyDoctor?.data?.body?.CompanyEmployee?.CompanyEmployee.id,
        "iddididididiididididid"
      );
      // setSelectFamilyMemberID(familyDoctor?.data?.body?.CompanyEmployee?.CompanyEmployee.id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));

    window.scrollTo(0, 0);
    getSelectdFamilyDoctor();
    console.log(userData, "profile view");
    console.log(familyDoc, "family Doctor********");
    getInsuranceInfo();

    setCountryJson(CountrysJson);
    let G = config?.country;
    var obj = CountrysJson?.countries?.filter((obj) => obj.country === G);
    console.log(obj, "call");
    setSelectCountry(obj[0]);

  }, []);

  const goEditProfile = () => {
    history.push("profile");
  };

  function onChangeCountry(value) {
    console.log(`selected ${value}`);
    console.log(CountrysJson);

    var obj = countryJson?.countries?.filter((obj) => obj.country == value);
    console.log(obj[0]);
    setSelectCountry(obj[0]);
  }
  
  const [sidebar, setSidebar] = useState(false);
  const [selectedDate, SetSelectedDate] = useState(new Date());
  const showSidebar = () => setSidebar(!sidebar);

  // const claimColumns = [
  //     // {
  //     //   title: 'Claim Id',
  //     //   dataIndex: 'claimid',
  //     //   key: 'no',
  //     //   fixed: 'center',
  //     //   width: '50'
  //     // },
  //     {
  //         title: 'Claim date',
  //         dataIndex: 'claimDate',
  //         key: 'no',
  //         fixed: 'center',
  //     },
  //     {
  //         title: 'Consultig Doctor',
  //         dataIndex: 'consultingdoctor',
  //         key: 'no',
  //         fixed: 'center',
  //     },
  //     {
  //         title: 'Cost Amount',
  //         dataIndex: 'costamount',
  //         key: 'no',
  //         fixed: 'center',
  //     },
  //     {
  //         title: 'Paid  Amount',
  //         dataIndex: 'paidamount',
  //         key: 'no',
  //         fixed: 'center',
  //     },
  //     {
  //         title: 'Payment Method',
  //         dataIndex: 'paymentStatus',
  //         key: 'no',
  //         fixed: 'center',
  //     },
  //     {
  //         title: 'Action',
  //         key: 'operation',
  //         // fixed: 'left',
  //         fixed: 'right',
  //         render: () => (
  //             <div>
  //                 <PanoramaFishEyeOutlined className="ml-3 mb-3 font-size-24" />
  //             </div>
  //         ),
  //     },
  // ]

  // const options = [
  //     {
  //         value: 'all',
  //         label: 'all',
  //     },
  // ]

  function onChange(value) {
    console.log(value);
  }

  const dataSource = [
    {
      key: "1",
      Claimdate: "03-jan-2022",
      ConsultigDoctor: "John Dow",
      CostAmount: "1500",
      PaidAmount: "2500",
      PaymentMethod: "Pay",
    },
    {
      key: "1",
      Claimdate: "03-jan-2022",
      ConsultigDoctor: "John Dow",
      CostAmount: "1500",
      PaidAmount: "2500",
      PaymentMethod: "Pay",
    },
    {
      key: "1",
      Claimdate: "03-jan-2022",
      ConsultigDoctor: "John Dow",
      CostAmount: "1500",
      PaidAmount: "2500",
      PaymentMethod: "Pay",
    },
    {
      key: "1",
      Claimdate: "03-jan-2022",
      ConsultigDoctor: "John Dow",
      CostAmount: "1500",
      PaidAmount: "2500",
      PaymentMethod: "Pay",
    },
    {
      key: "1",
      Claimdate: "03-jan-2022",
      ConsultigDoctor: "John Dow",
      CostAmount: "1500",
      PaidAmount: "2500",
      PaymentMethod: "Pay",
    },
    {
      key: "1",
      Claimdate: "03-jan-2022",
      ConsultigDoctor: "John Dow",
      CostAmount: "1500",
      PaidAmount: "2500",
      PaymentMethod: "Pay",
    },
  ];

  const columns = [
    {
      title: "Claim date",
      dataIndex: "Claimdate",
      key: "Claimdate",
    },
    {
      title: "Consultig Doctor",
      dataIndex: "ConsultigDoctor",
      key: "ConsultigDoctor",
    },
    {
      title: "Cost Amount",
      dataIndex: "CostAmount",
      key: "CostAmount",
    },
    {
      title: "Paid Amount",
      dataIndex: "PaidAmount",
      key: "PaidAmount",
    },
    {
      title: "Payment Method",
      dataIndex: "PaymentMethod",
      key: "PaymentMethod",
    },
  ];

  const InsuranceColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text?.charAt(0).toUpperCase() + text?.slice(1)}</a>,
    },
    {
      title: "Type",
      dataIndex: "insurance_type",
      key: "insurance_type",
    },
    {
      title: "Insurance Number",
      dataIndex: "insurance_number",
      key: "insurance_number",
    },
    // {
    //     title: 'Version code',
    //     dataIndex: 'address',
    //     key: 'address',
    // },

    // {
    //     title: 'Action',
    //     key: 'action',
    //     render: (text, record) => (
    //         <Space size="middle">
    //             <Tooltip title="Delete" placement="bottom">
    //                 <Popconfirm
    //                     title="Are you sure?"
    //                     onConfirm={confirm}
    //                     onCancel={cancel}
    //                     okText="Yes"
    //                     cancelText="No"
    //                 >
    //                     <Button shape="circle" danger icon={<DeleteFilled />} />
    //                 </Popconfirm>
    //             </Tooltip>
    //         </Space>
    //     ),
    // },
  ];

  const data = [
    {
      key: "1",
      name: "1",
      age: 321234678,
      address: "123456789",
    },
  ];

  const getInsuranceInfo = async () => {
    try {
      const res = await GET(`user/insurance/${userData.id}`);
      console.log(res?.data, "getInsuranceInfo");
      setInsuranceData(res?.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  const onFinish = async (values) => {
    console.log(values)

    let data = {
      accreditationNumber: values?.accreditationNumber,
      address1: address,
      address2: null,
      address: null,
      city: values?.City,
      faxNumber: values?.faxNumber.replaceAll('-',''),
      pharmacyName: values?.PharmacyName,
      phoneNumber: values?.phoneNumber.replaceAll('-',''),
      postalCode: values?.PostalCode,
      createDate: new Date(),
      createUserId: userData.id,
      accreditation_number: null
    }

    console.log(data);
    try {
      setLoader(true);
      POST("/pharmacy", data).then((response) => {
        setLoader(false);
        notification.success({
          message: 'Pharmacy has been saved successfully.',
        })
        form.resetFields();
        setAddress("")
      });
    } catch (error) {
      setLoader(false);
      notification.error({
        message: 'Error while save Pharmacy.',
      })
    }
  }

  const handleChange = (event) => {
    console.log(event,"event")
  }

  const addressSelect = (datee) => {
    console.log(datee,"eeeeeeee")
    setAddress(datee.formatted_address);

    if (datee && datee.address_components) {
      const city = find(datee.address_components, {
        types: ["locality"],
      });

      console.log(city.long_name, "city");
      if (city) {
        form.setFieldsValue({
          City: city?.long_name,
        });
      }

      const postal_code = find(datee.address_components, {
        types: ["postal_code"],
      });
      console.log(postal_code?.long_name, "postal_code");

      if (postal_code) {
        form.setFieldsValue({
          PostalCode: postal_code.long_name,
        });
      }
    }

    //  if (datee && datee.address_components) {
    //   const state = find(datee.address_components, {
    //     types: ['administrative_area_level_1'],
    //   })

    //   console.log(state?.long_name,"state");
    //   if (state) {
    //     form.setFieldsValue({
    //       province: state.long_name
    //     });
    //   }
    // }

  }
  const ClickToDayDate = () => {
    // let date = moment().format('YYYY-MM-DD')
    // SetSelectedDate(date);

    let date = new Date();

    SetSelectedDate(date);
  };

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const handleChangePhonenumber = (event) => {
    console.log(event.target.name, "jsdfhjsfhhdsfhfgjh ");
    console.log(event.target.value, "jsdfhjsfhhdsfhfgjh ");
    setPhoneNumber(event.target.value);
  };

  const handleChangeFaxNumber = (event) => {
    console.log(event.target.name, "jsdfhjsfhhdsfhfgjh ");
    console.log(event.target.value, "jsdfhjsfhhdsfhfgjh ");
    setFaxNumber(event.target.value);
  };

  return (
    <div className="pb-5">
      <TopBar />
      <div className="box">
        <div className={`${style.toggebtn} d-block d-xl-none`}>
          <i>
            <FontAwesomeIcon
              className="mr-1"
              icon={faEquals}
              onClick={showDrawer}
            />
          </i>
        </div>
        <div className="d-block d-xl-none">
          <p className="pageInfoM">Profile</p>
          <h5 className="h5NewM">Dashboard / Profile</h5>
        </div>
        <div className="d-none d-xl-block">
          <p className="pageInfo">Profile</p>
          <h5 className="h5New">
            <Link style={{ color: "white" }} to="/dashboard">
              Dashboard
            </Link>{" "}
            / Profile
          </h5>
        </div>
      </div>
      <div>
        <div
          className="content"
          style={{ backgroundColor: "#f1f5f9", paddingTop: 15 }}
        >
          <div className={style.container}>
            <div className="row pb-5 ">
              <div
                className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar pr-0`}
              >
                <DashboardSidebar />
              </div>
              <Drawer
                closable={false}
                width={300}
                placement="left"
                onClose={onClose}
                visible={visible}
                style={{ paddingTop: 0 }}
              >
                <div className="widget-profile pro-widget-content pt-0 pb-4">
                  <div className="profile-info-widget">
                    <img src="../Images/logo.png" height="50" alt="" />
                  </div>
                </div>
                <DashboardSidebar />
              </Drawer>

              {/* {sidebar ?
                                <div className={`d-block d-xl-none ${style.toggle}`}>
                                    <DashboardSidebar />
                                </div> : null
                            } */}
              <div className="col-lg-12 col-xl-10">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                    <Badge.Ribbon text={`${userData?.role}`}>
                      <div className="card card-top card-top-primary pb-1 mb-2">
                        <div className="card-body p-2">
                          <div className="media align-items-center">
                            <div
                              style={{
                                position: "relative",
                                backgroundColor: "white",
                              }}
                            >
                              {/* <img className="mr-3 profpic" src="https://doccure-html.dreamguystech.com/template/assets/img/doctors/doctor-thumb-02.jpg" alt="Mary Stanform" /> */}
                              {userData?.profilepicture != "" &&
                              userData?.profilepicture != null ? (
                                <img
                                  className="mr-3 profpic"
                                  src={`${process.env.REACT_APP_ASSET_URL}/${userData.profilepicture}`}
                                  alt="User"
                                />
                              ) : (
                                <img
                                  className="mr-3 profpic"
                                  src={IMG01}
                                  alt="User"
                                />
                              )}
                              {/* <img className="editIcon" style={{ cursor: 'pointer' }} src="Images/edit.png" alt="" onClick={() => goEditProfile()} /> */}
                            </div>
                            <div className="media-body">
                              <div className="pl-2">
                                <div className="">
                                  <span
                                    className="mr-auto fontColor"
                                    style={{
                                      margin: 0,
                                      fontSize: 15,
                                      color: "#134f85",
                                    }}
                                  >
                                    {userData?.FirstName?.charAt(0).toUpperCase() + userData?.FirstName?.slice(1)} {userData?.MiddleName !== null ? userData?.MiddleName?.charAt(0).toUpperCase() + userData?.MiddleName?.slice(1) : ''}{" "}
                                    {userData?.LastName?.charAt(0).toUpperCase() + userData?.LastName?.slice(1)}
                                  </span>
                                </div>
                                <div className="font-size-12 mb-1">
                                  {userData?.Email}
                                </div>
                              </div>

                              {/* <span>{userData.Email}</span> */}

                              <span
                                onClick={() => goEditProfile()}
                                className={`btn btn-primary ml-2 ${style.btnWithAddon}`}
                              >
                                {/* <img className="editIcon" style={{ cursor: 'pointer' }} src="Images/edit.png" alt="" /> */}
                                Edit Profile
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Badge.Ribbon>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                    {/* <div className="card pb-1 mb-3">
                                            <div className="card-body text-white rounded" style={{ backgroundColor: '#ffff' }}>
                                                <div>
                                                    <div className="d-flex mb-1">
                                                        <div className="text-uppercase mr-auto fontColor">Balance</div>
                                                        <div className="fontColor">TOTAL</div>
                                                    </div>
                                                    <div className="d-flex mb-2">
                                                        <div className="font-size-24 font-weight-bold mr-auto fontColor">{config?.currency_symbol} 3,000</div>
                                                        <div className="font-size-24 fontColor"> {config?.currency_symbol} 5,000</div>
                                                    </div>
                                                    <div className="progress">
                                                        <div
                                                            className="progress-bar fontColor"
                                                            style={{
                                                                width: '60%',
                                                                backgroundColor: '#194f85'
                                                            }}
                                                            role="progressbar"
                                                            aria-valuenow={60}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                  <div className="col-sm-12 col-md-0 col-lg-0 col-xl-2" />
                </div>
                <div className="card pb-1 mb-3" style={{ paddingBottom: 10 }}>
                  <div className="card-body m-2">
                    <div className="row">
                      {/* <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <span>Name</span>
                                                    <div>
                                                        <strong>{userData?.FirstName} {userData?.MiddleName} {userData?.LastName}</strong>
                                                    </div>
                                                </div>
                                            </div> */}
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <span>Address</span>
                          <div>
                            <strong>
                              {userData?.Address1 ? userData?.Address1 : ""}{" "}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <span>Email</span>
                          <div>
                            <strong> {userData?.Email}</strong>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <span>Phone</span>
                          <div>
                            <strong>{userData?.phoneNumber}</strong>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <span>Speciality</span>
                                                    <div>
                                                        <strong>{userData?.Speciality}</strong>
                                                    </div>
                                                </div>
                                            </div> */}
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <span>Languages</span>
                          <div>
                            <strong className="text-capitalize">
                              {userData?.Languages ? userData.Languages : ""}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <span>City</span>
                          <div>
                            <strong>{userData?.City}</strong>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <span>State</span>
                          <div>
                            <strong>{userData?.State}</strong>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <span>Postal Code</span>
                          <div>
                            <strong>{userData?.PostalCode}</strong>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <span>Country</span>
                          <div>
                            <strong>{userData?.Country}</strong>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <span>Department</span>
                                                    <div>
                                                        <strong>{userData?.Department}</strong>
                                                    </div>
                                                </div>
                                            </div> */}
                      {/* <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <span>Fax</span>
                                                    <div>
                                                        <strong>1234567890</strong>
                                                    </div>
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 p-0 m-0">
                  <div
                    className={`${style.Cardheader} card card-top card-top-primary pb-1 mb-3`}
                  >
                    <div className="p-4">
                      <Tabs
                        defaultActiveKey="1"
                        className={` ${style.tabsW} vb-tabs-bold`}
                      >
                        {/* <Tabs.TabPane tab="Claim History" key="1">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-xl-9 col-lg-8 col-md-8">
                                                                <div className="row">
                                                                    <div
                                                                        className="col-xl-4 col-lg-4 col-md-4 col-sm-12 pr-0 pb-4"
                                                                        style={{
                                                                            width: '100%',
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'flex-start',
                                                                        }}
                                                                    >
                                                                        <Text className="pr-3">Filters: </Text>

                                                                        <DatePicker dateFormat="MM-dd-yyyy" selected={selectedDate} onChange={(date) => SetSelectedDate(date)} /> */}

                        {/* {
                                                                            selectedDate == "" ?
                                                                                <DatePicker onChange={onChangeDate} style={{ width: '100%' }} /> :
                                                                                <DatePicker onChange={onChangeDate} value={moment(selectedDate, "YYYY-MM-DD")} style={{ width: '100%' }} />

                                                                        } */}

                        {/* <DatePicker onChange={onChangeDate} defaultValue={moment(selectedDate, "YYYY-MM-DD")} style={{ width: '100%' }} /> */}
                        {/* </div>
                                                                    <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12 pr-0 pb-4">
                                                                        <Button onClick={() => ClickToDayDate()}
                                                                            style={{ width: '100%', height: '100%' }}
                                                                        >
                                                                            <Text>Today</Text>
                                                                        </Button>
                                                                    </div> */}
                        {/* <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 pr-0 pb-4">
                                                                        <Cascader
                                                                            options={options}
                                                                            onChange={onChange}
                                                                            placeholder="All"
                                                                            style={{ width: '100%' }}
                                                                        />
                                                                    </div> */}
                        {/* <div className="col-xl-2 col-lg-0 col-md-0" />
                                                                </div>
                                                            </div>
                                                        </div> */}
                        {/* <Table

                                                            className="text-center"
                                                            columns={columns}
                                                            dataSource={dataSource}
                                                            pagination={{ pageSize: 3 }}
                                                        /> */}
                        {/* </div>
                                                </Tabs.TabPane> */}
                        <Tabs.TabPane tab="Insurance Provider" key="1">
                          <Table
                            columns={InsuranceColumns}
                            dataSource={insuranceData}
                            pagination={{ pageSize: 4 }}
                            rowKey={(obj) => obj.id}
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Pharmacy" key="2">
                          <Form
                            layout="vertical"
                            name="basic"
                            form={form}
                            onFinish={onFinish}
                          >
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                        <Form.Item
                                        name="PharmacyName"
                                        label="Pharmacy Name"
                                        rules={[
                                            {
                                            required: true,
                                            message: "Please Enter Pharmacy Name",
                                            },
                                        ]}
                                        >
                                        <input
                                            className={`${style.inputbox}`}
                                            placeholder="Pharmacy Name"
                                            name="PharmacyName"
                                            onChange={handleChange}
                                        />
                                        </Form.Item>
                                    </div>
                                  </div>
                                <div className="col-6">
                                    <div className="form-group">      
                                        <label> Address</label>
                                        <Form.Item>
                                        <SearchLocationInput
                                            styleProps={{ minHeight: 30 }}
                                            name="address"
                                            address={address}
                                            setlat={(e) => setLat(e)}
                                            setlng={(e) => setLng(e)}
                                            setAddress={(e) => addressSelect(e)}
                                            onBlur={(e) => setAddress(e)}
                                        />

                                        </Form.Item>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <Form.Item
                                            name="PostalCode"
                                            label="Zip Code"
                                            rules={[
                                                {
                                                required: true,
                                                message: "PostalCode is not valid!",
                                                // pattern: new RegExp(validPostCode),
                                                },
                                            ]}
                                            >
                                            <input
                                                type="text"
                                                className={`${style.inputbox}`}
                                                name="PostalCode"
                                                onChange={handleChange}
                                            />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className={`form-group `}>
                                            <Form.Item
                                            name="City"
                                            label="City"
                                            rules={[
                                                {
                                                required: true,
                                                message: "Please Enter your City!",
                                                },
                                            ]}
                                            >
                                            <input
                                                type="text"
                                                className={`${style.inputbox}`}
                                                onChange={handleChange}
                                            />
                                            {/* <input type="text" className={`${style.inputbox}`} name="City" onChange={handleChange} /> */}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className={`form-group `}>
                                        <Form.Item
                                            name="faxNumber"
                                            label="Fax Number"
                                            rules={[
                                              {
                                                required: false,
                                                message: "Please Enter Fax Number!",
                                              },
                                              {
                                                max: 12,
                                                message: "must be maximum 12 characters.",
                                              },
                                            ]}
                                          >
                                          <MaskInput
                                              alwaysShowMask
                                              maskChar="_"
                                              onChange={handleChangeFaxNumber}
                                              mask="000-000-0000"
                                          />
                                          </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className={`form-group `}>
                                        <Form.Item
                                            name="phoneNumber"
                                            label="Phone Number"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Please Enter phone number!",
                                              },
                                              {
                                                max: 12,
                                                message: "must be maximum 12 characters.",
                                              },
                                            ]}
                                          >
                                          <MaskInput
                                              alwaysShowMask
                                              maskChar="_"
                                              onChange={handleChangePhonenumber}
                                              mask="000-000-0000"
                                          />
                                          </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <div className={`form-group `}>
                                      <Form.Item
                                          name="accreditationNumber"
                                          label="Accredition Number"
                                          rules={[
                                            {
                                              required: false,
                                              message: "Please Enter Accredition number!",
                                            },
                                            {
                                              max: 10,
                                              message: "must be maximum 10 characters.",
                                            },
                                          ]}
                                        >
                                        <input
                                              type="text"
                                              className={`${style.inputbox}`}
                                              onChange={handleChange}
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                </div>

                                <div>
                                  {loader ? (
                                    <div className="spinner">
                                      {/* <Spin tip="Loading..."></Spin> */}
                                      <Spin tip="Validating Pharmacy) ..."></Spin>
                                    </div>
                                  ) : (
                                    <button type="submit" className="btn">
                                      Submit
                                    </button>
                                  )}
                                </div>
                            </div>
                        </div>

                        {/* <div
                            className="row ml-1 mr-1 border-top"
                            style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            }}
                        >
                            <div className="pt-4 pr-3">
                            <Form.Item>
                                <Button
                                style={{ width: 155, height: "auto" }}
                                htmlType="submit"
                                className="btn btn-primary px-5"
                                loading={btnLoader}
                                onClick={checkAddress}
                                >
                                Submit
                                </Button>
                            </Form.Item>
                            </div>
                            <div className="pt-4 pr-3">
                            <Form.Item>
                                <Button
                                style={{ width: 155, height: "auto" }}
                                htmlType="button"
                                onClick={close}
                                className="btn btn-light px-5"
                                >
                                Cancel
                                </Button>
                            </Form.Item>
                            </div>
                        </div> */}

                          </Form>
                        </Tabs.TabPane>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ViewProfile;
