/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Skeleton, Card, Avatar, Typography, Button } from 'antd'
import _ from 'lodash'
import style from "./style.module.css";

const { Meta } = Card
const { Title, Text } = Typography

const OtherProblemHealthHistoryCard = ({ loading, setOfQuestionsAnswers, title }) => {

  var checkboxes1 = [];
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    console.log(setOfQuestionsAnswers.otherProblemsHistory, "Other Problem History===");

    setOfQuestionsAnswers.otherProblemsHistory?.forEach((element, i) => {
      console.log(element);

      if (element.type == "checkboxes") {
        checkboxes1 = element.checkboxes?.filter(item => {
          return item.answer == "yes";
        })
        element.length = checkboxes1.length
      }

      if (setOfQuestionsAnswers.otherProblemsHistory?.length - 1 == i) {
        setLoader(false)
      }
    });
    if (_.isUndefined(setOfQuestionsAnswers?.otherProblemsHistory)) {
      setLoader(false);
    }
  }, []);

  return (
    <>
      <div>
        <div className={style.titleHeader}>Other Problem Health History</div>
          {!_.isUndefined(setOfQuestionsAnswers?.otherProblemsHistory) &&
            setOfQuestionsAnswers?.otherProblemsHistory?.map((questionAndAnswer, index) => {
              return (
        <div className={style.padd}>
                
                  {questionAndAnswer?.heading && questionAndAnswer?.length != 0 && <h4 className={style.subtitleHeader}>{questionAndAnswer?.heading}</h4>}

                  {questionAndAnswer.length != 0 && (
                    <div className={style.divider}>
                      <div className="row">
                        <div className="col-12">


                          <table className="table table-bordered">

                            <tbody>
                              {questionAndAnswer?.type === 'checkboxes' &&
                                questionAndAnswer?.checkboxes?.map(
                                  (option) =>
                                    option.answer !== '' &&
                                    option.answer !== 'no' && (
                                      <>
                                        <tr>
                                          <td>
                                            <Text>
                                              {option.label}
                                            </Text>
                                          </td>
                                          <td>
                                            <Text>
                                              {option.answer}
                                            </Text>
                                          </td>

                                        </tr>
                                      </>
                                    )
                                )}

                            </tbody>
                          </table>



                        </div>
                      </div>
                    </div>
                  )}

                </div>
              )
            })
          }
        </div>
      
    </>
  )
}

export default OtherProblemHealthHistoryCard
