import { TopBar, DoctorAppointmentBookNew, SearchFilter } from "components";
import {
  UserOutlined,
  SolutionOutlined,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Steps } from "antd";

import Footer from "components/footer";

import style from "./style.module.css";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom'

const SelectedType = () => {
  const { Step } = Steps;
  const appointmentTypeNew = useSelector((state: any) => state);
  const whichVersion = useSelector(
    (state: any) => state.curentAppointment.whichVersion
  );

  return (
    <div>
      <div className="box" style={{ marginTop: 0 }}>
        <p className="pageInfo">Search Doctor</p>
        <h5 className="h5New"><Link style={{ color: "white" }} to="/dashboard" >Dashboard</Link> / Search Doctor List</h5>
      </div>
      <div className="content" style={{ paddingTop: 0 }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-0 col-md-1 col-xl-2"></div>
            <div className="col-sm-12 col-md-10 col-xl-8">
              <div className="stepDiv d-none d-sm-block">
                {appointmentTypeNew.curentAppointment.appointmentType ==
                  "CallBackRequest" ? (
                  <Steps size="small">
                    {/* <Step status="finish" title="Payment" /> */}
                    {/* <Step status="finish" title="Problems" /> */}
                    <Step status="finish" title="Select Type" />
                    <Step status="finish" title="Select Slot" />
                    <Step status="finish" title="Select Doctor" />
                  </Steps>
                ) : (
                  <>
                    {" "}
                    {appointmentTypeNew.curentAppointment.appointmentType ==
                      "callback" ? (
                      <Steps size="small">
                        <Step status="finish" title="Payment" />
                        <Step status="finish" title="Select Type" />
                        <Step status="finish" title="Select Slot" />
                        <Step status="finish" title="Select Doctor" />
                        <Step status="wait" title="Book Appointment" />
                      </Steps>
                    ) : (
                      <Steps size="small">
                        <Step status="finish" title="Select Type" />
                        <Step status="finish" title="Select Doctor" />
                        {/* <Step status="wait" title="Payment " /> */}
                        {
                          whichVersion == "with_problem" ?
                            <Step status="wait" title="Problems" /> : null
                        }
                        <Step status="wait" title="Select Slot" />
                        <Step status="wait" title="Book Appointment" />
                      </Steps>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-sm-0 col-md-1 col-xl-2"></div>
          </div>
          <div className="row mt-3">
            <DoctorAppointmentBookNew />
          </div>
        </div>
      </div>
      <div className="footer">
        <Footer location="searchDoctor" pageName="searchDoctor" hide="false" />
      </div>
    </div>
  );
};

export default SelectedType;
