import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faCoffee } from '@fortawesome/free-solid-svg-icons'

import style from './style.module.css'

const Footerfix = () => {
  return (
    <div className="text-center py-4 new" style={{ backgroundColor: "#ffffff " }}>
      <div className="row">
        <div className={`col-sm-12 col-md-6 col-lg-6 col-xl-6 ${style.logoDiv}`}>
          <p className={style.footer_label}>© 2022 ONRx. All rights reserved.</p>
        </div>
        <div className={`col-sm-12 col-md-6 col-lg-6 col-xl-6 ${style.logoDiv}`}>
          <p className={style.footer_label2}> <a target="_blank" href="/term-and-conditions" className={style.linkA}>Terms and Conditions | Policy </a> </p>
        </div>
      </div>
    </div>
  );
};

export default Footerfix
