/* eslint-disable no-var */
import React, { useEffect } from "react";
import Problems from "./problems/index";
import SelectType from "./selectType/index";
import SearchDoctor from "./searchDoctor/index";
import BookAppointment from "./bookAppointment/index";
import CheckOut from "./checkOut/index";
import BookingSuccess from "./bookingSuccess/index";
import DoctorProfile from "./doctorProfile/index";
import ChatDoctor from "./dashboard/chatDoctor/index";
import Orders from "./dashboard/orders/index";
import MedicaldDetails from "./dashboard/medicaldetails/index";
import MedicaldDetailsForMobile from "./dashboard/medicaldetailsForMobile/index";
import Profile from "./dashboard/profile/index";
import SelectRegion from "./selectRegion/index";
import AppoimentType from "./appoimentType/index";
import SelectCallback from "./selectCallback/index";
import SelectPrivateService from "./selectPrivateService/index";
import PaymentSummary from "./paymentSummary/index";
import ChangePassword from "./dashboard/changePassword/index";
import ViewProfile from "./dashboard/viewProfile";
import Inactive from "./disabled-user";
import CompleteNewPassword from "./completeNewPassword";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./dashboard";
import ProtectedRoute from "../components/auth/ProtectedRoute";
import Workshop from "./workShop";
import Vitals from "./dashboard/vitals";
import Event from "./viewEventWorkShops";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";

import "../../src/css/css3/all3.css";
import "../../src/css/css3/fontawesome.min3.css";
import "../../src/css/css3/style3.css";
import "../../src/css/css3/style-admin3.css";
import "../../src/css/css3/all.min3.css";
import "../../src/css/css3/fontawesome3.css";

import "../../src/css/css/all1.css";
import "../../src/css/css/fontawesome.min1.css";
import "../../src/css/css/style1.css";
import "../../src/css/css/style-admin1.css";
import "../../src/css/css/bootstrap1.css";
import "../../src/css/css/fontawesome1.css";

import "../../src/css/css2/all2.css";
import "../../src/css/css2/fontawesome.min2.css";
import "../../src/css/css2/style2.css";
import "../../src/css/css2/style-admin2.css";
import "../../src/css/css2/all.min2.css";
import "../../src/css/css2/fontawesome2.css";

import "../../src/css/global.css";

import Login from "./login";
import ForgotPassword from "./forgotPassword";
import Register from "./register";
import Dependent from "./dashboard/dependent";
import { useSelector } from "react-redux";
import TermsandConditions from "./termAndConditions";
import VideoContainerWrapper from "components/videoCalling/VideoContainerWrapper";
import { checkToken } from "../services/common.api";
import RegisterUHC from "./registerUHC";
interface IMyProps {
  history: any;
}
const Home: React.FC<IMyProps> = ({ history }) => {
  const usrAuth = useSelector((state: any) => state.userReducer.User_Auth); // User_Auth || isloggedin? true:false;
  const tkn = checkToken();
  const isAuth =  (usrAuth || tkn)? true:false;
  // const usr = useSelector((state: any) => state.userReducer);
  // console.log("++++++Index Page++++++ HISTORY ", history)
  console.log("++++++Index Page++++++ UserAuth,tkn isAuth,  ",usrAuth,  tkn , isAuth,)
  return (
    <>
      <div className="d-flex flex-column min-vh-100">
        <Router history={history}>
          <Switch>
            {isAuth == true ? (
              // <ProtectedRoute path="/dashboard" component={Dashboard} auth={isAuth}/>
              <Route
                exact
                path="/"
                render={() => <Redirect to="/dashboard" />}
              />
            ) : (
              <Route exact path="/" render={() => <Redirect to="/login" />} />
            )}

            <Route exact path="/login">
              <Login />
            </Route>

            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>

            <Route exact path="/register">
              <Register />
            </Route>

            <Route exact path="/RegisterUHC">
              <RegisterUHC  />
            </Route>

            <Route path="/inactive">
              <Inactive />
            </Route>

            {/* <Route exact path="/dashboard">
            <Dashboard />
          </Route> */}

            <Route exact path="/complete-new-password">
              <CompleteNewPassword />
            </Route>

            <ProtectedRoute
              path="/dashboard"
              component={Dashboard}
              auth={isAuth}
            />
            {/* <Route exact path="/dependent">
            <Dependent />
          </Route> */}
            <ProtectedRoute
              path="/dependent"
              component={Dependent}
              auth={isAuth}
            />
            {/* <Route exact path="/selectType">
            <SelectType />
          </Route> */}
            <ProtectedRoute
              path="/selectType"
              component={SelectType}
              auth={isAuth}
            />
            {/* <Route path="/problems">
            <Problems />
          </Route> */}
            <ProtectedRoute
              path="/problems"
              component={Problems}
              auth={isAuth}
            />
            {/* <Route path="/searchDoctor">
            <SearchDoctor />
          </Route> */}
            <ProtectedRoute
              path="/searchDoctor"
              component={SearchDoctor}
              auth={isAuth}
            />

            {/* <Route path="/bookAppointment">
            <BookAppointment />
          </Route> */}

            <ProtectedRoute
              path="/bookAppointment"
              component={BookAppointment}
              auth={isAuth}
            />

            {/* <Route path="/checkOut">
            <CheckOut />
          </Route> */}

            <ProtectedRoute
              path="/checkOut"
              component={CheckOut}
              auth={isAuth}
            />

            {/* <Route path="/bookingSuccess">
            <BookingSuccess />
          </Route> */}

            <ProtectedRoute
              path="/bookingSuccess"
              component={BookingSuccess}
              auth={isAuth}
            />

            {/* <Route path="/doctorProfile">
            <DoctorProfile />
          </Route> */}
            <ProtectedRoute
              path="/doctorProfile"
              component={DoctorProfile}
              auth={isAuth}
            />

            {/* <Route path="/chatDoctor">
            <ChatDoctor />
          </Route> */}
            <ProtectedRoute
              path="/chatDoctor"
              component={ChatDoctor}
              auth={isAuth}
            />

            {/* <Route path="/orders">
            <Orders />
          </Route> */}
            <ProtectedRoute path="/orders" component={Orders} auth={isAuth} />

            {/* <Route path="/medicaldDetails">
            <MedicaldDetails />
           </Route> */}
            <ProtectedRoute
              path="/medicaldDetails"
              component={MedicaldDetails}
              auth={isAuth}
            />

            <Route path="/MedicaldDetailsForMobile">
              <MedicaldDetailsForMobile />
            </Route>
            {/* <ProtectedRoute
              path="/MedicaldDetailsForMobile"
              component={MedicaldDetailsForMobile}
              auth={isAuth}
            /> */}

            {/* <Route path="/profile">
            <Profile />
          </Route> */}
            <ProtectedRoute path="/profile" component={Profile} auth={isAuth} />

            {/* <Route path="/changePassword">
            <ChangePassword />
          </Route> */}
            <ProtectedRoute
              path="/changePassword"
              component={ChangePassword}
              auth={isAuth}
            />

            {/* <Route path="/select-region">
            <SelectRegion />
          </Route> */}
            <ProtectedRoute
              path="/select-region"
              component={SelectRegion}
              auth={isAuth}
            />

            {/* <Route path="/appoiment-type">
            <AppoimentType />
          </Route>
          <Route path="/workshop">
            <Workshop />
          </Route>
          <Route path="/event">
            <Event />
          </Route>
          </Route> */}
            {/* <Route path="/workshop">
            <Workshop />
          </Route> */}

            <ProtectedRoute
              path="/workshop"
              component={Workshop}
              auth={isAuth}
            />

            <ProtectedRoute
              path="/vitals"
              component={Vitals}
              auth={isAuth}
            />

            <ProtectedRoute path="/event" component={Event} auth={isAuth} />
            {/* <Route path="/event">
            <Event />
          </Route> */}
            <ProtectedRoute
              path="/appoiment-type"
              component={AppoimentType}
              auth={isAuth}
            />

            {/* <Route path="/select-callback">
            <SelectCallback />
          </Route> */}
            <ProtectedRoute
              path="/select-callback"
              component={SelectCallback}
              auth={isAuth}
            />
            <ProtectedRoute
              path="/selectPrivateService"
              component={SelectPrivateService}
              auth={isAuth}
            />
            <ProtectedRoute
              path="/paymentSummary"
              component={PaymentSummary}
              auth={isAuth}
            />
            <ProtectedRoute
              path="/term-and-conditions"
              component={TermsandConditions}
              auth={isAuth}
            />

            {/* <Route path="/view-profile">
            <ViewProfile />
          </Route> */}
            <ProtectedRoute
              path="/view-profile"
              component={ViewProfile}
              auth={isAuth}
            />
          </Switch>
        </Router>
      </div>
      {isAuth === true ? <VideoContainerWrapper /> : null}
    </>
  );
};

export default Home;
