import { ActionTypes } from "../constants/action-types";

export const setUser = (user) => {
  return {
    type: ActionTypes.SET_USER,
    payload: user,
  };
};
export const setProfileComplate = (status) => {
  return {
    type: ActionTypes.IS_PROFILE_COMPLATE,
    payload: status,
  };
};

export const setDoctorList = (DOC_LIST) => {
  return {
    type: ActionTypes.SET_DOCTOR_LIST,
    payload: DOC_LIST,
  };
};

export const selectedDoctor = (doctor) => {
  return {
    type: ActionTypes.SELECTED_DOCTOR,
    payload: doctor,
  };
};

export const selectedDoctorProfile = (doctorProfile) => {
  return {
    type: ActionTypes.SELECTED_DOCTOR_PROFILE,
    payload: doctorProfile,
  };
};

export const setCurrentAppointment = (currentAppointment) => {
  return {
    type: ActionTypes.CURRENT_APPOINTMENT,
    payload: currentAppointment,
  };
};
export const setProblem = (problem) => {
  return {
    type: ActionTypes.SET_PROBLEMS,
    payload: problem,
  };
};

export const selectPaymentProvider = (val) => {
  return {
    type: ActionTypes.SELECT_PAYMENT_PROVIDER,
    payload: val,
  };
};

export const selectAppointmentType = (val) => {
  return {
    type: ActionTypes.APPOINTMENT_TYPE,
    payload: val,
  };
};

export const bookAppointmentDoctor = (val) => {
  return {
    type: ActionTypes.BOOK_APPOINTMENT_DOCTOR,
    payload: val,
  };
};

export const selectedService = (val) => {
  return {
    type: ActionTypes.BOOK_APPOINTMENT,
    payload: val,
  };
};

export const callbackAppointment = (val) => {
  return {
    type: ActionTypes.CALLBACK_TYPE,
    payload: val,
  };
};

export const loadCurrentAccount = () => {
  return {
    type: ActionTypes.LOAD_CURRENT_ACCOUNT,
  };
};

export const loadWhichVersion = (val) => {
  return {
    type: ActionTypes.WHICH_VERSION,
    payload: val,
  };
};
export const authentication = (val) => {
  return {
    type: ActionTypes.AUTHENTICATION,
    payload: val,
  };
};

export const setCognitoUserData = (val) => {
  return {
    type: ActionTypes.SET_COGNITO_USER_DATA,
    payload: val,
  };
};

export const setMeetingEndData = (val) => {
  return {
    type: ActionTypes.CALL_END,
    payload: val,
  };
};

export const setCallID = (val) => {
  return {
    type: ActionTypes.SET_CALL_ID,
    payload: val,
  };
};

export const setFamilyDoctor = (val) => {
  return {
    type: ActionTypes.FAMILY_DOCTOR,
    payload: val,
  };
};


export const setEndModal = (val) => {
  return {
    type: ActionTypes.CALL_END,
    payload: val,
  };
};

export const setSelectedFamilyMember = (val) => {
  return {
    type: ActionTypes.SELECTED_FAMILY_MEMBER,
    payload: val,
  };
};

export const AppointmentRequestType = (val) => {
  return {
    type: ActionTypes.APPOINTMENT_REQUEST_TYPE,
    payload: val,
  };
};
export const setAppConfig = (val) => {
  return {
    type: ActionTypes.App_Config,
    payload: val,
  };
};
export const setInsurance = (val) => {
  return {
    type: ActionTypes.INSURANCE,
    payload: val,
  };
};
export const setUserToken = (val) => {
  return {
    type: ActionTypes.USER_TOKEN,
    payload: val,
  };
};
export const setChatHead = (val) => {
  return {
    type: ActionTypes.CHATHEAD,
    payload: val,
  };
};

export const setChatUserData = (val) => {
  return {
    type: ActionTypes.CHAT_USER,
    payload: val,
  };
};

