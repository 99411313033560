/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Skeleton, Card, Avatar, Typography, Button } from "antd";
import _ from "lodash";
import style from "./style.module.css";

const { Meta } = Card;
const { Title, Text } = Typography;

const FamilyHealthHistoryCard = ({ loading, setOfQuestionsAnswers, title }) => {
  var familyDetails = [];
  var children = [];
  var sibling = [];

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    console.log(
      setOfQuestionsAnswers.familyHealthHistory,
      "Personal Health History==="
    );

    setOfQuestionsAnswers.familyHealthHistory?.forEach((element, i) => {
      console.log(element);

      if (element.type == "table" && element.heading == "Children") {
        children = element.table?.answer?.filter((item) => {
          return (
            item.Age !== "" &&
            item.Gender !== "" &&
            item.SignificantHealthProblems !== ""
          );
        });
        element.length = children.length;
      }

      if (element.type == "table" && element.heading == "Sibling") {
        sibling = element.table?.answer?.filter((item) => {
          return (
            item.Age !== "" &&
            item.Gender !== "" &&
            item.SignificantHealthProblems !== ""
          );
        });
        element.length = sibling.length;
      }

      if (element.type == "tableWithInput") {
        element.relationTableData = { answer: [] };
        familyDetails = element.relationTable?.answer?.filter((item) => {
          return item.age !== "" && item.significantHealth !== "";
        });
        element.length = familyDetails.length;
        element.relationTableData.answer = familyDetails;
      }

      if (setOfQuestionsAnswers.familyHealthHistory?.length - 1 == i) {
        setLoader(false);
        console.log(setOfQuestionsAnswers.familyHealthHistory, "==========");
      }
    });

    if (_.isUndefined(setOfQuestionsAnswers?.familyHealthHistory)) {
      setLoader(false);
    }
  }, []);

  return (
    <>
      <div>
        <div className={style.titleHeader}>Patient Family Health History</div>

        {!_.isUndefined(setOfQuestionsAnswers?.familyHealthHistory) &&
          setOfQuestionsAnswers?.familyHealthHistory?.map(
            (questionAndAnswer, index) => {
              return (
                <div className={style.padd}>
                <div key={index}>
                  {questionAndAnswer?.heading &&
                    questionAndAnswer?.length != 0 && (
                      <h4 className={style.subtitleHeader}>{questionAndAnswer?.heading}</h4>
                    )}
                  <div>
                    {questionAndAnswer?.type == "tableWithInput" &&
                      questionAndAnswer?.length != 0 && (
                        <div className="row">
                          <div className="col-12">
                            <div className={style.divider}>
                              <table className="table table-bordered">
                                <thead>
                                  <>
                                    <tr>
                                      <th scope="col">Relation</th>
                                      <th scope="col">Age</th>
                                      <th scope="col">
                                        Significant Health Problems
                                      </th>
                                    </tr>
                                  </>
                                </thead>
                                <tbody>
                                  {![1, 2].includes(index) &&
                                    questionAndAnswer?.relationTableData?.answer
                                      ?.length &&
                                    questionAndAnswer?.relationTableData?.answer?.map(
                                      (tableData, tableIndex) => (
                                        <>
                                          <tr>
                                            <td>
                                              <Text>
                                                {tableData.relationName}
                                              </Text>
                                            </td>
                                            <td>
                                              <Text>
                                                {tableData.age}
                                              </Text>
                                            </td>
                                            <td>
                                              <Text>
                                                {tableData.significantHealth}
                                              </Text>
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    )}
                                  {[1, 2].includes(index) &&
                                    questionAndAnswer?.table.answer.length &&
                                    questionAndAnswer?.length !== 0 &&
                                    questionAndAnswer?.table?.answer.map(
                                      (tableData, tableIndex) => (
                                        <>
                                          <tr>
                                            <td>
                                              <Text style={{ textTransform: "capitalize" }}>
                                                {tableData.Gender}
                                              </Text>
                                            </td>
                                            <td>
                                              <Text>
                                                {tableData.Age}
                                              </Text>
                                            </td>
                                            <td>
                                              <Text>
                                                {
                                                  tableData.SignificantHealthProblems
                                                }
                                              </Text>
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}

                    {["table"].includes(questionAndAnswer?.type) &&
                      questionAndAnswer?.length != 0 && (
                        <div className="row">
                          <div className="col-12">
                            <div className={style.divider}>
                              <table className="table table-bordered">
                                <thead>
                                  <>
                                    <tr>

                                      <th scope="col">Gender</th>
                                      <th scope="col">Age</th>
                                      <th scope="col">
                                        Significant Health Problems
                                      </th>
                                    </tr>
                                  </>
                                </thead>
                                <tbody>


                                  {["table"].includes(
                                    questionAndAnswer?.type
                                  ) &&
                                    questionAndAnswer?.table.answer.length &&
                                    questionAndAnswer?.length !== 0 &&
                                    questionAndAnswer?.table?.answer.map(
                                      (tableData, tableIndex) => (
                                        <>
                                          <tr>
                                            <td>
                                              <Text style={{ textTransform: "capitalize" }}>
                                                {tableData.Gender}
                                              </Text>
                                            </td>
                                            <td>
                                              <Text>
                                                {tableData.Age}
                                              </Text>
                                            </td>
                                            <td>
                                              <Text>
                                                {
                                                  tableData.SignificantHealthProblems
                                                }
                                              </Text>
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                </div>                                  
              );
            }
          )}

        {/* {setOfQuestionsAnswers?.familyHealthHistory ? (
          <Card loading={loader} title={title || null} className="mb-4">
            {_.isUndefined(setOfQuestionsAnswers?.familyHealthHistory) && (
              <Text>This patient has not filled up their Health History yet</Text>
            )}
            {!_.isUndefined(setOfQuestionsAnswers?.familyHealthHistory) &&
              setOfQuestionsAnswers?.familyHealthHistory?.map(
                (questionAndAnswer, index) => {
                  return (
                    <div key={index}>
                      {questionAndAnswer?.heading &&
                        questionAndAnswer?.length != 0 && (
                          <h4 className={style.subtitleHeader}>{questionAndAnswer?.heading}</h4>
                        )}
                      
                      <div>
                        <div>
                          <div className="row ml-4">
                            {questionAndAnswer?.type == "tableWithInput" &&
                              questionAndAnswer?.length != 0 && (
                                <table className="table table-bordered ml-n4">
                                  <thead>
                                    <>
                                      <tr>
                                        <th scope="col">Relation</th>
                                        <th scope="col">Age</th>
                                        <th scope="col">
                                          Significant Health Problems
                                        </th>
                                      </tr>
                                    </>
                                  </thead>
                                  <tbody>
                                    {![1, 2].includes(index) &&
                                      questionAndAnswer?.relationTableData?.answer
                                        ?.length &&
                                      questionAndAnswer?.relationTableData?.answer?.map(
                                        (tableData, tableIndex) => (
                                          <>
                                            <tr>
                                              <td>
                                                <Text>
                                                  {tableData.relationName}
                                                </Text>
                                              </td>
                                              <td>
                                                <Text>
                                                  {tableData.age}
                                                </Text>
                                              </td>
                                              <td>
                                                <Text>
                                                  {tableData.significantHealth}
                                                </Text>
                                              </td>
                                            </tr>
                                          </>
                                        )
                                      )}
                                    {[1, 2].includes(index) &&
                                      questionAndAnswer?.table.answer.length &&
                                      questionAndAnswer?.length !== 0 &&
                                      questionAndAnswer?.table?.answer.map(
                                        (tableData, tableIndex) => (
                                          <>
                                            <tr>
                                              <td>
                                                <Text>
                                                  {tableData.Gender}
                                                </Text>
                                              </td>
                                              <td>
                                                <Text>
                                                  {tableData.Age}
                                                </Text>
                                              </td>
                                              <td>
                                                <Text>
                                                  {
                                                    tableData.SignificantHealthProblems
                                                  }
                                                </Text>
                                              </td>
                                            </tr>
                                          </>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              )}

                            {["table"].includes(questionAndAnswer?.type) &&
                              questionAndAnswer?.length != 0 && (
                                <table className="table table-bordered ml-n4">
                                  <thead>
                                    <>
                                      <tr>
                                        
                                        <th scope="col">Gender</th>
                                        <th scope="col">Age</th>
                                        <th scope="col">
                                          Significant Health Problems
                                        </th>
                                      </tr>
                                    </>
                                  </thead>
                                  <tbody>
                                    
                                    
                                    {["table"].includes(
                                      questionAndAnswer?.type
                                    ) &&
                                      questionAndAnswer?.table.answer.length &&
                                      questionAndAnswer?.length !== 0 &&
                                      questionAndAnswer?.table?.answer.map(
                                        (tableData, tableIndex) => (
                                          <>
                                            <tr>
                                              <td>
                                                <Text>
                                                  {tableData.Gender}
                                                </Text>
                                              </td>
                                              <td>
                                                <Text>
                                                  {tableData.Age}
                                                </Text>
                                              </td>
                                              <td>
                                                <Text>
                                                  {
                                                    tableData.SignificantHealthProblems
                                                  }
                                                </Text>
                                              </td>
                                            </tr>
                                          </>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
          </Card>
        ) : null} */}

      </div>
    </>
  );
};

export default FamilyHealthHistoryCard;
