import { useEffect, useState } from "react";
import { TopBar } from "components";
import Footer from "components/footer";
import { Steps, Form, Button, Select } from 'antd';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { callbackAppointment, selectAppointmentType } from "../../redux/actions/userActions";
import style from "./style.module.css";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { GET } from "services/common.api";

const SelectPrivateService = () => {
    const dispatch = useDispatch();
    const { Step } = Steps;
    const [selectedCard, setSelectedCard] = useState("");
    const [todayDate, setTodayDate] = useState("");
    const [certainHours, setCertainHours] = useState("");
    const [reason, setReason] = useState("");
    const [slots, setSlots] = useState(false);
    const [slotsTime, setSlotsTime] = useState("")
    const { Option } = Select;
    const [selectedServiceValue, setSelectedServiceValue] = useState(null);
    const [selectedService, setSelectedService] = useState(null);
    const [allServices, setAllServices] = useState([]);

    const cardClick = (selectedCard) => {
        console.log(selectedCard)
        setSelectedCard(selectedCard)
        // dispatch(callbackAppointment(selectedCard));
        if (selectedCard == "Morning") {
            setSlotsTime("08:00AM-12:00PM")
        } else if (selectedCard == "Afternoon") {
            setSlotsTime("12:00PM-05:00PM")
        } else if (selectedCard == "Evening") {
            setSlotsTime("05:00PM-09:00AM")
        }
    };

    useEffect(() => {
        setTodayDate(moment().format("dddd, MMM DD, YYYY"))
        // dispatch(callbackAppointment(selectedCard));
        generateGreetings()
        getServices();
    }, [])

    const getServices = async () => {
        try{
            await GET(`services/private`).then((response) => {
                console.log("All Services",response.data)

                if(response?.data?.errorMessage){
                    setAllServices([]);
                }else{
                    setAllServices(response?.data);
                }
            });
        } catch (error) {
            setAllServices([]);
            console.log(error,"error")
        }
    }

    const generateGreetings = () => {
        console.log("okokokokokokokokok ")
        let currentHour: any = moment().format("HH");
        if (currentHour >= 3 && currentHour < 12) {
            setCertainHours("Morning")
            console.log(currentHour, "event****")
            setSlots(false)
            setSlotsTime("08:00AM-12:00PM")

        } else if (currentHour >= 12 && currentHour < 15) {
            setCertainHours("Afternoon")
            setSelectedCard('Afternoon')
            setSlotsTime("12:00PM-05:00PM")
            console.log(currentHour, "event****")
            setSlots(false)

        } else if (currentHour >= 15 && currentHour < 20) {
            setCertainHours("Evening")
            console.log(currentHour, "event****")
            setSelectedCard('Evening')
            setSlotsTime("05:00PM-09:00AM")
            setSlots(false)
        } else {
            setSlots(true)
            console.log("NO slots available")
        }
    }

    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const leftArraow = () => {
        let C = moment().format("dddd, MMM DD, YYYY")
        setTodayDate(moment(C).format("dddd, MMM DD, YYYY"))
        generateGreetings()
    }
    const rightArraow = () => {
        let C = moment().format("DD-MM-YYYY")
        let A = moment(C, "DD-MM-YYYY").add(1, 'days');
        console.log(moment(A).format("dddd, MMM DD, YYYY"));
        setTodayDate(moment(A).format("dddd, MMM DD, YYYY"))
        setSlots(false)
    }

    function handleChangeService(value) {
        console.log(value)
        setSelectedServiceValue(value);

        const data = allServices?.filter((item) => item.id == value);
        console.log(data[0]);

        setSelectedService(data[0]);
    }

    return (
        <div style={{ height: '100%' }}>
            <div className={style.box}>
                <p className={style.pageInfo}>Private Service</p>
                <h5 className={style.h5New}>Dashboard / Select Private Service</h5>
            </div>

            <div className="content contentSelect" style={{ height: 600 }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-0 col-md-1 col-xl-2"></div>
                        <div className="col-sm-12 col-md-10 col-xl-8">
                            <div className="stepDiv d-none d-sm-block">
                                <Steps>
                                    {/* <Step status="wait" title="Problems" /> */}
                                    <Step status="finish" title="Select Slot" />
                                    <Step status="finish" title="Select Service" />
                                    <Step status="finish" title="Payment" />
                                    {/* <Step status="wait" title="Select Doctor" /> */}
                                    {/* <Step status="wait" title="Book Appointment" /> */}
                                </Steps>
                                <div className={style.divPayment}>
                                    {
                                        todayDate !== moment().format('dddd, MMM DD, YYYY') ?
                                            <img onClick={leftArraow} src="Images/problems/arrow-left.png" alt="" className={style.arrowIcon} /> :
                                            null
                                    }
                                    <h2 className="lblPayment px-3">{todayDate}</h2>
                                    {
                                        todayDate == moment().format('dddd, MMM DD, YYYY') ?
                                            <img onClick={rightArraow} src="Images/problems/arrow-right.png" alt="" className={style.arrowIcon} /> :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-0 col-md-1 col-xl-2"></div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="row text-left">
                                <div className="col-sm-1 col-md-1 col-xl-2"></div>
                                <div className="col-sm-10 col-md-10 col-xl-8">
                                    <div className={style.div_flex}>
                                        {
                                            certainHours == 'Morning' && todayDate == moment().format('dddd, MMM DD, YYYY') ?
                                                <>
                                                    <div className={style.div_inner_flex}>
                                                        <div onClick={() => cardClick('Morning')} className={`${selectedCard === 'Morning' ? `${style.card_selected}` : `${style.card_static}`}`}>
                                                            <img src="Images/morning.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Morning</h4>
                                                                <h6 className="p-0 m-0">8AM - 12PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Afternoon' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Afternoon')}>
                                                            <img src="Images/afternoon.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Afternoon</h4>
                                                                <h6 className="p-0 m-0">12PM - 5PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Evening' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Evening')}>
                                                            <img src="Images/evening.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Evening</h4>
                                                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                        {/* next day */}
                                        {
                                            todayDate !== moment().format('dddd, MMM DD, YYYY') ?
                                                <>
                                                    <div className={style.div_inner_flex}>
                                                        <div onClick={() => cardClick('Morning')} className={`${selectedCard === 'Morning' ? `${style.card_selected}` : `${style.card_static}`}`}>
                                                            <img src="Images/morning.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Morning</h4>
                                                                <h6 className="p-0 m-0">8AM - 12PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Afternoon' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Afternoon')}>
                                                            <img src="Images/afternoon.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Afternoon</h4>
                                                                <h6 className="p-0 m-0">12PM - 5PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Evening' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Evening')}>
                                                            <img src="Images/evening.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Evening</h4>
                                                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }

                                        {
                                            certainHours == 'Afternoon' && todayDate == moment().format('dddd, MMM DD, YYYY') ?
                                                <>
                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Afternoon' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Afternoon')}>
                                                            <img src="Images/afternoon.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Afternoon</h4>
                                                                <h6 className="p-0 m-0">12PM - 5PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Evening' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Evening')}>
                                                            <img src="Images/evening.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Evening</h4>
                                                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                        {
                                            certainHours == 'Evening' && todayDate == moment().format('dddd, MMM DD, YYYY') ?
                                                <>
                                                    <div className={style.div_inner_flex}>
                                                        <div className={`${selectedCard === 'Evening' ? `${style.card_selected}` : `${style.card_static}`}`} onClick={() => cardClick('Evening')}>
                                                            <img src="Images/evening.png" alt="" className={style.productImg} />
                                                            <div className="doc-info-center text-center">
                                                                <h4 className="p-0 m-0">Evening</h4>
                                                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }

                                        {
                                            slots == true ?
                                                <div className={style.div_inner_flex}>
                                                    <div className={style.card_static}>
                                                        <img src="Images/problems/cancel.png" alt="" className={style.productImg} />
                                                        <div className="doc-info-center text-center">
                                                            <h4 className="p-0 m-0 pt-2">No slots available</h4>
                                                            {/* <h6 className="p-0 m-0">5pm - 12am</h6> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-1 col-md-1 col-xl-2"></div>
                            </div>
                        </div>
                    </div>
                    <div className={style.reasonDiv}>
                        {
                            slots !== true ?
                                <div className={` mt-5`} style={{ width: 580, marginRight: 26 }}>
                                    <div className="pb-0 mb-0" style={{ textAlign: 'start' }}>
                                        <Form
                                            layout="vertical"
                                            name="basic"
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                        >

                                        <h4 className="card-title">Select Service *</h4>
                                        <Select
                                            showSearch
                                            placeholder="Select Service"
                                            optionFilterProp="children"
                                            onChange={handleChangeService}
                                            value={selectedServiceValue}
                                            style={{
                                            fontSize : 15,
                                            width: '100%',
                                            borderWidth: 1,
                                            borderColor: "rgba(0,0,0,0.)",
                                            borderRadius: 5,
                                            marginRight: 15,
                                            marginBottom: 25,
                                            whiteSpace:"pre-wrap"
                                            }}
                                        >
                                           
                                           {allServices?.map((itemm, index) => (
                                            <>
                                                {itemm.service != null ? (
                                                <Option key={index} value={itemm.id}>
                                                    <span style={{whiteSpace:"pre-wrap"}}>
                                                    {itemm.service} - ${itemm?.fees}
                                                    </span>
                                                </Option>
                                                ) : null}
                                            </>
                                            ))}

                                            {/* // <Option value={'General Service'}>
                                            //     <span style={{whiteSpace:"pre-wrap"}}>
                                            //         General Service - 10
                                            //     </span>
                                            // </Option>
                                            // <Option value={'Mental Health'}>
                                            //     <span style={{whiteSpace:"pre-wrap"}}>
                                            //         Mental Health - 20
                                            //     </span>
                                            // </Option>
                                            // <Option value={'Sick Notes'}>
                                            //     <span style={{whiteSpace:"pre-wrap"}}>
                                            //         Sick Notes and Forms - 30
                                            //     </span>
                                            // </Option> */}
                                        </Select>
                                        </Form>
                                    </div>
                                </div>
                            : null
                        }
                    </div>

                </div>
            </div>

            {!slots ? 
                <div className="footer">
                    <Footer
                        location="paymentSummary"
                        pageName="selectPrivateService"
                        hide="true"                    
                        valueSelectedServiceValue={selectedServiceValue}
                        selectedServiceData={selectedService}
                        appoimentDate={todayDate}
                        // disbleFooter={!(!slots && selectedCard !== "" && (selectedServiceValue === null || selectedServiceValue === ''))}
                        disbleFooter={selectedCard !== "" && selectedServiceValue !== null}
                        slotsTime={slotsTime}
                        selectslot={selectedCard}
                    />
                </div> 
            : null }
        </div>
    );
};

export default SelectPrivateService;