import { TopBar } from "components";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import DashboardSidebar from "../sidebar/sidebar";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { PUT, GET, POST, DELETE } from "../../../services/common.api";
import { v4 as uuidv4 } from "uuid";
import { s3Upload } from "../../../services/s3fileUpload/index";
import { find } from "lodash";
import {
  setUser,
  setProfileComplate,
  setFamilyDoctor,
} from "../../../redux/actions/userActions";
import {
  Form,
  notification,
  Select,
  Spin,
  Modal,
  Space,
  Tag,
  Table,
  Tooltip,
  Button,
  Popconfirm,
  Input,
  Drawer,
  DatePicker,
  Alert,
  Switch,
} from "antd";
import Footer from "components/footerFix";
// import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
// import "react-datepicker/dist/react-datepicker.css";
import { DeleteFilled } from "@ant-design/icons";
import CountrysJson from "../../../jsonFiles/country.json";
import SearchLocationInput from "components/searchAddress";
import MaskInput from "react-maskinput";
import { IMG01 } from "./img";
// import { select } from "redux-saga/effects";

const Profile = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { Option } = Select;
  const [userInfo, setUserInfo] = useState({} as any);
  const [dob, setDob] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryJson, setCountryJson] = useState({} as any);
  const [selectCountry, setSelectCountry] = useState({} as any);
  const [validPostCode, setValidPostCode] = useState({} as any);
  // const [familyDoctorDetails, setFamilyDoctorDetails] = useState("" as any);
  const [selectedFamilyMemberID, setSelectFamilyMemberID] = useState("");
  const [insuranceType, setInsuranceType] = useState("OHIP");
  const [allDoctor, setAllDoctor] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [formValues, setFormValues] = useState({} as any);
  const [loader, setLoader] = useState(false);
  const [imgLoader, setImgLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [insuranceData, setInsuranceData] = useState([] as any);
  let config = useSelector((state: any) => state.AppConfig.config);
  const userData = useSelector((state: any) => state.userReducer.user);
  const familyD = useSelector((state: any) => state?.userReducer?.familyDoc);
  const [ProfilePath, setprofilePath] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [address, setAddress] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(undefined);
  const [, setLat] = useState(null);
  const [isInsurance, setIsInsurance] = useState(false);
  const [disableAddNew, setDisableAddNew] = useState(false);

  const [, setLng] = useState(null);

  const [userProfileKey, setUserProfileKey] = useState(null);

  const [startDate, setStartDate] = useState(null);

  const handleChange = (event) => {
    // console.log(event.target.name, "jsdfhjsfhhdsfhfgjh ");

    if (event.target.name === "FirstName") {
      console.log(event.target.value, "jsdfhjsfhhdsfhfgjh ");
      console.log(userData?.FirstName, "userData?.FirstName");
      if (event.target.value !== userData?.FirstName) {
        setDisableAddNew(true);
      } else {
        setDisableAddNew(false);
      }
    } else if (event.target.name === "MiddleName") {
      if (event.target.value !== userData?.MiddleName) {
        setDisableAddNew(true);
      } else {
        setDisableAddNew(false);
      }
    } else if (event.target.name === "LastName") {
      if (event.target.value !== userData?.LastName) {
        setDisableAddNew(true);
      } else {
        setDisableAddNew(false);
      }
    }

    if (
      errorMsg == "Invalid OHIP Card" &&
      event.target.name == "Insurancesvcnum"
    ) {
      setShowError(false);
    } else if (
      errorMsg == "Invalid version code" &&
      event.target.name == "insuranceVersionCode"
    ) {
      setShowError(false);
    } else if (
      (errorMsg == "First name should match with OHIP" ||
        errorMsg ==
          "Your account profile details should match with the OHIP card details") &&
      event.target.name == "fname"
    ) {
      setShowError(false);
    }
    // else if ((errorMsg == 'First name should match with OHIP' || errorMsg == 'Change the profile details according to the OHIP card') && event.target.name == 'fname') {
    //   setShowError(false);
    // }
    // else if ((errorMsg == 'First name should match with OHIP' || errorMsg == 'First name should match with profile info') && event.target.name == 'fname') {
    //   setShowError(false);
    // }
    else if (
      (errorMsg == "Last name should match with OHIP" ||
        errorMsg ==
          "Your account profile details should match with the OHIP card details") &&
      event.target.name == "lname"
    ) {
      setShowError(false);
    }
    // else if ((errorMsg == 'Last name should match with OHIP' || errorMsg == 'Change the profile details according to the OHIP card') && event.target.name == 'lname') {
    //   setShowError(false);
    // }
    // else if ((errorMsg == 'Last name should match with OHIP' || errorMsg == 'Last name should match with profile info') && event.target.name == 'lname') {
    //   setShowError(false);
    // }
    else if (errorMsg == "Invalid or Expired health card") {
      setShowError(false);
    }

    const name = event?.target?.name;
    const value = event?.target?.value;
    console.log(name, value);
    setFormValues((formValues) => ({ ...formValues, [name]: value }));
    console.log(formValues, "formValues");
  };

  const handleChangePhonenumber = (event) => {
    console.log(event.target.name, "jsdfhjsfhhdsfhfgjh ");
    console.log(event.target.value, "jsdfhjsfhhdsfhfgjh ");
    setPhoneNumber(event.target.value);
  };

  const onChangeDateOfBirth = (date, dateString) => {
    console.log("dataeateaeataeata", dateString);
    setDateOfBirth(dateString);
    if (errorMsg == "Date of birth should match with OHIP") {
      setShowError(false);
    }
  };

  // const handleChangeDob = (event) => {
  //     console.log(moment(event).format('YYYY-MM-DD'))
  //     setDob(moment(event).format('YYYY-MM-DD'))
  // }

  const handleChangeMemeber = (event) => {
    console.log(event);
    setSelectFamilyMemberID(event);
  };

  const getInsuranceInfo = async () => {
    try {
      const res = await GET(`user/insurance/${userData.id}`);
      console.log(res?.data, "getInsuranceInfo");
      if (res?.data.length != 0) {
        form.setFieldsValue({
          Insurancesvcnum: res?.data[0]?.insurance_number,
        });
        form.setFieldsValue({
          insuranceVersionCode: res?.data[0]?.insurance_vc,
        });
        setIsInsurance(false);
      } else {
        setIsInsurance(true);
      }
      setInsuranceData(res?.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  const getFamilyDoctor = async () => {
    try {
      await GET(`patient/familydoc/${userData.id}`).then((response) => {
        if (response?.data?.body != null) {
          console.log(
            "🚀 ~ file: index.tsx ~ line 110 ~ awaitGET ~ response",
            response
          );
          let N = response?.data?.body?.CompanyEmployee?.CompanyEmployee;
          // setFamilyDoctorDetails(`Dr. ${N?.FirstName} ${N?.LastName}`)
          console.log(
            `Dr. ${N?.FirstName} ${N?.LastName}`,
            "NNNNNNNNNNNNNNNNNNN"
          );
          if (N !== null && N !== "") {
            if (N?.FirstName !== undefined && N?.LastName !== undefined) {
              dispatch(setFamilyDoctor(`Dr. ${N?.FirstName} ${N?.LastName}`));
            } else {
              dispatch(setFamilyDoctor(""));
            }
          }
        }
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };

  // const getSelectdFamilyDoctor = async () => {
  //     if (userData.id !== "") {
  //         try {
  //             const familyDoctor = await GET(`patient/familydoc/${userData?.id}`);
  //             console.log(familyDoctor.data?.body, "Family Doctor data");
  //             setFamilyDisplayId(familyDoctor?.data?.body?.CompanyEmployee?.CompanyEmployee)
  //             familyDoctor.name = familyDoc.FirstName + familyDoc.MiddleName + familyDoc.LastName
  //             dispatch(setFamilyDoctor(`Dr. ${familyDoctor.data?.body?.CompanyEmployee?.CompanyEmployee?.FirstName} ${familyDoctor.data?.body?.CompanyEmployee?.CompanyEmployee?.LastName}`))
  //             setFamilyDoc(familyDoctor?.data?.body?.CompanyEmployee?.CompanyEmployee);
  //             console.log(familyDoctor?.data?.body?.CompanyEmployee?.CompanyEmployee.ID, "iddididididiididididid")
  //             setSelectFamilyMemberID(familyDoctor?.data?.body?.CompanyEmployee?.ID);
  //         } catch (error) {
  //             console.log(error)
  //         }
  //     }

  // }

  const getUserDetails = async () => {
    try {
      const userInfo = await GET(`user?cognitoid=${userData.cognitoid}`);
      console.log(userInfo, "update user");
      console.log(userInfo.data?.body, "update user");
      // var data = userInfo.data?.body
      // let Doc = "Dr." + familyDoc.FirstName + familyDoc.LastName
      // console.log(data)

      let ud;
      ud = userInfo.data?.body;
      ud.userId = ud?.cognitoid;
      ud.sub = ud?.cognitoid;

      dispatch(setUser(ud));
      // dispatch(setFamilyDoctor(Doc))
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {

    event.FirstName = event.FirstName.trim();
    event.LastName = event.LastName.trim();
    
    if (address == null || address === "") {
      setAddress(null);
    } else if (
      (event.Insurancesvcnum !== "____-___-___" &&
        event.insuranceVersionCode === "") ||
      (event.Insurancesvcnum !== undefined &&
        event.insuranceVersionCode === undefined)
    ) {
      notification.error({
        message: "Please select version code",
      });
    } else if (
      event.Insurancesvcnum !== "____-___-___" &&
      event.insuranceVersionCode !== "" &&
      event.Insurancesvcnum !== undefined &&
      event.insuranceVersionCode !== undefined
    ) {
      await handleSubmitModal(event);
    } else {
      setLoader(true);
      if (insuranceData?.length != 0) {
        insuranceData.forEach(element => {
          try {
            DELETE(`user/insurance/delete/${element?.id}`).then(
              () => {
  
              }
            );
          } catch (error) {
            setLoader(false);
            console.log(error);
          }
        });
      }
      try {
        setLoader(true);
        PUT(`user/patient/profile/${userData.cognitoid}`, {
          ...event,
          // medconcent : event?.medconcent == true ? 1 : 0,
          name: event?.FirstName.trim() + " " + event?.LastName.trim(),
          DOB: startDate,
          phoneNumber: phoneNumber,
          Address1: address,
          // profilepicture:
          //   userProfileKey == null ? userData.profilepicture : userProfileKey,           
        }).then(() => {
          // notification.success({
          //     message: 'Your Data Successfully Added',
          // })
          setLoader(false);
          // history.push("/view-profile");
          getUserDetails();

          if (selectedFamilyMemberID) {
            const found = allDoctor.find(
              (element) => element.companyemployeeid == selectedFamilyMemberID
            );
            console.log(found, "-*-*-*-**");
            dispatch(
              setFamilyDoctor(`Dr. ${found?.FirstName} ${found?.LastName}`)
            );
          }
          dispatch(setProfileComplate("true"));
          updateOrCreateFamilyMember();
          notification.success({
            message: "Your Data Successfully Added",
          });
          setLoader(false);
          setDisableAddNew(false);
          // history.push("/view-profile");
          getUserDetails();
          dispatch(setProfileComplate("true"));
          // setAddress(null);

          // try {
          //   PUT(`user/patient/profile/${userData.cognitoid}`, {
          //     ...formValues,
          //     DOB: startDate,
          //     phoneNumber: phoneNumber,
          //     profilepicture: userProfileKey == null ? userData.profilepicture : userProfileKey,
          //   }, token).then(() => {
          //     notification.success({
          //       message: "Your Data Successfully Added",
          //     });
          //     setLoader(false);
          //     history.push("/view-profile");
          //     getUserDetails();
          //     dispatch(setProfileComplate("true"));
          //   });
          // } catch (error) {
          //   setLoader(false);
          //   console.log(error);
          // }
        });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    }
  };

  const updateOrCreateFamilyMember = async () => {
    console.log(selectedFamilyMemberID);
    const familyAddObject = {
      patient_id: userData.id,
      provider_id: selectedFamilyMemberID,
    };
    console.log(familyAddObject);
    try {
      const res = await POST("patient/familydoc", familyAddObject);
      console.log(res, "family doc api ");
    } catch (err) {
      console.log("error", err);
    }
  };

  const handlePostalCode = () => {
    if (config?.country == "Canada") {
      // setValidPostCode(/^([A-Za-z][0-9][A-Za-z])\s*([0-9][A-Za-z][0-9])$/);
      setValidPostCode(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    } else if (config?.country == "United States") {
      setValidPostCode(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    } else if (config?.country == "India") {
      setValidPostCode(/^[1-9][0-9]{5}$/);
    }
  };

  useEffect(() => {
    getFamilyDoctor();
    handlePostalCode();
    setCountryJson(CountrysJson);
    console.log(countryJson);
    console.log(userData?.Country, "cut");
    console.log(config?.country, "cut");
    console.log(CountrysJson, "CountrysJson");

    // if(userData?.Country == "" || userData?.Country)
    // let G = userData?.Country == null ? config?.country : userData?.Country;
    let G = config?.country;

    console.log(G, "GGGGGGGGGG");

    var obj = CountrysJson?.countries?.filter((obj) => obj.country === G);
    console.log(obj, "call");
    setSelectCountry(obj[0]);

    setPhoneNumber(userData?.phoneNumber);
    setAddress(userData?.Address1);
    getInsuranceInfo();
    getUserDetails();
    // getSelectdFamilyDoctor();
    if (formValues.DOB !== "") {
      setDob(formValues.DOB);
    }
    if (userData?.DOB !== null && userData?.DOB !== "") {
      setStartDate(moment(userData.DOB, "YYYY-MM-DD").format("YYYY-MM-DD"));
    }

    // if (userData.DOB !== undefined && userData.DOB !== null) {
    //     setFormValues(formValues => ({ ...formValues, DOB: moment(userData.DOB).format('YYYY-MM-DD HH:mm:ss') }));
    // }

    setUserInfo(userData);
    getDoctorList();
  }, []);

  const dateFormat = "YYYY/MM/DD";

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const getDoctorList = async () => {
    const doctorList = await GET(`employee/doctors`);
    console.log(doctorList, "-*-*-*All doctorList");
    setAllDoctor(doctorList.data);
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
      code: "${label} is not a valid!",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const showModal = () => {
    // form.resetFields();
    form.setFieldsValue({ fname: "" });
    form.setFieldsValue({ middlename: "" });
    form.setFieldsValue({ lname: "" });
    form.setFieldsValue({ birth_date: "" });
    form.setFieldsValue({ Insurancesvcnum: "" });
    form.setFieldsValue({ insuranceVersionCode: "" });
    setIsModalVisible(true);
  };

  // const handleOk = async () => {
  // };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function confirm(e, event) {
    console.log(e.id);
    // try {
    //   DELETE(`user/insurance/delete/${e?.id}`).then(() => {

    addOhipCardDetail(e, event, "fromDelete");
    // notification.success({
    //   message: "Insurance successfully deleted",
    // });
    // getInsuranceInfo();
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
  }
  
  function cancel(e) {
    console.log(e);
  }

  // const columns = [
  //   // {
  //   //     title: 'Name',
  //   //     dataIndex: 'name',
  //   //     key: 'name',
  //   //     render: text => <a>{text}</a>,
  //   // },
  //   {
  //     title: "Type",
  //     dataIndex: "insurance_type",
  //     key: "insurance_type",
  //   },
  //   {
  //     title: "Insurance Number",
  //     dataIndex: "insurance_number",
  //     key: "insurance_number",
  //   },
  //   // {
  //   //   title: "Insurance Provider",
  //   //   dataIndex: "insurance_provider",
  //   //   key: "insurance_provider",
  //   // },
  //   {
  //     title: "Insurance Version Code",
  //     dataIndex: "insurance_vc",
  //     key: "insurance_vc",
  //   },

  //   {
  //     title: "Action",
  //     key: "action",
  //     render: (id) => (
  //       <Space size="middle">
  //         <Tooltip title="Delete" placement="bottom">
  //           <Popconfirm
  //             title="Are you sure?"
  //             onConfirm={() => confirm(id)}
  //             onCancel={cancel}
  //             okText="Yes"
  //             cancelText="No"
  //           >
  //             <Button shape="circle" danger icon={<DeleteFilled />} />
  //           </Popconfirm>
  //         </Tooltip>
  //       </Space>
  //     ),
  //   },
  // ];

  const data = [
    {
      key: "1",
      name: "1",
      age: 321234678,
      address: "123456789",
      type: "Government",
    },
  ];

  function handleChangeSelect(value) {
    console.log(value);
    setInsuranceType(value);
  }

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  // const handleSubmitModal = async (event: any) => {
  //   console.log("Success:", event);
  //   setBtnLoader(true);
  //   let ohipNumber = event.Insurancesvcnum.replaceAll("-", "");
  //   console.log(ohipNumber, "ohipnumberohipnumber");
  //   if (insuranceType == "OHIP") {
  //     const result = !!insuranceData.find(
  //       (item) => item.insurance_number === ohipNumber
  //     );
  //     const OhipDuplicate = !!insuranceData.find(
  //       (item) => item.insurance_type === "OHIP"
  //     );
  //     if (OhipDuplicate) {
  //       notification.warning({
  //         message: "You can add only one OHIP !",
  //       });
  //       setBtnLoader(false);
  //     } else {
  //       if (result) {
  //         notification.warning({
  //           message: "Insurance already added !",
  //         });
  //         setBtnLoader(false);
  //       } else {
  //         let ohipNumber = event.Insurancesvcnum.replaceAll("-", "");
  //         console.log(ohipNumber, "ohipnumberohipnumber");
  //         let data = {
  //           // 'Provider-number': formValues.Insurancesvcnum,
  //           "Provider-number": "020497",
  //           HCN: ohipNumber,
  //           VC: event.insuranceVersionCode,
  //           // User: formValues.FirstName || userData.FirstName,
  //           User: event.fname + " " + event.lname,
  //         };
  //         console.log(data, "check Api  Data");
  //         setBtnLoader(true);
  //         await axios
  //           .post("https://api.mdmax.ca/api/1.1/wf/api-validation-call", data, {
  //             headers: {
  //               Authorization: `Bearer cee53e7aa78674f078e300e6d96d98ec`,
  //               // Authorization: `Bearer 894da2b4b1760319ae94cbfa73db5a10`,
  //             },
  //           })
  //           .then(
  //             async (response: any) => {
  //               console.log(response, "responce");
  //               let D = moment(dateOfBirth).format("YYYY-MM-DD");

  //               if (response?.data?.response["MOH-card-eligible"] == false) {
  //                 setLoader(false);
  //                 setErrorMsg("Invalid OHIP Card");
  //                 setShowError(true);
  //                 setBtnLoader(false);
  //               } else {
  //                 if (
  //                   response?.data?.response["MOH-Message"] ==
  //                   "Invalid version code"
  //                 ) {
  //                   setLoader(false);
  //                   setErrorMsg("Invalid version code");
  //                   setShowError(true);
  //                   setBtnLoader(false);
  //                 } else {
  //                   if (
  //                     response?.data?.response["MOH-card-status"] == "invalid"
  //                   ) {
  //                     setLoader(false);
  //                     setErrorMsg("Invalid or Expired health card");
  //                     setShowError(true);
  //                     setBtnLoader(false);
  //                   } else {
  //                     if (response?.data?.response.DOB !== D) {
  //                       setLoader(false);
  //                       setErrorMsg("Date of birth should match with OHIP");
  //                       setShowError(true);
  //                       setBtnLoader(false);
  //                     } else {
  //                       if (
  //                         response?.data?.response[
  //                           "First-name"
  //                         ].toLowerCase() !== event.fname.toLowerCase()
  //                       ) {
  //                         setLoader(false);
  //                         setErrorMsg("First name should match with OHIP");
  //                         setShowError(true);
  //                         setBtnLoader(false);
  //                       } else {
  //                         if (
  //                           response?.data?.response[
  //                             "First-name"
  //                           ].toLowerCase() !==
  //                           userData?.FirstName.toLowerCase()
  //                         ) {
  //                           setLoader(false);
  //                           // setErrorMsg("First name should match with profile info")
  //                           // setErrorMsg("Change the profile details according to the OHIP card")
  //                           setErrorMsg(
  //                             "Your account profile details should match with the OHIP card details"
  //                           );
  //                           setShowError(true);
  //                           setBtnLoader(false);
  //                         } else {
  //                           if (
  //                             response?.data?.response[
  //                               "Last-name"
  //                             ].toLowerCase() !== event.lname.toLowerCase()
  //                           ) {
  //                             setLoader(false);
  //                             setErrorMsg("Last name should match with OHIP");
  //                             setShowError(true);
  //                             setBtnLoader(false);
  //                           } else {
  //                             if (
  //                               response?.data?.response[
  //                                 "Last-name"
  //                               ].toLowerCase() !==
  //                               userData.LastName.toLowerCase()
  //                             ) {
  //                               setLoader(false);
  //                               // setErrorMsg("Last name should match with profile info")
  //                               // setErrorMsg("Change the profile details according to the OHIP card")
  //                               setErrorMsg(
  //                                 "Your account profile details should match with the OHIP card details"
  //                               );
  //                               setShowError(true);
  //                               setBtnLoader(false);
  //                             } else {
  //                               const Ohip = {
  //                                 name: event.fname + " " + event.lname,
  //                                 number: ohipNumber,
  //                                 provider: "NA",
  //                                 type: insuranceType,
  //                                 vc: event.insuranceVersionCode,
  //                                 user_id: userData.id,
  //                                 DOB: moment(dateOfBirth).format("YYYY/MM/DD"),
  //                                 is_valid: 1,
  //                               };
  //                               console.log(Ohip, "Ohip");
  //                               try {
  //                                 const res = await POST(
  //                                   "user/insurance",
  //                                   Ohip
  //                                 );
  //                                 console.log(res, "family insurances------0 ");
  //                                 setDisableAddNew(false);
  //                                 notification.success({
  //                                   message: "OHIP details added succesfully",
  //                                 });
  //                                 getInsuranceInfo();
  //                                 setBtnLoader(false);
  //                                 setIsModalVisible(false);
  //                                 form.resetFields();
  //                               } catch (err) {
  //                                 console.log("error", err);
  //                               }
  //                             }
  //                           }
  //                         }
  //                       }
  //                     }
  //                   }
  //                 }
  //               }
  //             },
  //             (err) => {
  //               setBtnLoader(false);
  //               console.log(err);
  //             }
  //           );
  //       }
  //     }
  //   } else {
  //     setBtnLoader(true);
  //     console.log(insuranceData);
  //     console.log(ohipNumber);
  //     const result = !!insuranceData.find(
  //       (item) => item.insurance_number == ohipNumber
  //     );
  //     console.log(`resFind(search1): ${result}`);
  //     if (result) {
  //       notification.warning({
  //         message: "Insurance already added !",
  //       });
  //       setBtnLoader(false);
  //     } else {
  //       const Private = {
  //         name: event?.name,
  //         number: ohipNumber,
  //         provider: event?.provider,
  //         type: insuranceType,
  //         vc: "NA",
  //         user_id: userData.id,
  //         is_valid: 0,
  //       };
  //       console.log(Private, "data Private");

  //       try {
  //         setBtnLoader(true);
  //         const res = await POST("user/insurance", Private);
  //         console.log(res, "family insurances------0 ");
  //         notification.success({
  //           message: "Insurance details added succesfully",
  //         });
  //         setBtnLoader(false);
  //         getInsuranceInfo();
  //         form.resetFields();
  //       } catch (err) {
  //         setBtnLoader(false);
  //         console.log("error", err);
  //       }
  //       setIsModalVisible(false);
  //       setBtnLoader(false);
  //     }
  //   }
  // };

  const addOhipCardDetail = async (idetail, event, fromm) => {
    let ohipNumber = event.Insurancesvcnum.replaceAll("-", "");
    console.log(ohipNumber, "ohipnumberohipnumber");
    let data = {
      // 'Provider-number': formValues.Insurancesvcnum,
      "Provider-number": "020497",
      HCN: ohipNumber,
      VC: event.insuranceVersionCode,
      // User: formValues.FirstName || userData.FirstName,
      User: event.FirstName.trim() + " " + event.LastName.trim(),
    };
    console.log(data, "check Api  Data");
    setLoader(true);
    await axios
      .post("https://api.mdmax.ca/api/1.1/wf/api-validation-call", data, {
        headers: {
          Authorization: `Bearer cee53e7aa78674f078e300e6d96d98ec`,
          // Authorization: `Bearer 894da2b4b1760319ae94cbfa73db5a10`,
        },
      })
      .then(
        async (response: any) => {
          console.log(response, "responce");
          // let D = moment(dateOfBirth).format("YYYY-MM-DD");
          let D = moment(startDate).format("YYYY-MM-DD");

          if (response?.data?.response["MOH-card-eligible"] == false) {
            setLoader(false);
            setErrorMsg("Invalid OHIP Card");
            setShowError(true);
            setLoader(false);
          } else {
            if (
              response?.data?.response["MOH-Message"] == "Invalid version code"
            ) {
              setLoader(false);
              setErrorMsg("Invalid version code");
              setShowError(true);
              setLoader(false);
            } else {
              if (response?.data?.response["MOH-card-status"] == "invalid") {
                setLoader(false);
                setErrorMsg("Invalid or Expired health card");
                setShowError(true);
                setLoader(false);
              } else {
                console.log(D, "DDDDD");
                if (response?.data?.response.DOB !== D) {
                  setLoader(false);
                  setErrorMsg("Date of birth should match with OHIP");
                  setShowError(true);
                  setLoader(false);
                } else {
                  if (
                    response?.data?.response["First-name"].toLowerCase() !==
                    event.FirstName.trim().toLowerCase()
                  ) {
                    setLoader(false);
                    setErrorMsg("First name should match with OHIP");
                    setShowError(true);
                    setLoader(false);
                  } else {
                    if (
                      response?.data?.response["Last-name"].toLowerCase() !==
                      event.LastName.toLowerCase()
                    ) {
                      setLoader(false);
                      setErrorMsg("Last name should match with OHIP");
                      setShowError(true);
                      setLoader(false);
                    } else {
                      if (fromm == "fromDelete") {
                        try {
                          DELETE(`user/insurance/delete/${idetail?.id}`).then(
                            () => {
                              finalAddOhip(event, ohipNumber);
                            }
                          );
                        } catch (error) {
                          console.log(error);
                        }
                      } else {
                        finalAddOhip(event, ohipNumber);
                      }
                    }
                  }
                }
              }
            }
          }
        },
        (err) => {
          setBtnLoader(false);
          console.log(err);
        }
      );
  };

  const finalAddOhip = async (event, ohipNumber) => {
    const Ohip = {
      name: event.FirstName.trim() + " " + event.LastName.trim(),
      number: ohipNumber,
      provider: "NA",
      type: insuranceType,
      vc: event.insuranceVersionCode,
      user_id: userData.id,
      DOB: moment(startDate).format("YYYY/MM/DD"),
      is_valid: 1,
    };
    console.log(Ohip, "Ohip");
    try {
      const res = await POST("user/insurance", Ohip);
      console.log(res, "family insurances------0 ");
      setDisableAddNew(false);

      try {
        setLoader(true);
        PUT(`user/patient/profile/${userData.cognitoid}`, {
          ...event,
          // medconcent : event?.medconcent == true ? 1 : 0,
          name: event?.FirstName.trim() + " " + event?.LastName.trim(),
          DOB: startDate,
          phoneNumber: phoneNumber,
          Address1: address,
          // profilepicture:
          //   userProfileKey == null ? userData.profilepicture : userProfileKey,
        }).then(() => {
          setLoader(false);
          getUserDetails();

          if (selectedFamilyMemberID) {
            const found = allDoctor.find(
              (element) => element.companyemployeeid == selectedFamilyMemberID
            );
            console.log(found, "-*-*-*-**");
            dispatch(
              setFamilyDoctor(`Dr. ${found?.FirstName} ${found?.LastName}`)
            );
          }
          dispatch(setProfileComplate("true"));
          updateOrCreateFamilyMember();
          notification.success({
            message: "Your Data Successfully Added",
          });
          setLoader(false);
          setDisableAddNew(false);
          history.push("/view-profile");
          getUserDetails();
          dispatch(setProfileComplate("true"));
        });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }

      // notification.success({
      //   message: "OHIP details added succesfully",
      // });
      getInsuranceInfo();
      setBtnLoader(false);
      setIsModalVisible(false);
      form.resetFields();
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleSubmitModal = async (event: any) => {
    console.log("Success:", event);
    setLoader(true);
    let ohipNumber = event.Insurancesvcnum.replaceAll("-", "");
    console.log(ohipNumber, "ohipnumberohipnumber");
    if (insuranceType == "OHIP") {
      const result = !!insuranceData.find(
        (item) => item.insurance_number === ohipNumber
      );
      const OhipDuplicate = !!insuranceData.find(
        (item) => item.insurance_type === "OHIP"
      );
      if (OhipDuplicate) {
        console.log(insuranceData, "jhkk");

        if (insuranceData?.length != 0) {
          confirm(insuranceData[0], event);
        }

        // notification.warning({
        //   message: "You can add only one OHIP !",
        // });
        // setLoader(false);
      } else {
        addOhipCardDetail(null, event, "fromDirect");
      }
    } else {
      setLoader(true);
      console.log(insuranceData);
      console.log(ohipNumber);
      const result = !!insuranceData.find(
        (item) => item.insurance_number == ohipNumber
      );
      console.log(`resFind(search1): ${result}`);
      if (result) {
        notification.warning({
          message: "Insurance already added !",
        });
        setLoader(false);
      } else {
        const Private = {
          name: event?.name,
          number: ohipNumber,
          provider: event?.provider,
          type: insuranceType,
          vc: "NA",
          user_id: userData.id,
          is_valid: 0,
        };
        console.log(Private, "data Private");

        try {
          setLoader(true);
          const res = await POST("user/insurance", Private);
          console.log(res, "family insurances------0 ");
          notification.success({
            message: "Insurance details added succesfully",
          });
          setLoader(false);
          getInsuranceInfo();
          form.resetFields();
        } catch (err) {
          setLoader(false);
          console.log("error", err);
        }
        setIsModalVisible(false);
        setLoader(false);
      }
    }
  };

  function onChangeCountry(value) {
    console.log(`selected ${value}`);
    console.log(CountrysJson);

    var obj = countryJson?.countries?.filter((obj) => obj.country == value);
    console.log(obj[0]);
    setSelectCountry(obj[0]);
  }

  function onSearchCountry(val) {
    console.log("search:", val);
  }

  function onChangeCity(value) {
    console.log(`selected ${value}`);
  }

  function onSearchCity(val) {
    console.log("search:", val);
  }

  function onDateChange(date, dateString) {
    // console.log(date, dateString);
    console.log(moment(date).format("YYYY-MM-DD"));
    console.log(moment(userData?.DOB).format("YYYY-MM-DD"));
    setStartDate(date);
    if (
      moment(date).format("YYYY-MM-DD") !==
      moment(userData?.DOB).format("YYYY-MM-DD")
    ) {
      console.log("in if");
      setDisableAddNew(true);
    } else {
      console.log("in else");
      setDisableAddNew(false);
    }
  }

  const getUserImage = (imageUrl) => {
    if (
      imageUrl?.match(/^http[^\?]*.(jpg|jpeg|gif|png)(\?(.*))?$/gim) != null
    ) {
      return imageUrl;
    }
    return `${process.env.REACT_APP_ASSET_URL}/${imageUrl}`;
  };

  const getUserImageDoc = (imageUrll) => {
    if (
      imageUrll?.match(
        /^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim
      ) != null
    ) {
      return imageUrll;
    }
    return `${process.env.REACT_APP_ASSET_URL}/${imageUrll}`;
  };

  const userProffile = async (event) => {
    const currentFile = event.target.files[0];
    const filename = currentFile?.name;
    const fileExtension = filename?.substring(
      filename.lastIndexOf("."),
      filename.length
    );
    const profilePicFileName = `${uuidv4()}${fileExtension}`;
    setImgLoader(true);
    if (currentFile.size >= 2097152) {
      notification.error({
        message: "Please upload 2MB size photo",
      });

      setImgLoader(false);
    } else {
      const uplodadedImageKey = await s3Upload(profilePicFileName, currentFile);
      setImgLoader(false);
      console.log(uplodadedImageKey, "uplodadedImageKey");
      setUserProfileKey(uplodadedImageKey);
      setprofilePath(URL.createObjectURL(event.target.files[0]));
    }
  };

  const removeProfile = async (event) => {
    console.log(event, "eventetetetette form");
    // try {
    //   setLoader(true);
    //   PUT(`user/patient/profile/${userData.cognitoid}`,
    //     {
    //       profilepicture: null,
    //     }).then(() => {
    //       // notification.success({
    //       //     message: 'Your Data Successfully Added',
    //       // })
    //       setLoader(false);
    //       // history.push("/view-profile");
    //       // getUserDetails();

    //       dispatch(setProfileComplate("true"));
    //       // updateOrCreateFamilyMember();
    //       notification.success({
    //         message: "Your Profile picture removed Successfully",
    //       });
    //       setLoader(false);
    //       // history.push("/view-profile");
    //       getUserDetails();
    //       dispatch(setProfileComplate("true"));
    //     });
    // } catch (error) {
    //   setLoader(false);
    //   console.log(error);
    // }

    // console.log("remove image");
  };

  const getDateFormated = (date) => {
    if (date) {
      return date.replace("/", "-").replace("/", "-");
    } else {
      return date;
    }
  };

  const addressSelect = (datee) => {
    console.log(datee, "eeeeeeee");
    setAddress(datee.formatted_address);

    if (datee && datee.address_components) {
      const city = find(datee.address_components, {
        types: ["locality"],
      });

      console.log(city.long_name, "city");
      if (city) {
        form.setFieldsValue({
          City: city?.long_name,
        });
      }
      const state = find(datee.address_components, {
        types: ["administrative_area_level_1"],
      });

      console.log(state?.long_name, "state");
      if (state) {
        form.setFieldsValue({
          State: state.long_name,
        });
      }
      const postal_code = find(datee.address_components, {
        types: ["postal_code"],
      });
      console.log(postal_code?.long_name, "postal_code");

      if (postal_code) {
        form.setFieldsValue({
          PostalCode: postal_code.long_name,
        });
      }
    }
  };

  return (
    <div className="pb-5">
      <TopBar />
      <div className="box">
        <div className={`${style.toggebtn} d-block d-xl-none`}>
          <i>
            <FontAwesomeIcon
              className="mr-1"
              icon={faEquals}
              onClick={showDrawer}
            />
          </i>
        </div>
        <div className="d-block d-xl-none">
          <p className="pageInfoM"> Profile</p>
          <h5 className="h5NewM">Dashboard / Profile</h5>
        </div>
        <div className="d-none d-xl-block">
          <p className="pageInfo"> Profile</p>
          <h5 className="h5New">
            <Link style={{ color: "white" }} to="/dashboard">
              Dashboard
            </Link>{" "}
            / Profile
          </h5>
        </div>
      </div>
      <div>
        <div
          className="content"
          style={{ backgroundColor: "#f1f5f9", paddingTop: 15 }}
        >
          <div className={style.container}>
            <div className="row pb-5 ">
              <div
                className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar pr-0`}
              >
                <DashboardSidebar />
              </div>
              <Drawer
                closable={false}
                width={300}
                placement="left"
                onClose={onClose}
                visible={visible}
                style={{ paddingTop: 0 }}
              >
                <div className="widget-profile pro-widget-content pt-0 pb-4">
                  <div className="profile-info-widget">
                    <img src="../Images/logo.png" height="50" alt="" />
                  </div>
                </div>
                <DashboardSidebar />
              </Drawer>

              {/* {sidebar ?
                                <div className={`d-block d-xl-none ${style.toggle}`}>
                                    <DashboardSidebar />
                                </div> : null
                            } */}
              <div className="col-lg-12 col-xl-9">
                <div className="card">
                  <div className="card-body">
                    <Form
                      // autoComplete="off"
                      form={form}
                      onFinish={handleSubmit}
                      layout="vertical"
                      initialValues={{
                        FirstName: `${
                          userData?.FirstName == null ? "" : userData?.FirstName?.charAt(0).toUpperCase() + userData?.FirstName?.slice(1)
                        }`,
                        MiddleName: `${
                          userData?.MiddleName == null
                            ? ""
                            : userData?.MiddleName?.charAt(0).toUpperCase() + userData?.MiddleName?.slice(1)
                        }`,
                        LastName: `${
                          userData?.LastName == null ? "" : userData?.LastName?.charAt(0).toUpperCase() + userData?.LastName?.slice(1)
                        }`,
                        Email: `${
                          userData?.Email == null ? "" : userData?.Email
                        }`,
                        phoneNumber: `${
                          userData?.phoneNumber == null
                            ? ""
                            : userData?.phoneNumber
                        }`,
                        Address1: `${
                          userData?.Address1 == null ? "" : userData?.Address1
                        }`,
                        City: `${userData?.City == null ? "" : userData?.City}`,
                        State: `${
                          userData?.State == null ? "" : userData?.State
                        }`,
                        PostalCode: `${
                          userData?.PostalCode == null
                            ? ""
                            : userData?.PostalCode
                        }`,
                        medconcent : `${
                          userData?.medconcent === 1
                            ? true
                            : false
                        }`,

                        // Country: `${userData?.Country == null ? config?.country : userData?.Country}`,
                        Country: `${config?.country}`,
                        Languages: `${
                          userData?.Languages == null ? "" : userData?.Languages
                        }`,
                        gender: `${
                          userData?.gender == null ? "" : userData?.gender
                        }`,
                        // DOB: `${userData?.DOB == moment() ? "" : moment(userData?.DOB)}`,
                        DOB: userData?.DOB ? moment(userData?.DOB) : null,
                        familyDoctor: `${familyD == null ? "" : familyD}`,
                        // familyDoctor: familyDoctorDetails == "" ? "" : familyDoctorDetails,
                      }}
                      validateMessages={validateMessages}
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-xl-6 ">
                          <div className="change-avatar pb-2">
                            {/* 03A */}
                            {/* <div className="profile-img">
                              {imgLoader ? (
                                <div className={style.spinDiv}>
                                  <Spin />
                                </div>
                              ) : (
                                <>
                                  {ProfilePath ? (
                                    <img src={ProfilePath} alt="" />
                                  ) : (
                                    <div
                                      style={{
                                        width: 100,
                                        height: 100,
                                        position: "relative",
                                      }}
                                    >
                                      <img
                                        src={
                                          userData?.profilepicture == null
                                            ? "https://www.pngarts.com/files/6/User-Avatar-in-Suit-PNG.png"
                                            : getUserImage(
                                                userData?.profilepicture
                                              )
                                        }
                                        alt="User"
                                        style={{ borderRadius: "100%" }}
                                      />
                                      {userData?.profilepicture != null ? (
                                        <img
                                          src="../Images/close.png"
                                          onClick={removeProfile}
                                          style={{
                                            height: 20,
                                            width: 20,
                                            position: "absolute",
                                            top: 0,
                                            right: 10,
                                            cursor: "pointer",
                                          }}
                                          alt=""
                                        />
                                      ) : null} */}
                                      {/* <img src="../Images/close.png" onClick={removeProfile} style={{
                                          height: 20,
                                          width: 20,
                                          position: 'absolute',
                                          top: 0,
                                          right: 10,
                                          cursor: 'pointer'
                                        }} alt="" /> */}
                                    {/* </div>
                                  )}
                                </>
                              )}
                            </div> */}
                            {/* <div className="upload-img">
                              <div className="change-photo-btn">
                                <span>
                                  <FontAwesomeIcon
                                    className="mr-1"
                                    icon={faUpload}
                                  />{" "}
                                  Upload Photo
                                </span>
                                <input
                                  type="file"
                                  className="upload"
                                  onChange={userProffile}
                                  accept="image/png, image/gif, image/jpeg"
                                />
                              </div>
                              <small className="form-text text-muted">
                                Allowed JPG, GIF or PNG. Max size of 2MB
                              </small>
                            </div> */}
                          </div>
                          <div className={style.personHeading}>
                            <h4>Personal Info</h4>
                          </div>
                          <hr></hr>
                          <div className="row" style={{ marginTop: 15 }}>
                            <div className="col-6 ">
                              <div className={`form-group `}>
                                {/* {isInsurance} */}
                                <Form.Item
                                  label="First Name"
                                  name="FirstName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter First Name!",
                                    },
                                  ]}
                                >
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className={`${style.inputbox}`}
                                    name="FirstName"
                                    onChange={handleChange}
                                    // disabled={!isInsurance}
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="form-group">
                                <Form.Item
                                  label="Middle Name"
                                  name="MiddleName"
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Please Enter Last Name!",
                                  //   },
                                  // ]}
                                >
                                  <input
                                    type="text"
                                    className={`${style.inputbox}`}
                                    name="MiddleName"
                                    onChange={handleChange}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <Form.Item
                                  label="Last Name"
                                  name="LastName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter Last Name!",
                                    },
                                  ]}
                                >
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className={`${style.inputbox}`}
                                    name="LastName"
                                    onChange={handleChange}
                                    // disabled={!isInsurance}
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className={`form-group `}>
                                <Form.Item
                                  name="Email"
                                  label="Email"
                                  rules={[{ required: false, type: "email" }]}
                                >
                                  <input
                                    disabled={true}
                                    autoComplete="off"
                                    type="text"
                                    className={`${style.inputbox}`}
                                    name="Email"
                                    onChange={handleChange}
                                    style={{
                                      cursor: "not-allowed",
                                      backgroundColor: "#f5f5f5",
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`form-group `}
                            style={{ width: "100%" }}
                          >
                            <Form.Item
                              name="phoneNumber"
                              label="Phone Number"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter phone number!",
                                },
                                {
                                  max: 12,
                                  message: "must be maximum 12 characters.",
                                },
                              ]}
                            >
                              {/* <PhoneInput
                                country={config?.code}
                                value={formValues.phoneNumber || ""}
                                onChange={(phone) => setPhoneNumber(phone)}
                              /> */}

                              {/* {config?.code == 'ca' ?

                                <label style={{ position: "absolute", top: 11, left: 15 }}>+1</label>
                                :
                                <label style={{ position: "absolute", top: 11, left: 15 }}>+91</label>
                              } */}

                              {/* <input type="text" className={`${style.inputbox}`} name="phoneNumber"
                                value={phoneNumber || ""} maxLength={12}
                                onChange={handleChangePhonenumber}  /> */}

                              <MaskInput
                                alwaysShowMask
                                maskChar="_"
                                onChange={handleChangePhonenumber}
                                mask="000-000-0000"
                              />
                            </Form.Item>
                          </div>

                          <div className="form-group">
                            <Form.Item
                              name="DOB"
                              label="Date of Birth"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Date Of Birth",
                                },
                              ]}
                            >
                              <DatePicker
                                size="large"
                                style={{ width: "100%" }}
                                format="YYYY/MM/DD"
                                onChange={onDateChange}
                                disabledDate={(current) => {
                                  return (
                                    current && current > moment().endOf("day")
                                  );
                                  // return moment().add(-1, "days") <= current;
                                }}
                                // disabled={!isInsurance}
                              />
                              {/* <DatePicker autocomplete="off" dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                            </Form.Item>
                          </div>
                          <div className="form-group pb-0 mb-0">
                            <div className="row">
                              <div className="col-sm-6">
                                <Form.Item
                                  name="Languages"
                                  label="Language"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select your Language !",
                                    },
                                  ]}
                                >
                                  <select
                                    placeholder="Select Language"
                                    className={`select ${style.select}`}
                                    name="Languages"
                                    value={formValues.Languages || ""}
                                    onChange={handleChange}
                                  >
                                    <option value="" disabled selected>
                                      Select Languages
                                    </option>
                                    <option value="english">English</option>
                                    <option value="hindi">French</option>
                                    <option value="hindi">Spanish</option>
                                    <option value="hindi">Hindi</option>
                                    <option value="tamil">Tamil</option>
                                    <option value="telugu">Telugu</option>
                                    <option value="gujarati">Gujarati</option>
                                    <option value="marathi">Marathi</option>
                                    <option value="bengali">Bengali</option>
                                    <option value="hindi">Other</option>
                                  </select>
                                </Form.Item>
                              </div>
                              <div className="col-sm-6">
                                <Form.Item
                                  name="gender"
                                  label="Gender"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select Gender! !",
                                    },
                                  ]}
                                >
                                  <select
                                    placeholder="Select gender"
                                    className={`select ${style.select}`}
                                    name="gender"
                                    value={formValues.gender || ""}
                                    onChange={handleChange}
                                  >
                                    <option value="" disabled selected>
                                      Select Gender
                                    </option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                  </select>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          {/* <div className="form-group">
                            <Form.Item
                              name="familyDoctor"
                              label="Family Doctor"
                            >
                              <Select
                                showSearch
                                placeholder="Select Service"
                                className={style.selectMember}
                                optionFilterProp="children"
                                size="large"
                                style={{ width: "100%" }}
                                onChange={handleChangeMemeber}
                              >
                                {allDoctor?.map((itemm, index) => (
                                  <>
                                    {itemm.FirstName != null ? (
                                      <Option
                                        key={index}
                                        value={itemm?.companyemployeeid}
                                      >
                                        <img
                                          className={style.bgImg}
                                          src={getUserImageDoc(
                                            itemm?.profilepicture
                                          )}
                                          onError={(e: any) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                              {IMG01};
                                          }}
                                          alt=""
                                          height="20"
                                          width="20"
                                        />
                                        Dr. {itemm?.FirstName} {itemm?.LastName}
                                        <p className="p-0 m-0 pl-5">
                                          {itemm?.ClinicAddress}
                                        </p>
                                      </Option>
                                    ) : null}
                                  </>
                                ))}
                              </Select>
                            </Form.Item>
                          </div> */}
                          <div className="form-group">
                          {/* 03A */}
                          {/* <Form.Item
                              name="medconcent"
                              label="Allow medical history for doctors"
                              >
                            <Switch
                              defaultChecked={userData.medconcent}
                              style={{ textAlign: "right" }}
                            />
                            </Form.Item> */}
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-6">
                          <div className={style.addressHeading}>
                            <h4>Address Info</h4>
                          </div>
                          <hr></hr>
                          <div className="col-sm-12">
                            <label>
                              {" "}
                              <span className="text-danger">*</span> Address
                            </label>
                            <Form.Item>
                              <SearchLocationInput
                                styleProps={{ minHeight: 30 }}
                                name="address"
                                address={address}
                                setlat={(e) => setLat(e)}
                                setlng={(e) => setLng(e)}
                                // setAddress={(e) => setAddress(e)}
                                setAddress={(e) => addressSelect(e)}
                                onBlur={(e) => setAddress(e)}
                              />
                              {address == null ? (
                                <span style={{ color: "#ff0000" }}>
                                  Please Select your Address!
                                </span>
                              ) : null}
                            </Form.Item>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div className={`form-group `}>
                                <Form.Item
                                  name="Country"
                                  label="Country"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select your Country!",
                                    },
                                  ]}
                                >
                                  {/* <input type="text" className={`${style.inputbox}`} name="Country" onChange={handleChange} /> */}
                                  <Select
                                    disabled
                                    size="large"
                                    showSearch
                                    placeholder="Select a person"
                                    optionFilterProp="children"
                                    onChange={onChangeCountry}
                                    onSearch={onSearchCountry}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {CountrysJson?.countries.map((element) => {
                                      return (
                                        <Option value={element?.country}>
                                          {element?.country}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className={`form-group `}>
                                <Form.Item
                                  name="State"
                                  label="State/Province"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please Select your State/Province!",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    size="large"
                                    placeholder="Select a person"
                                    optionFilterProp="children"
                                    onChange={onChangeCity}
                                    onSearch={onSearchCity}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {selectCountry?.states?.map((element) => {
                                      return (
                                        <Option value={element}>
                                          {element}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <Form.Item
                                  name="PostalCode"
                                  label="Zip Code"
                                  rules={[
                                    {
                                      required: true,
                                      message: "PostalCode is not valid!",
                                      // pattern: new RegExp(validPostCode),
                                    },
                                  ]}
                                >
                                  <input
                                    type="text"
                                    className={`${style.inputbox}`}
                                    name="PostalCode"
                                    onChange={handleChange}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className={`form-group `}>
                                <Form.Item
                                  name="City"
                                  label="City"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter your City!",
                                    },
                                  ]}
                                >
                                  <input
                                    type="text"
                                    className={`${style.inputbox}`}
                                    onChange={handleChange}
                                  />
                                  {/* <input type="text" className={`${style.inputbox}`} name="City" onChange={handleChange} /> */}
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div>
                            <label className={style.inputLable}>
                              Insurance Type
                            </label>
                            <Select
                              defaultValue="OHIP"
                              size="large"
                              style={{ width: "100%" }}
                              onChange={handleChangeSelect}
                            >
                              {/* <Option value="PRIVATE_INSURANCE">Private insurance</Option> */}
                              <Option value="OHIP">OHIP</Option>
                            </Select>

                            <div className="row" style={{ marginTop: 20 }}>
                              {insuranceType == "OHIP" ? (
                                <>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <Form.Item
                                        label="Ohip Number"
                                        name="Insurancesvcnum"
                                        rules={[
                                          {
                                            required: false,
                                            message:
                                              "Please Enter Insurance Number!",
                                          },
                                          {
                                            max: 12,
                                            message:
                                              "must be maximum 12 characters.",
                                          },
                                        ]}
                                      >
                                        <MaskInput
                                          alwaysShowMask
                                          maskChar="_"
                                          onChange={handleChange}
                                          mask="0000-000-000"
                                        />
                                      </Form.Item>

                                      {/* <label className={style.inputLable}>Insurance Number *</label>
                                                <Input size="large" autoComplete="off" maxLength={10} type="text" className={`${style.inputbox}`} name="Insurancesvcnum" defaultValue={formValues.Insurancesvcnum || ""} onChange={handleChange} /> */}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <Form.Item
                                        label="Version Code"
                                        name="insuranceVersionCode"
                                        rules={[
                                          {
                                            required: false,
                                            message:
                                              "Please Enter Version code!",
                                          },
                                        ]}
                                      >
                                        <Input
                                          style={{textTransform : "uppercase"}}
                                          size="large"
                                          onChange={handleChange}
                                          name="insuranceVersionCode"
                                          maxLength={2}
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please Enter Name!",
                                          },
                                        ]}
                                      >
                                        <Input
                                          size="large"
                                          onChange={handleChange}
                                        />
                                      </Form.Item>

                                      {/* <label className={style.inputLable}>Name *</label>
                                                            <Input size="large" autoComplete="off" type="text" className={`${style.inputbox}`} name="name" onChange={handleChange} /> */}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <Form.Item
                                        label="Insurance Number"
                                        name="Insurancesvcnum"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please Enter Insurance Number!",
                                          },
                                          {
                                            max: 10,
                                            message:
                                              "must be maximum 10 characters.",
                                          },
                                        ]}
                                      >
                                        <Input
                                          max={10}
                                          size="large"
                                          onChange={handleChange}
                                        />
                                      </Form.Item>

                                      {/* <label className={style.inputLable}>Insurance Number *</label>
                                                <Input size="large" autoComplete="off" maxLength={10} type="text" className={`${style.inputbox}`} name="Insurancesvcnum" defaultValue={formValues.Insurancesvcnum || ""} onChange={handleChange} /> */}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <Form.Item
                                        label="Provider"
                                        name="provider"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please Enter Provider!",
                                          },
                                        ]}
                                      >
                                        <Input
                                          size="large"
                                          onChange={handleChange}
                                        />
                                      </Form.Item>
                                      {/* <label className={style.inputLable}>Provider *  </label>
                                                            <Input size="large" autoComplete="off" type="text" className={`${style.inputbox}`} name="provider" onChange={handleChange} /> */}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            {showError ? (
                              <Alert
                                style={{ marginBottom: "15px", width: "70%" }}
                                message={errorMsg}
                                type="error"
                              />
                            ) : null}
                            <div className={style.div_row_flex}>
                              <div
                                className={style.div_card_main}
                                style={{
                                  backgroundImage: `url("Images/problems/card.png")`,
                                }}
                              >
                                <div>
                                  <div
                                    className={style.div_card_below}
                                    style={{ paddingTop: 0 }}
                                  >
                                    <div style={{ width: "100%" }}>
                                      <label className={style.lbl_card}>
                                        
                                      </label>
                                      <div className={style.div_row_name}>
                                        <label className={style.lbl_card_name}>
                                          {formValues?.FirstName?.charAt(0).toUpperCase() + formValues?.FirstName?.slice(1) ||
                                            userData?.FirstName?.charAt(0).toUpperCase() + userData?.FirstName?.slice(1)}{" "}
                                          &nbsp;
                                        </label>
                                        <label className={style.lbl_card_name}>
                                          {formValues?.MiddleName != null && formValues?.MiddleName ? formValues?.MiddleName.charAt(0).toUpperCase() + formValues?.MiddleName?.slice(1) : '' ||
                                            userData?.MiddleName != null && userData?.MiddleName? userData?.MiddleName?.charAt(0).toUpperCase() + userData?.MiddleName?.slice(1) : ''}{" "}
                                          &nbsp;
                                        </label>
                                        <label className={style.lbl_card_name}>
                                          {formValues?.LastName?.charAt(0).toUpperCase() + formValues?.LastName?.slice(1) ||
                                            userData?.LastName?.charAt(0).toUpperCase() + userData?.LastName?.slice(1)}
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className={style.div_card_below}>
                                    <div style={{ width: "67%" }}>
                                      <label className={style.lbl_card}>
                                        Card Number
                                      </label>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="Insurancesvcnum"
                                      >
                                        <Input
                                          size="large"
                                          onChange={handleChange}
                                          className={style.input_card}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                    </div>
                                    <div style={{ width: "25%" }}>
                                      <label className={style.lbl_card}>
                                        Version Code
                                      </label>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="insuranceVersionCode"
                                      >
                                        <Input
                                        style={{textTransform : "uppercase"}}
                                          size="large"
                                          onChange={handleChange}
                                          className={style.input_card_vcode}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>

                                  <div
                                    className={style.div_card_below}
                                    style={{ paddingTop: 0 }}
                                  >
                                    <div style={{ width: "100%" }}>
                                      <label className={style.lbl_card}>
                                        DOB
                                      </label>
                                      <div className={style.div_row_name}>
                                        <label className={style.lbl_card_name}>
                                          {/* {getDateFormated(dateOfBirth)} */}
                                          {moment(startDate).format(
                                            "YYYY-MM-DD"
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div className={style.insureHeading}>
                            <h4 className="pt-3">Insurance Info</h4>
                            <button
                              onClick={showModal}
                              type="button"
                              disabled={disableAddNew}
                              className="ant-btn ant-btn-dashed mx-4 "
                            >
                              <span>
                                {" "}
                                <FontAwesomeIcon
                                  className="mr-1 pr-1"
                                  icon={faPlus}
                                />
                                Add New
                              </span>
                            </button>
                          </div> */}
                          <hr></hr>
                          <div className={style.div_btn}>
                            {loader ? (
                              <div className="spinner">
                                {/* <Spin tip="Loading..."></Spin> */}
                                <Spin tip="Validating Health Card (OHIP Validation in progress) ..."></Spin>
                              </div>
                            ) : (
                              <button type="submit" className="btn">
                                Update
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            {/* modal */}
            {/* <Modal
              footer={null}
              title="Insurance Info"
              visible={isModalVisible}
              onCancel={handleCancel}
              width={1100}
            >
              {showError ? (
                <Alert
                  style={{ marginBottom: "15px", width: "70%" }}
                  message={errorMsg}
                  type="error"
                />
              ) : null}
              <Form form={form} onFinish={handleSubmitModal} layout="vertical">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className={style.inputLable}>Insurance Type *</label>
                    <Select
                      defaultValue="OHIP"
                      size="large"
                      style={{ width: "100%" }}
                      onChange={handleChangeSelect}
                    >
                      <Option value="OHIP">OHIP</Option>
                    </Select>

                    <div className="row" style={{ marginTop: 20 }}>
                      {insuranceType == "OHIP" ? (
                        <>
                          <div className="col-6">
                            <div className="form-group">
                              <Form.Item
                                label="First Name"
                                name="fname"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter First Name!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  onChange={handleChange}
                                  name="fname"
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <Form.Item
                                label="Middle Name"
                                name="middlename"
                              >
                                <Input
                                  size="large"
                                  name="middlename"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <Form.Item
                                label="Last Name"
                                name="lname"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Last Name!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  onChange={handleChange}
                                  name="lname"
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <Form.Item
                              label="Date Of Birth"
                              name="birth_date"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Date Of Birth",
                                },
                              ]}
                            >
                              <DatePicker
                                size="large"
                                style={{ width: "100%" }}
                                name="birth_date"
                                onChange={onChangeDateOfBirth}
                                format="YYYY/MM/DD"
                                disabledDate={(current) => {
                                  return (
                                    current && current > moment().endOf("day")
                                  );
                                }}
                              />
                            </Form.Item>
                          </div>

                          <div className="col-6">
                            <div className="form-group">
                              <Form.Item
                                label="Ohip Number"
                                name="Insurancesvcnum"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Insurance Number!",
                                  },
                                  {
                                    max: 12,
                                    message: "must be maximum 12 characters.",
                                  },
                                ]}
                              >
                                <MaskInput
                                  alwaysShowMask
                                  maskChar="_"
                                  onChange={handleChange}
                                  mask="0000-000-000"
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <Form.Item
                                label="Version Code"
                                name="insuranceVersionCode"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Version code!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  onChange={handleChange}
                                  name="insuranceVersionCode"
                                  maxLength={2}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-6">
                            <div className="form-group">
                              <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Name!",
                                  },
                                ]}
                              >
                                <Input size="large" onChange={handleChange} />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <Form.Item
                                label="Insurance Number"
                                name="Insurancesvcnum"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Insurance Number!",
                                  },
                                  {
                                    max: 10,
                                    message: "must be maximum 10 characters.",
                                  },
                                ]}
                              >
                                <Input
                                  max={10}
                                  size="large"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <Form.Item
                                label="Provider"
                                name="provider"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Provider!",
                                  },
                                ]}
                              >
                                <Input size="large" onChange={handleChange} />
                              </Form.Item>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className={style.div_row_flex}>
                      <div
                        className={style.div_card_main}
                        style={{
                          backgroundImage: `url("Images/problems/card.png")`,
                        }}
                      >
                        <div>
                          <div
                            className={style.div_card_below}
                            style={{ paddingTop: 0 }}
                          >
                            <div style={{ width: "100%" }}>
                              <label className={style.lbl_card}>Name</label>
                              <div className={style.div_row_name}>
                                <label className={style.lbl_card_name}>
                                  {formValues.fname} &nbsp;
                                </label>
                                <label className={style.lbl_card_name}>
                                  {formValues.middlename} &nbsp;
                                </label>
                                <label className={style.lbl_card_name}>
                                  {formValues.lname}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className={style.div_card_below}>
                            <div style={{ width: "67%" }}>
                              <label className={style.lbl_card}>
                                Card Number
                              </label>
                              <Form.Item
                                style={{ marginBottom: "5px" }}
                                name="Insurancesvcnum"
                              >
                                <Input
                                  size="large"
                                  onChange={handleChange}
                                  className={style.input_card}
                                  disabled={true}
                                />
                              </Form.Item>
                            </div>
                            <div style={{ width: "25%" }}>
                              <label className={style.lbl_card}>
                                Version Code
                              </label>
                              <Form.Item
                                style={{ marginBottom: "5px" }}
                                name="insuranceVersionCode"
                              >
                                <Input
                                  size="large"
                                  onChange={handleChange}
                                  className={style.input_card_vcode}
                                  disabled={true}
                                />
                              </Form.Item>
                            </div>
                          </div>

                          <div
                            className={style.div_card_below}
                            style={{ paddingTop: 0 }}
                          >
                            <div style={{ width: "100%" }}>
                              <label className={style.lbl_card}>DOB</label>
                              <div className={style.div_row_name}>
                                <label className={style.lbl_card_name}>
                                  {getDateFormated(dateOfBirth)}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    type="primary"
                    loading={btnLoader}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Modal> */}
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Profile;
