
import { TopBar } from "components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEquals, faPaperclip, faSearch, faShare } from '@fortawesome/free-solid-svg-icons'
import { IMG01, IMG02, IMG03, IMG04, IMG05, IMG06, IMG011, IMG07, IMG08, IMG09,RINGER } from './img';
import style from './style.module.scss'
import { Drawer } from "antd";
import DashboardSidebar from "../sidebar/sidebar";
import { useEffect, useState, useRef } from "react";
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { useDispatch, useSelector } from "react-redux";
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import * as subscriptions from '../../../graphql/subscriptions';
import { v4 as uuid } from "uuid";



import awsconfig from '../../../aws-exports';
import { setChatHead } from "redux/actions/userActions";
import moment from "moment";

Amplify.configure(awsconfig);

const CharDoctor = () => {
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState(null);
    const userData = useSelector((state: any) => state.userReducer.user);
    const messagesEndRef = useRef(null)

    const [conversations, setConversations] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const selectedChatHead = useSelector((state: any) => state.chat.chathead);
    const selectedChatUser = useSelector((state: any) => state.chat.chatuser);
    const [allMessages, setAllMessages] = useState([]);

	const audio = new Audio(RINGER);

    // console.log(selectedChatHead, "selectedChatHead")
    const dispatch = useDispatch();

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [allMessages]);

    const sendMessage = async (e) => {

        e.preventDefault();
        console.log(messageInput,"setMessageInput");

        if(messageInput != null && messageInput !== ''){
            const messageObj = { 
                author: selectedChatHead?.patient,
                authorName:selectedChatHead?.patientName,
                // reciver: selectedChatHead?.doctor ,
                authorType: userData?.role ,
                content: messageInput, 
                conversationId: selectedChatHead?.id, 
                createdAt: new Date(), 
                id: uuid() 
            }
            const messageItem = await API.graphql(graphqlOperation(mutations.createMessage,messageObj)); // equivalent to above example
            console.log(messageItem);
            setMessageInput('');
        }

    }

    const getLiveMessage = async (id) => {
        const subscription = (API.graphql(
            graphqlOperation(subscriptions.subscribeToNewMessage, {conversationId: id})
        ) as any).subscribe((eventData) => {
            console.log(eventData);
            setAllMessages(allMessages => [...allMessages, eventData?.value?.data?.subscribeToNewMessage]);
        });

        console.log(subscription);

        setMessage(subscription);
    }
    
    // const getLiveConversation = async (patientId) => {
    //     const subscriptionConversation = (API.graphql(
    //         graphqlOperation(subscriptions.subscribeToNewConversation, {patient: patientId})
    //     ) as any).subscribe((eventData) => {
    //         console.log("New Conversation Created",eventData);
    //         if(conversations?.length <= 0){
    //             setConversations(conversations => [...conversations, eventData?.value?.data?.subscribeToNewConversation]);
    //             getMessagesByConversation(eventData?.value?.data?.subscribeToNewConversation?.id);
    //             getLiveMessage(eventData?.value?.data?.subscribeToNewConversation?.id);
    //             dispatch(setChatHead(eventData?.value?.data?.subscribeToNewConversation));
    //         }else{
    //             setConversations(conversations => [...conversations, eventData?.value?.data?.subscribeToNewConversation]);
    //         }
    //     });
    // }

    const getTime = (timestampp) => {
        return moment(timestampp).format("hh:mm a");
    }

    const getMessagesByConversation = async (cid) => {
        console.log(cid,"conversation id")
        let messages;
        messages = await API.graphql({
            query: queries.allMessage,
            variables: { conversationId: cid }
        });

        console.log("Message", messages?.data?.allMessage);
        setAllMessages(messages?.data?.allMessage);
    }

    const showDrawer = async () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    // const openChat = (itemm) => {
    //     setAllMessages([]);
    //     dispatch(setChatHead(itemm));
    //     getMessagesByConversation(itemm?.id);
    //     message?.unsubscribe();
    //     getLiveMessage(itemm?.id);
    // };

    useEffect(() => {
        getConversations();
        // getLiveConversation(userData?.id);
        scrollToBottom();
    }, [])

    const getConversations = async () => {
        let convo;
        convo = await API.graphql({
            query: queries.getConversationById,
            variables: { id: selectedChatUser?.id }
        });

        if (convo?.data?.getConversationById?.length !== 0) {
            
            console.log("convo?.data?.getConversationById",convo?.data?.getConversationById)

            // convo?.data?.getConversationById.forEach((element, index) => {
            //     console.log(index);
            //     if (index === 0) {
            // getMessagesByConversation(element?.id);
            // dispatch(setChatHead(element));
            // message?.unsubscribe();
            // getLiveMessage(element?.id);
            getMessagesByConversation(convo?.data?.getConversationById[0]?.id);
            dispatch(setChatHead(convo?.data?.getConversationById[0]));
            message?.unsubscribe();
            getLiveMessage(convo?.data?.getConversationById[0]?.id);
            //     }
            // });

            setConversations(convo?.data?.getConversationById);
        } else {
            createConversation();
            // setConversations([]);
            // dispatch(setChatHead(null));
        }
    }

    const createConversation = async () => {
        const conversionObj = {
            createdAt: new Date(),
            doctor: selectedChatUser?.providerid,
            doctorName: selectedChatUser?.providername,
            id: selectedChatUser?.id,
            patient: selectedChatUser?.patientid,
            patientName: selectedChatUser?.patientname,
        }

        const conversationItem = await API.graphql(
            graphqlOperation(mutations.createConversation, conversionObj),
        ) // equivalent to above example

        console.log(conversationItem['data'],"conversation item Che Ashi")
        message?.unsubscribe();
        getMessagesByConversation(conversationItem['data'].createConversation?.id);
        dispatch(setChatHead(conversationItem['data']?.createConversation));
        getLiveMessage(conversationItem['data']?.createConversation?.id);

        // messages?.unsubscribe()
        // setConversation(conversationItem?.data?.createConversation)
        // getMessagesByConversation(conversationItem?.data?.createConversation?.id)
        // getLiveMessage(conversationItem?.data?.createConversation?.id)
    }

    return (
        <div>
            <TopBar />

            <div className="box">
                <div className={`${style.toggebtn} d-block d-xl-none`}>
                    <i>
                        <FontAwesomeIcon
                            className="mr-1"
                            icon={faEquals}
                            onClick={showDrawer}
                        />
                    </i>
                </div>
                <div className="d-block d-xl-none">
                    <p className="pageInfoM">Chat</p>
                </div>
                <div className="d-none d-xl-block">
                    <p className="pageInfo">Chat</p>
                </div>
            </div>

            {/* <div className="box">
                <p className="pageInfo">Home / Chat Doctor</p>
                <h5 className="h5New">Chat Doctor</h5>
            </div> */}

            <div>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div
                                className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar`}
                            >
                                <DashboardSidebar />
                            </div>
                            <Drawer
                                closable={false}
                                width={300}
                                placement="left"
                                onClose={onClose}
                                visible={visible}
                                style={{ paddingTop: 0 }}
                            >
                                <div className="widget-profile pro-widget-content pt-0 pb-4">
                                    <div className="profile-info-widget">
                                        <img src="../Images/logo.png" height="50" alt="" />
                                    </div>
                                </div>
                                <DashboardSidebar />
                            </Drawer>


                            {/* <div className="col-xl-12"> */}

                            <div className="col-lg-12 col-xl-10">
                                <div className="chat-window">
                                    <div className="chat-cont-right">
                                        <div className="chat-header">
                                            <a id="back_user_list" href="#0" className="back-user-list">
                                                <i className="material-icons">chevron_left</i>
                                            </a>
                                            <div className="media">
                                                <div className="media-img-wrap">
                                                    <div className="avatar avatar-online">
                                                        <img src={IMG011} alt="User" className="avatar-img rounded-circle" />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div className="user-name">Appointment with Dr {selectedChatHead?.doctorName}</div>
                                                    <div className="user-status">online</div>
                                                </div>
                                            </div>
                                            <div className="chat-options">
                                            </div>
                                        </div>
                                        <div className="chat-body">
                                            <div className="chat-scroll">
                                                <ul className="list-unstyled">

                                                    {allMessages?.map((itemm, index) => (
                                                        <div key={index}>
                                                            {parseInt(itemm?.author) === userData?.id ?
                                                                <li className="media sent">
                                                                    <div className="media-body">
                                                                        <div className="msg-box">
                                                                            <div>
                                                                                <h6>{itemm?.authorName} ({itemm?.authorType})</h6>
                                                                                <p>{itemm?.content}</p>
                                                                                <ul className="chat-msg-info">
                                                                                    <li>
                                                                                        <div className="chat-time">
                                                                                            <span>{getTime(itemm?.createdAt)}</span>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li> :
                                                                <li className="media received">
                                                                    <div className="avatar">
                                                                        <img src={IMG011} alt="User" className="avatar-img rounded-circle" />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <div className="msg-box">
                                                                            <div>
                                                                                <h6>{itemm?.authorName} ({itemm?.authorType})</h6>
                                                                                <p>{itemm?.content}</p>
                                                                                <ul className="chat-msg-info">
                                                                                    <li>
                                                                                        <div className="chat-time">
                                                                                        <span>{getTime(itemm?.createdAt)}</span>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            }
                                                        </div>
                                                    ))}

                                                <div ref={messagesEndRef} ></div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="chat-footer">
                                            <form name="f2" id="f12">
                                                <div className="input-group">
                                                    <input type="text" className="input-msg-send form-control" placeholder="Type something" 
                                                    value={messageInput} onChange={evt => setMessageInput(evt?.target?.value)}   />
                                                    <div className="input-group-append">
                                                        <button type="submit" onClick={(e) => {sendMessage(e)}}
                                                        className=" w-25 btn msg-send-btn"><i>
                                                            <FontAwesomeIcon className="mr-1" icon={faShare} /></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.footer}>
                {/* <Footer /> */}
            </div>
        </div >
    );
};

export default CharDoctor;