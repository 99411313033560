import { Auth } from 'aws-amplify'
import { useEffect, useSelector } from "react";
import * as moment from 'moment'
import store from 'store'
import jwt_decode from "jwt-decode";
import { faBullseye } from '@fortawesome/free-solid-svg-icons';


const config = {
  apiUrl: process.env.REACT_APP_API_URL
}
const axios = require('axios')
// const dispatch = useDispatch();
const GET = (path) => {
  GetRefreshToken();
  return axios.get(`${config.apiUrl}${path}`, {
    headers: { Authorization: store.get('auth_token_refresh') }
  }
  ).catch((error) => {
    if (error.response) {
      if (error.response.status == 401) {
        window.location = '/login';
      }
    }
    if (error.request) {
      // window.location = '/login';
      throw error
    } else {
      throw error
    }
  })
}


const GET_WITHOUT_AUTH = (path, token) => {
  return axios.get(`${config.apiUrl}${path}`, {
    headers: { Authorization: token }
  }
  ).catch((error) => {
    if (error.response) {
      if (error.response.status == 401) {
        window.location = '/login';
      }
    }
    if (error.request) {
      // window.location = '/login';
      throw error
    } else {
      throw error
    }
  })
}

const POST_WITHOUT_AUTH = (path, body, token) => {
  const options = {
    body: {},
  }
  if (body) {
    options.body = body
  }
  return axios.post(`${config.apiUrl}${path}`, options.body, { headers: { Authorization: token } }).catch((error) => {
    if (error.response) {
      if (error.response.status == 401) {
        window.location = '/login';
      }
    }
    if (error.request) {
      // window.location = '/login';
      throw error
    } else {
      throw error
    }
  })
}



const POST = (path, body) => {
  GetRefreshToken();
  const options = {
    body: {},
  }
  if (body) {
    options.body = body
  }
  return axios.post(`${config.apiUrl}${path}`, options.body, { headers: { Authorization: store.get('auth_token_refresh') } }).catch((error) => {
    if (error.response) {
      if (error.response.status == 401) {
        window.location = '/login';
      }
    }
    if (error.request) {
      // window.location = '/login';
      throw error
    } else {
      throw error
    }
  })
}

const PUT = (path, body) => {
  GetRefreshToken();
  const options = {
    body: {},
  }
  if (body) {
    options.body = body
  }
  return axios.put(`${config.apiUrl}${path}`, options.body, { headers: { Authorization: store.get('auth_token_refresh') } }).catch((error) => {
    if (error.response) {
      if (error.response.status == 401) {
        window.location = '/login';
      }
    }
    if (error.request) {
      // window.location = '/login';
      throw error
    } else {
      throw error
    }
  })
}

const DELETE = (path) => {
  GetRefreshToken();
  return axios.delete(`${config.apiUrl}${path}`, { headers: { Authorization: store.get('auth_token_refresh') } }).catch((error) => {
    if (error.response) {
      if (error.response.status == 401) {
        window.location = '/login';
      }
    }
    if (error.request) {
      window.location = '/login';
      throw error
    } else {
      throw error
    }
  })
}

const OpenPOST = (path, body) => {
  const options = {
    body: {},
  }
  if (body) {
    options.body = body
  }
  return axios.post(`${config.apiUrl}${path}`, options.body, {}).catch((error) => {
    console.log(error);
    console.log(error.message);
    if (error.response) {

    }
    if (error.request) {
      if (error.message == "Network Error") {

      }
      throw error
    } else {
      throw error
    }
  })
}

const checkToken = () => {
  var duration = 0
  var tkn = store.get('auth_token_refresh');
  console.log("token", tkn);
  if (tkn)
  {
    var storedtkn = jwt_decode(store.get('auth_token_refresh'));
    // console.log(storedtkn); 
    const date = moment.unix(storedtkn.exp);
    const now = moment(new Date())
    duration = date.diff(now, 'minutes');
    if (duration <= 0) {
      console.log("Token Expired", duration)
      return false;
    } else {
      return true;
    }
  }else
  {
    return false;
  }
 
}

const GetRefreshToken = async () => {
  store.set('userauth', true);
  var duration = 0
  try {

    // console.log(moment.unix(currentSession.idToken.payload.exp).format(), 'token expiration time')
    //  console.log(currentSession.idToken, 'token expiration time')
    //console.log(store);
    var storedtkn = jwt_decode(store.get('auth_token_refresh'));
    // console.log(storedtkn); 
    const date = moment.unix(storedtkn.exp);
    const now = moment(new Date())
    duration = date.diff(now, 'minutes');

    console.log("DURATION ", duration)
    if (duration <= 0) {
      console.log("TIME OUT ************** ", duration)
      window.location = '/login';
    }

    if (duration <= 15) {
      console.log('TOKEN REFRESHED')
      // Auth.currentSession()
      //     .then(data => console.log(data))
      //     .catch(err => console.log(err));
      const currentSession = await Auth.currentSession()
      const cognitoUser = await Auth.currentAuthenticatedUser()
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        console.log('session', session)
        var userid = store.get('userid');
        var name = store.get('name');
        var email = store.get('email');
        const payload = {
          "activity": 'REFRESHTOKEN',
          "source": 'PATIENTPORTAL',
          "userid": userid,
          "username":email,
          "notes": '',
          "valueeint": 1,
          "value": 1,
          "valuedesc": 'REFRESHTOKEN',
          "sourceapp": "PATIENTPORTAL",
          "name": name
        }
        console.log(payload);

        store.set('auth_token_refresh', session?.idToken?.jwtToken);
        store.set('auth_token_exp', session?.idToken?.payload?.exp);

        try {
          POST("analyticsdb", payload).then((response) => {
            console.log("ANALYTICS LOGGED - Token Refreshed", response);
          });
        } catch (error) {
          console.log("ANALYTICS LOG ERROr", error);
        }
      })
    }
  } catch (e) {

    console.log('Unable to refresh Token', e);
    // AuthService.logout();
    // store.set('userauth',false);
    // dispatch(authentication(false));
  }
}

export { checkToken, GET, POST, PUT, DELETE, OpenPOST, GET_WITHOUT_AUTH, POST_WITHOUT_AUTH }
