/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { TopBar } from "components";
import { DashboardSidebar } from "./sidebar/sidebar.jsx";
import { Tab, Tabs, Button } from "react-bootstrap";
import moment from "moment";
import {
  Spin,
  Modal,
  notification,
  Table,
  Space,
  Drawer,
  Empty,
  Tooltip,
  Tag,
  Typography,
  Button as Buttons,
} from "antd";
import { useHistory } from "react-router-dom";
import { MessageOutlined } from '@ant-design/icons'
import Footer from "components/footerFix";
import style from "./style.module.css";
import SSEHandler from "../../lib/SSEHandler";
import { GET, POST } from "../../services/common.api";
import { IMG01,IMG011,IMG012,RINGER } from "./img";
import IMG02 from "../../images/user.png";
import Man from "../../images/man.png";
import Woman from "../../images/woman.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CommentOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCross,
  faEquals,
  faEye,
  faFile,
  faFileAlt,
  faFileInvoiceDollar,
  faShare,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { PlusOutlined } from "@ant-design/icons";
import { RemoveRedEyeOutlined } from "@material-ui/icons";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin, FileIcon } from "@react-pdf-viewer/default-layout";
import { base64toBlob } from "../../utils/common";
import PatientDetail from "../../components/patientDetail";
import { AppointmentRequestType, setAppConfig, setChatHead, setChatUserData, setFamilyDoctor, setInsurance, setProfileComplate, setSelectedFamilyMember } from "../../redux/actions/userActions";
import AddFamilyMember from "components/addFamilyMember";
import { v4 as uuid } from "uuid";
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as subscriptions from '../../graphql/subscriptions';
import * as queries from '../../graphql/queries';
import awsconfig from '../../aws-exports';
import { faFileText } from "@fortawesome/free-regular-svg-icons";
Amplify.configure(awsconfig);

const Dashboard = () => {
  // All For chat
  const { Text, Title } = Typography;
  
  const messagesEndRef = useRef(null)
  const [allMessages, setAllMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const selectedChatHead = useSelector((state: any) => state.chat.chathead);
  const selectedChatUser = useSelector((state: any) => state.chat.chatuser);
  const [conversations, setConversations] = useState([]);
  const [openChat, setOpenChat] = useState(false);
  const audio = new Audio(RINGER);

  const history = useHistory();
  const dispatch = useDispatch();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderFamily, setLoaderFamily] = useState(false);
  const [priscriptionsDetails, setPriscriptionsDetails] = useState([] as any);
  const [eventWorkshop, setEventWorkshop] = useState([] as any);
  const [message, setMessage] = useState(null);
  const [particluarEvent, setParticularEvent] = useState({} as any);
  const [eventModalVisible, setEventModalVisisble] = useState(false);
  const [callBackDetails, setCallBackDetails] = useState([]);

  const [selectAppointmentId, setSelectAppointmentId] = useState({} as any);
  const [doctorList, setdoctorList] = useState([] as any);
  const [previousDoctorList, setPreviousDoctorList] = useState([] as any);
  const [PdfString, setPdfString] = useState("");
  // const [prescription, setPrescription] = useState([]);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [type, setType] = useState();
  const [selectedCard, setSelectedCard] = useState("Upcoming");
  const [familyMembers, setFamilyMembers] = useState([] as any);
  const [showFamily, setShowFamily] = useState(false);
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState({} as any);
  const [familyModel, setFamilyModel] = useState(false);
  const [click, setClick] = useState(false);
  const [addClick, setAddClick] = useState(false);
  const [selectedFamilyMember, setSelectedFamilyMemberr] = useState(null);
  const [noteModal, setNoteModal] = useState(false);
  let user = useSelector((state: any) => state.userReducer.user);
  const [pdfUrl, setPdfUrl] = useState(null)
  const [isOpenPdfModal, setIsOpenPdfModal] = useState(false)

  let config = useSelector((state: any) => state.AppConfig.config);
  const isProfileComplate = useSelector(
    (state: any) => state.userReducer.IsProfileComplate
  );
  const currentAppointmentState: any = useSelector(
    (state: any) => state?.curentAppointment?.appointmentRequestType
  );
  const [showEdit, setShowEdit] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [allMessages]);
  

  const getConversations = async (value) => {
    setAllMessages([]);
    let convo;
    convo = await API.graphql({
      query: queries.getConversationById,
      // variables: { id: selectedChatUser?.id }
      variables: { id: value?.appointment_id || value?.id }
      // variables: { id: value?.patientid }
    });

    if (convo?.data?.getConversationById?.length !== 0) {
        console.log("convo?.data?.getConversationById",convo?.data?.getConversationById)
        getMessagesByConversation(convo?.data?.getConversationById[0]?.id);
        dispatch(setChatHead(convo?.data?.getConversationById[0]));
        message?.unsubscribe();
        getLiveMessage(convo?.data?.getConversationById[0]?.id);
        setConversations(convo?.data?.getConversationById);
    } else {
        createConversation(value);
    }
  }
  const createConversation = async (value) => {
    const conversionObj = {
        createdAt: new Date(),
        doctor: value?.providerid,
        doctorName: value?.providername,
        id: value?.id,
        patient: value?.patientid,
        patientName: value?.patientname,
    }

    const conversationItem = await API.graphql(
      graphqlOperation(mutations.createConversation, conversionObj),
    )

    console.log(conversationItem['data'],"conversation item Che Ashi")
    message?.unsubscribe();
    getMessagesByConversation(conversationItem['data'].createConversation?.id);
    dispatch(setChatHead(conversationItem['data']?.createConversation));
    getLiveMessage(conversationItem['data']?.createConversation?.id);
  }

  const getLiveMessage = async (id) => {
    const subscription = (API.graphql(
        graphqlOperation(subscriptions.subscribeToNewMessage, {conversationId: id})
    ) as any).subscribe((eventData) => {
        console.log(eventData,"evevevevevveveveentntnntntnt");

        if(eventData?.value?.data?.subscribeToNewMessage?.author != user?.id){
          audio.play();
        }

        setAllMessages(allMessages => [...allMessages, eventData?.value?.data?.subscribeToNewMessage]);
    });

    console.log(subscription);
    setMessage(subscription);
}

  const getTime = (timestampp) => {
    return moment(timestampp).format("hh:mm a");
  }

  const getMessagesByConversation = async (cid) => {
    console.log(cid,"conversation id")
    let messages;
    messages = await API.graphql({
        query: queries.allMessage,
        variables: { conversationId: cid }
    });

    console.log("Message", messages?.data?.allMessage);
    setAllMessages(messages?.data?.allMessage);
  }

  const sendMessage = async (e) => {

    e.preventDefault();
    console.log(messageInput,"setMessageInput");

    if(messageInput != null && messageInput !== ''){
        const messageObj = { 
            author: selectedChatHead?.patient,
            authorName:selectedChatHead?.patientName,
            // reciver: selectedChatHead?.doctor ,
            authorType: user?.role ,
            content: messageInput, 
            conversationId: selectedChatHead?.id, 
            createdAt: new Date(), 
            id: uuid() 
        }
        const messageItem = await API.graphql(graphqlOperation(mutations.createMessage,messageObj)); // equivalent to above example
        console.log(messageItem);
        setMessageInput('');
    }

  }


  const getFamilyMember = async () => {
    try {
      setLoaderFamily(true);
      const {
        data: {
          body: { FamilyMembers },
        },
      } = await GET(`userfamilymembers?id=${user.id}`);
      console.log(FamilyMembers, "FamilyMembers");
      if (FamilyMembers) {
        setLoaderFamily(true);
        setFamilyMembers(FamilyMembers);
        setLoaderFamily(false);
      } else {
        setLoaderFamily(true);
        setFamilyMembers([]);
        setLoaderFamily(false);
      }
    } catch (err) {
      setLoaderFamily(false);
      console.log("err: ", err);
    }
  };

  const getAllEventAndWorkShops = async () => {
    try {
      const eventData = await GET(`event`);
      console.log("eventData: ", eventData);
      setEventWorkshop(eventData.data.body);
    } catch (error) {
      console.log(error);
    }
  };

  const patientDetailClose = () => {
    setIsDetailVisible(false);
  };

  const addFamilyModelOpen = () => {
    setFamilyModel(true);
    setSelectedFamilyMemberr({});
    setAddClick(true);
  };

  const familyModelClose = () => {
    setFamilyModel(false);
  };

  const getFamilyDoctor = () => {
    try {
      GET(`patient/familydoc/${user.id}`).then((response) => {
        console.log(
          "family member...: ",
          response?.data?.body?.CompanyEmployee?.CompanyEmployee
        );
        let N = response?.data?.body?.CompanyEmployee?.CompanyEmployee;
        if (N !== null && N !== "") {
          if (N?.FirstName !== undefined && N?.LastName !== undefined) {
            dispatch(setFamilyDoctor(`Dr. ${N?.FirstName} ${N?.LastName}`));
          } else {
            dispatch(setFamilyDoctor(""));
          }
        }
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };

  useEffect(() => {
    if (user.id !== undefined) {
      getDoctortList({ showloader: false }, user?.id);
      getpriscriptionList(user.id);
      getcallBackList(user.id);
      getFamilyMember();
      getFamilyDoctor();
      getAppConfig();
      getAllEventAndWorkShops();
      getInsuranceDetailForUser();
    }
    // setNoteModal(true)
    setNoteModal(true);


  }, [user.id]);

  const getAppConfig = async () => {
    try {
      await GET("config").then((response) => {
        console.log("config...: ", response?.data[0]);
        dispatch(setAppConfig(response?.data[0]));

        setShowEdit(true);
      });
    } catch (error) {
      setShowEdit(false);
      notification.error({
        message: error.message,
      });
    }
  };

  useEffect(() => {
    // if (store.get('auth_token_exp') != null) {

    //   const date = moment.unix(store.get('auth_token_exp'))
    //   const now = moment()
    //   const end = date

    //   const duration = moment.duration(end.diff(now))
    //   console.log(duration.asMinutes(), 'durationnnn')

    //   if (duration.asMinutes() <= 0) {
    //     AuthService.GetAuthenticationToken().then((res) => {
    //       console.log(res, "token res in deshboard first time")
    //       store.set('auth_token_refresh', res?.idToken?.jwtToken);
    //       store.set('auth_token_exp', res?.idToken?.payload?.exp);
    //     })
    //   }
    // }

    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));

    SSEHandler.getInstance().eventEmitter.on("updatedAppointmentList", () => {
      console.log("appointment updated successfully");
      getDoctortList({ showloader: false }, user?.id);
    });
    message?.unsubscribe();
    // getLiveMessage();

    window.scrollTo(0, 0);
  }, []);

  const getpriscriptions = (item, type) => {
    console.log("btn click", item, type);
    setSelectAppointmentId(item);
    setType(type)
    setIsDetailVisible(true);
  };

  const getpriscriptionList = (id) => {
    async function fetchMyAPI() {
      console.log(`patientprescriptions?patientid=` + id, "getpriscriptionList ma id check")
      var prescription = await GET(`patientprescriptions?patientid=` + id);
      console.log(prescription?.data, "prescription list data .........");

      if(prescription?.data){
      //   var finalPresctiptions = prescription?.data.reduce((unique, o) => {
      //   if(!unique.some(obj => obj.prescription_id === o.prescription_id)) {
      //     unique.push(o);
      //   }
      //   return unique;
      // },[]);
      // console.log(finalPresctiptions, "========");

      // finalPresctiptions?.sort(function (a, b) {
      //     var dateA = undefined;
      //     var dateB = undefined;

      //     const astartdate = moment(a.createdate,"YYYY-MM-DDTHH:mm:ss.SSSS").format("YYYY-MM-DD hh:mm a");
      //     const bstartdate = moment(b.createdate,"YYYY-MM-DDTHH:mm:ss.SSSS").format("YYYY-MM-DD hh:mm a");


      //     dateA = new Date(astartdate);
      //     dateB = new Date(bstartdate);
      //     return dateB - dateA
      //   });
      // }
      prescription?.data?.sort(function (a, b) {
          var dateA = undefined;
          var dateB = undefined;

          const astartdate = moment(a.createdate,"YYYY-MM-DDTHH:mm:ss.SSSS").format("YYYY-MM-DD hh:mm a");
          const bstartdate = moment(b.createdate,"YYYY-MM-DDTHH:mm:ss.SSSS").format("YYYY-MM-DD hh:mm a");


          dateA = new Date(astartdate);
          dateB = new Date(bstartdate);
          return dateB - dateA
        });
        setPriscriptionsDetails(prescription?.data);
        // setPriscriptionsDetails(finalPresctiptions);
      }  
      

    }
    fetchMyAPI();
  };

  const getcallBackList = (id) => {
    async function fetchMyAPI() {
      const callback = await GET(`callbackrequests/patient/${id}`);
      console.log(callback, "callback list")

      let allCallback =  callback?.data?.body?.data;
      if(allCallback){
        allCallback.sort(function (a, b) {
          var dateA = undefined;
          var dateB = undefined;

          const astartdate = moment(a.callbackstart,"YYYY-MM-DD HH:mm a").format("YYYY-MM-DD hh:mm a");
          const bstartdate = moment(b.callbackstart,"YYYY-MM-DD HH:mm a").format("YYYY-MM-DD hh:mm a");

          dateA = new Date(astartdate);
          dateB = new Date(bstartdate);
          return dateB - dateA
        });
  
        console.log(allCallback,"all callback")
        setCallBackDetails(allCallback);
      }
    }
    fetchMyAPI();
  };

  const getDoctortList = (options?: any, id?: any) => {
    console.log(id, "My id");
    async function fetchMyAPI() {
      if (!options || (options && options.showloader)) {
        // setLoader(true);
      }
      setLoader(true);
      const patientlist = await GET(`appointment?patientid=${id}`);
      // const patientlist = await GET(`appointment?patientid=148`);
      console.log(patientlist, "new api call dash");

      if (patientlist?.data.hasOwnProperty('errorMessage')) {
        setdoctorList([]);
      } else {
        setLoader(false);
        if (patientlist?.data?.errorType == "Error") {
          setdoctorList([]);
        } else {

          console.log("else ma avyuyuyuyuyu",patientlist)

          const currentAndFuture = [];
          const pastAppointments = [];
          patientlist?.data?.forEach((element: any) => {

            
            const compareDatee = moment(element.startdatetime,"YYYY-MM-DDTHH:mm:ss.SSSS").format("DD-MM-YYYY HH:mm A");
            const compareTodayDate = moment().format("DD-MM-YYYY HH:mm A");

            const todayDate = moment(compareTodayDate, "DD-MM-YYYY HH:mm A");
            const pastDate = moment(compareDatee, "DD-MM-YYYY HH:mm A");
            const dDiff = pastDate.diff(todayDate);

            // console.log('Date is not past', todayDate);
            // console.log('Date is not past', pastDate);
            // console.log('Date is not past', dDiff);

            console.log(element.startdatetime,"element.startdatetime")
            console.log(compareDatee,"compareDateecompareDatee")
            console.log(compareTodayDate,"compareTodayDatecompareTodayDate")


            if (dDiff >= 0) {
              console.log('Date is not past');
              currentAndFuture.push(element);
            } else {
              console.log('Date is past');
              pastAppointments.push(element);
            }
          });

          currentAndFuture.sort(function (a, b) {
            var dateA = undefined;
            var dateB = undefined;
            dateA = new Date(a.startdatetime);
            dateB = new Date(b.startdatetime);
            // return dateA - dateB
            return dateB - dateA
          });
          
          let walkinData = [];
          const patientlistAll = await GET(`appointment/walking?patientid=${id}`);
          console.log(patientlistAll, "patientlistAllpatientlistAllpatientlistAll");
          if (patientlistAll?.data.hasOwnProperty('errorMessage')) {
            walkinData = pastAppointments;
          } else {
            walkinData = [...pastAppointments,...patientlistAll?.data];
          }

          // pastAppointments.sort(function (a, b) {
          //   var dateA = undefined;
          //   var dateB = undefined;
          //   dateA = new Date(a.startdatetime);
          //   dateB = new Date(b.startdatetime);
          //   // return dateA - dateB
          //   return dateB - dateA
          //   // return dateA - dateB
          // });
          walkinData.sort(function (a, b) {
            var dateA = undefined;
            var dateB = undefined;
            dateA = new Date(a.startdatetime);
            dateB = new Date(b.startdatetime);
            // return dateA - dateB
            return dateB - dateA
            // return dateA - dateB
          });

          console.log(currentAndFuture);
          setdoctorList(currentAndFuture);
          console.log(walkinData, "pastconsole.log(currentAndFuture);");
          setPreviousDoctorList(walkinData)
          // setdoctorList(patientlist?.data);
        }
      }
    }
    fetchMyAPI();
  };

  const getDateFormate = (dateData) => {
    let stillUtc = moment.utc(dateData).toDate();
    return moment(stillUtc, ["YYYY-MM-DD h:mm:ss A"]).local().format("h:mm A");
  };

  const showPdf = async (preId) => {
    console.log("preId: ", preId);
    try {
      const paylod = {
        prescriptionId: preId,
      };
      POST("generatepdf", paylod).then((response) => {
        console.log("response: ", response);
        if (response?.data?.statusCode === 200) {
          const url = URL.createObjectURL(base64toBlob(response?.data?.body));
          console.log(url, "url pdf");
          setPdfString(url);
          setIsModalVisible(true);
        } else {
          notification.error({
            message: "Error while save prescription.",
          });
        }
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };

  const appointmentListColumn = [
    {
      title: "Doctor",
      dataIndex: ["providername", "clinicname"],
      key: "name",
      render: (text, row) => (
        <a style={{ color: "#1A3045", fontSize: 15, cursor: "unset" }}>
          <div className="row">
            <div>
              {/* <img
                style={{ width: 40, marginLeft: 8 }}
                className="avatar-img rounded-circle"
                // src={IMG01}
                src={
                  row.providerprofilepicture == null || row.providerprofilepicture == ''
                    ? IMG01
                    : getUserImage(row.providerprofilepicture)
                }
              /> */}
            </div>
            <div style={{ paddingLeft: 13 }}>
              Dr. {row["providername"]}
              <p style={{ color: "#009efb", margin: 0 }}>{row["clinicname"]}</p>
            </div>
          </div>
        </a>
      ),
    },
    {
      title: "Appointment Type",
      dataIndex: "appointmenttype",
      key: "appointmenttype",
      render: (text, Speciality) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {text}
        </a>
      ),
    },
    {
      title: "Appointment Date",
      dataIndex: "startdatetime",
      key: "name",
      render: (text, Speciality) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {/* {moment(text).local().format("DD-MMM-YYYY")}{" "} */}
          {moment(text).utc().format("DD-MMM-YYYY")}{" "}
          <div>
            <p style={{ color: "#009efb", margin: 0 }}>
              {moment(text).utc().format("hh:mm A")}
            </p>
          </div>
        </a>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (tags) => (
        <>
          {tags == "DRAFT" ? (
            <span className={`badge badge-warning ${style.tag_dashboard2}`}>
              PENDING
            </span>
          ) : null}
          {tags == "PENDING" ? (
            <span className={`badge badge-warning ${style.tag_dashboard2}`}>
              CALLBACK
            </span>
          ) : null}
          {tags == "CONFIRMED" ? (
            <span className={`badge badge-success ${style.tag_dashboard}`}>
              CONFIRMED
            </span>
          ) : null}
          {tags == "COMPLETED" ? (
            <span className={`badge  ${style.tag_dashboard_completed}`}>
              COMPLETED
            </span>
          ) : null}
          {tags == "CANCEL" ? (
            <span className={`badge badge-danger  ${style.tag_dashboard}`}>
              CANCELED
            </span>
          ) : null}
          {tags == "REJECTED" ? (
            <span className={`badge badge-danger ${style.tag_dashboard}`}>
              REJECTED
            </span>
          ) : null}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "appoinment_id",
      render: (status, data) => (
        <Space size="middle">
          <Button
              onClick={event => toChat(data)}
              // to={toChat(data)}
              // to='/chatDoctor'
              className={`${style.btnTransparentNew} ml-3`}
              // target="_blank"
            >
              <CommentOutlined className={style.iconComment} />
            </Button>
            
          {/* <Link
              onClick={event => toChat(data)}
              // to={toChat(data)}
              to='/chatDoctor'
              className="ml-3"
              target="_blank"
            >
              <CommentOutlined className={style.iconComment} />
            </Link> */}
          <button
            className={style.buttonView}
            role="button"
            onClick={() => getpriscriptions(data, "upcomingAppointment")}
          >
            <FontAwesomeIcon icon={faEye} className="mr-2" />
            View
          </button>
          {data.status === "CONFIRMED" ? (
            <>
              {moment(data.startdatetime).format("DD-MMM-YYYY") ==
                moment().format("DD-MMM-YYYY") ? (
                <>
                  {getDateFormate(data.startdatetime) ==
                    moment().format("h:mm A") ? (
                    <button className={style.buttonJoinOriginal} role="button">
                      <FontAwesomeIcon icon={faVideo} className="mr-2" />
                      Join
                    </button>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
        </Space>
      ),
    },
  ];

  const handleCancelChatModal = () => {
    setOpenChat(false);
  }

  const toChat = (dataa) => {
    console.log(dataa, 'dataaaaa ---=-=-=------------');
    const value = dataa;
    dispatch(setChatUserData(value));

    setOpenChat(true);
    scrollToBottom();
    getConversations(value);
  }

  const viewInvoice = async (dataa) => {
    console.log(dataa, "DATATATA");
    // setLoaderId(index.claimid)
    const requestData = {
      claimId : dataa.claimid,
    }
    try {
      const claimData = await POST(`invoice`, requestData)
      const fileUrl = URL.createObjectURL(base64toBlob(claimData.data.pdf))
      setPdfUrl(fileUrl);

      setIsOpenPdfModal(true)
      // setLoader(true)

    } catch (error) {
      notification.warning({
        message: error.message,
      })
    } finally {
      // setLoader(false)
    }
  }

  const PreviousappointmentListColumn = [
    {
      title: "Doctor",
      dataIndex: ["providername", "clinicname"],
      key: "name",
      render: (text, row) => (
        <a style={{ color: "#1A3045", fontSize: 15, cursor: "unset" }}>
          <div className="row">
            <div>
              {/* <img
                style={{ width: 40, marginLeft: 8 }}
                className="avatar-img rounded-circle"
                // src={IMG01}
                src={
                  row.providerprofilepicture == null || row.providerprofilepicture == ''
                    ? IMG01
                    : getUserImage(row.providerprofilepicture)
                }
              /> */}
            </div>
            <div style={{ paddingLeft: 13 }}>
              Dr. {row["providername"]}
              <p style={{ color: "#009efb", margin: 0 }}>{row["clinicname"]}</p>
            </div>
          </div>
        </a>
      ),
    },
    {
      title: "Appointment Type",
      dataIndex: "appointmenttype",
      key: "appointmenttype",
      render: (text, Speciality) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {text}
        </a>
      ),
    },
    {
      title: "Appointment Date",
      dataIndex: "startdatetime",
      key: "name",
      render: (text, Speciality) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {/* {moment(text).local().format("DD-MMM-YYYY")}{" "} */}
          {moment(text).utc().format("DD-MMM-YYYY")}{" "}
          <div>
            <p style={{ color: "#009efb", margin: 0 }}>
              {moment(text).utc().format("hh:mm A")}
            </p>
          </div>
        </a>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (tags) => (
        <>
          {tags == "DRAFT" ? (
            <span className={`badge badge-warning ${style.tag_dashboard2}`}>
              PENDING
            </span>
          ) : null}
          {tags == "PENDING" ? (
            <span className={`badge badge-warning ${style.tag_dashboard2}`}>
              CALLBACK
            </span>
          ) : null}
          {tags == "CONFIRMED" ? (
            <span className={`badge badge-success ${style.tag_dashboard}`}>
              CONFIRMED
            </span>
          ) : null}
          {tags == "COMPLETED" ? (
            <span className={`badge  ${style.tag_dashboard_completed}`}>
              COMPLETED
            </span>
          ) : null}
          {tags == "REJECTED" ? (
            <span className={`badge badge-danger ${style.tag_dashboard}`}>
              REJECTED
            </span>
          ) : null}
          {tags == "CANCELLED" ? (
            <span className={`badge badge-danger ${style.tag_dashboard}`}>
              CANCELLED
            </span>
          ) : null}
          {tags == "CANCEL" ? (
            <span className={`badge badge-danger  ${style.tag_dashboard}`}>
              CANCELED
            </span>
          ) : null}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "appoinment_id",
      render: (status, data) => (
        <Space size="middle">
          <Button
              onClick={event => toChat(data)}
              // to={toChat(data)}
              // to='/chatDoctor'
              className={`${style.btnTransparentNew} ml-3`}
              // target="_blank"
            >
              <CommentOutlined className={style.iconComment} />
            </Button>
          {/* <Link
              onClick={event => toChat(data)}
              // to={toChat(data)}
              // to='/chatDoctor'
              className="ml-3"
              // target="_blank"
            >
              <CommentOutlined className={style.iconComment} />
            </Link> */}
          <button
            className={style.buttonView}
            role="button"
            onClick={() => getpriscriptions(data, "previousAppoinrmentAppointment")}
          >
            <FontAwesomeIcon icon={faEye} className="mr-2" />
            View
          </button>
          {data.status === "CONFIRMED" ? (
            <>
              {moment(data.startdatetime).format("DD-MMM-YYYY") ==
                moment().format("DD-MMM-YYYY") ? (
                <>
                  {getDateFormate(data.startdatetime) ==
                    moment().format("h:mm A") ? (
                    <button className={style.buttonJoinOriginal} role="button">
                      <FontAwesomeIcon icon={faVideo} className="mr-2" />
                      Join
                    </button>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}

          {/* <Button
            onClick={event => viewInvoice(data)}
            className={`${style.btnTransparentNew} ml-2`}
          >
            <FontAwesomeIcon icon={faFileInvoiceDollar} className={style.iconComment} />
          </Button> */}

          {data.claimid != null ? 
            <Button
              onClick={event => viewInvoice(data)}
              className={`${style.btnTransparentNew} ml-2`}
            >
              <FontAwesomeIcon icon={faFileInvoiceDollar} className={style.iconComment} />
            </Button>  : null }            

        </Space>
      ),
    },
  ];

  const priscriptionsListColumn = [
    // {
    //   title: "Appointment Id",
    //   dataIndex: "appoinment_id",
    //   key: "appoinment_id",
    // },
    {
      title: "Doctor",
      dataIndex: "['doctorFirstName','doctorLastName'],",
      key: "doctorFirstName",
      render: (text, row) => (
        <>
          {
            text !== "null null" ? <Text style={{ textTransform: 'capitalize' }}>{row.doctorFirstName} {row.doctorLastName}</Text> : <Text> - </Text>
          }
        </>
      ),
    },
    {
      title: "Appointment Date",
      dataIndex: "createdate",
      key: "createdate",
      render: (text) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {/* {moment(text).local().format("DD-MMM-YYYY")}{" "} */}
          {moment(text).utc().format("DD-MMM-YYYY")}{" "}
          <div>
            <p style={{ color: "#009efb", margin: 0 }}>
              {moment(text).utc().format("hh:mm A")}
            </p>
          </div>
        </a>
      ),
    },
    // {
    //   title: "Drug Name",
    //   dataIndex: "drug_name",
    //   key: "drug_name",
    //   width: 400,
    // },
    {
      title: "Pharmacy Name",
      dataIndex: "pharmacy_name",
      key: "pharmacy_name",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "id",
      render: (id, data) => (
        <Space size="middle">
          <button
            style={{
              paddingTop: 6,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={style.buttonJoin}
            role="button"
            onClick={() => getpriscriptions(data, "prescription")}
          >
            <FontAwesomeIcon className="mr-2" icon={faCalendar} />
            Appointment
          </button>

          <button
            className={style.buttonView}
            role="button"
            onClick={() => showPdf(data.id)}
          >
            <FontAwesomeIcon icon={faEye} className="mr-2" />
            View
          </button>
        </Space>
      ),
    },
  ];

  const callBackColumn = [
    {
      title: "Patient Name",
      dataIndex: ["patientname", "patientprofilepicture"],
      key: "patientname",
      render: (text, row) => (
        <a style={{ color: "#1A3045", fontSize: 15, cursor: "unset" }}>
          <div className="row">
            <div>
              {/* <img
                style={{ width: 40, marginLeft: 8 }}
                className="avatar-img rounded-circle"
                src={
                  // row.patientprofilepicture == null
                  row.providerprofilepicture == null || row.providerprofilepicture == ''
                    ? IMG02
                    : `${process.env.REACT_APP_ASSET_URL}/${user.profilepicture}`
                }
              /> */}
            </div>
            <div style={{ paddingLeft: 13 }}>{row.patientname.charAt(0).toUpperCase() + row.patientname.slice(1)}</div>
          </div>
        </a>
      ),
    },
    {
      title: 'Payment Type',
      key: 'paymenttype',
      dataIndex: 'paymenttype',
      render: (paymentTag) => (
        <>
          <>
            {paymentTag === 'cash' && <Tag color="green">{paymentTag?.toUpperCase()}</Tag>}
            {paymentTag === 'PRIVATE_INSURANCE' && (
              <Tag color="red">{paymentTag?.toUpperCase()}</Tag>
            )}
            {paymentTag === 'card' && <Tag color="blue">{paymentTag?.toUpperCase()}</Tag>}
            {paymentTag === 'PRIVATE_PAY' && <Tag color="pink">{paymentTag?.toUpperCase()}</Tag>}
            {paymentTag === 'OHIP' && <Tag color="orange">{paymentTag?.toUpperCase()}</Tag>}
          </>
        </>
      ),
    },
    {
      title: 'Reason',
      dataIndex: 'callbackreason',
      key: 'callbackreason',
      render: (text) => (
        <Tooltip title={text}>
          <a className="text-blue">
            <MessageOutlined />
          </a>
        </Tooltip>
      )

    },
    //  {
    //   title: "DOCTOR NAME",
    //   dataIndex: ["providername", "clinicname"],
    //   key: "name",
    //   render: (text, row) => (
    //     <a style={{ color: "#1A3045", fontSize: 15 }}>
    //       <div className="row">
    //         <div>
    //           <img
    //             style={{ width: 40, marginLeft: 8 }}
    //             className="avatar-img rounded-circle"
    //             // src={IMG01}
    //             src={row.providerprofilepicture == null ? IMG01 : getUserImage(row.providerprofilepicture)}

    //           />
    //         </div>
    //         <div style={{ paddingLeft: 13 }}>
    //           Dr. {row["providername"]}
    //           <p style={{ color: "#009efb", margin: 0 }}>{row["clinicname"]}</p>
    //         </div>
    //       </div>
    //     </a>
    //   ),
    // },
    {
      title: "Date",
      dataIndex: "callbackstart",
      // key: "created_at",
      key: "created_at",
      render: (text) => {
        return <div>{moment(text, "YYYY-MM-DD HH:mm A").format("ll")}</div>;
      },
    },
    {
      title: "Time",
      dataIndex: "callbacktime",
      key: "callbacktime",
      render: (text) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "callbackstatus",
      key: "callbackstatus",
      render: (tags) => (
        <>
          {tags == "ON CALL" ? (
            <span className={`badge badge-warning ${style.tag_dashboard2}`}>
              ON CALL
            </span>
          ) : null}
          {tags == "REJECTED" ? (
            <span className={`badge badge-danger ${style.tag_dashboard2}`}>
              REJECTED
            </span>
          ) : null}
          {tags == "NEW" ? (
            <span className={`badge badge-secondary ${style.tag_dashboard2}`}>
              NEW
            </span>
          ) : null}
          {tags == "FULFILLED" ? (
            <span className={`badge badge-success ${style.tag_dashboard}`}>
              FULFILLED
            </span>
          ) : null}
        </>
      ),
    },


    {
      title: "Action",
      key: "action",
      dataIndex: "id",
      render: (status, data) => (
        <Space size="middle">
          {/* <Link
              onClick={event => toChat(data)}
              // to={toChat(data)}
              to='/chatDoctor'
              className="ml-3"
              target="_blank"
            >
              <CommentOutlined className={style.iconComment} />
          </Link> */}

            <Button
              className={`${style.btnTransparentNew} ml-3`}
              onClick={event => toChat(data)}
            >
              <CommentOutlined className={style.iconComment} />
            </Button>
          <button
            className={style.buttonView}
            role="button"
            // onClick={() => getpriscriptions(data, "callbackrequest")}
            onClick={() => getpriscriptions(data, "prescription")}
          >
            <FontAwesomeIcon icon={faEye} className="mr-2" />
            View
          </button>

          {data.paymenttype == 'OHIP' ? null  :
            <>
              {data.claimid != null ? 
              <Button
                onClick={event => viewInvoice(data)}
                className={`${style.btnTransparentNew} ml-2`}
              >
                <FontAwesomeIcon icon={faFileInvoiceDollar} className={style.iconComment} />
              </Button>  : null }
            </>
          }
        </Space>
      ),
    },
  ];

  const getUserImage = (imageUrl) => {
    if (imageUrl) {
      if (
        imageUrl?.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/) !=
        null
      ) {
        return imageUrl;
      }
      return `${process.env.REACT_APP_ASSET_URL}/${imageUrl}`;
    } else {
      return null;
    }
  };

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const ClickTab = (e) => {
    console.log(e);
    setSelectedCard(e);
  };

  const viewFamilyMembers = (event: any) => {
    console.log(event, "family member selected");

    // dispatch kryu apde
    if (event) {
      dispatch(setSelectedFamilyMember(event));
    }

    var filtered = familyMembers.filter(function (value, index, arr) {
      return value.id == event.id;
    });
    const data = [
      {
        // userName: `${user?.FirstName} ${user?.MiddleName} ${user?.LastName}`,
        userName: `${user?.FirstName}  ${user?.LastName}`,
        id: user?.id,
        gender: user?.gender && user?.gender != 'undefined' ? user?.gender : '-',
      },
    ];
    filtered = data;
    console.log(filtered, "filtered");
    setFamilyMembers(filtered);
    setSelectedFamilyMembers(event);
    setShowFamily(true);
    getDoctortList(
      { showloader: false },
      event?.familymemberid ? event?.familymemberid : event?.id
    );
    console.log(event?.familymemberid, "viewfamily member ma get prescriptions list")
    getpriscriptionList(event?.familymemberid);

    if (event.familymemberid) {
      dispatch(AppointmentRequestType("familyMember"));
    } else {
      dispatch(AppointmentRequestType("Own"));
    }

    getcallBackList(event?.familymemberid ? event?.familymemberid : event?.id);
    getInsuranceDetails(event?.familymemberid ? event?.familymemberid : event?.id);
    if (event?.id == user?.id) {
      setShowFamily(false);
      getFamilyMember();
    }
  };

  const getInsuranceDetails = async (uid) => {
    try {
      setLoader(true);
      const res = await GET(`user/insurance/${uid}`);
      console.log(res?.data, "getInsuranceInfo");
      // setInsuranceData(res?.data);
      const result = res?.data.find(
        (item) => item.insurance_type === 'OHIP'
      );

      if (result) {
        dispatch(setInsurance(result));
      }
      setLoader(false);
    } catch (err) {
      console.log("error", err);
      setLoader(false);
    }
  };
  
  const getInsuranceDetailForUser = async () => {
    try {
      setLoader(true);
      const res = await GET(`user/insurance/${user?.id}`);
      console.log(res?.data, "getInsuranceInfo");
      // setInsuranceData(res?.data);
      const result = res?.data.find(
        (item) => item.insurance_type === 'OHIP'
      );

      if (result) {}else{
        console.log("okokokoko")
        dispatch(setProfileComplate("false"));
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleOk = () => {
    setEventModalVisisble(false);
  };

  const handleCancel = () => {
    setEventModalVisisble(false);
  };

  const handleCancelModal = () => {
    setNoteModal(false);
  };

  const EditProfile = () => {
    history.push("/profile")
  }

  return (
    <>
      <div>
        <TopBar />
        {/* <VideoContainerWrapper /> */}
        <div className="box">
          <div className={`${style.toggebtn} d-block d-xl-none`}>
            <i>
              <FontAwesomeIcon
                className="mr-1"
                icon={faEquals}
                onClick={showDrawer}
              />
            </i>
          </div>
          <div className="d-block d-xl-none">
            <p className="pageInfoM">Dashboard</p>
          </div>
          <div className="d-none d-xl-block">
            <p className="pageInfo">Dashboard</p>
          </div>
        </div>
        <div>
          <div className="content" style={{ backgroundColor: "#f1f5f9" }}>
            <div className="container-fluid">
              <div className="row pb-5 ">
                <div
                  className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar`}
                >
                  <DashboardSidebar />
                </div>
                <Drawer
                  closable={false}
                  width={300}
                  placement="left"
                  onClose={onClose}
                  visible={visible}
                  style={{ paddingTop: 0 }}
                >
                  <div className="widget-profile pro-widget-content pt-0 pb-4">
                    <div className="profile-info-widget">
                      <img src="../Images/logo.png" height="50" alt="" />
                    </div>
                  </div>
                  <DashboardSidebar />
                </Drawer>
                {/* {sidebar ? (
                  <div className={`d-block d-xl-none ${style.toggle}`}>
                    <DashboardSidebar />
                  </div>
                ) : null} */}
                <div className="col-lg-12 col-xl-10">
                  {/* {isProfileComplate == "false" && showEdit ? (
                    <div className="alert alert-warning mb-2" role="alert">
                      Please complete your profile!{" "}
                      setNoteModal(false);
                      <a
                        href="/profile"
                        className="pl-3"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Edit Profile
                      </a>
                    </div>
                  ) : null} */}

                  <div className={`row ${style.div_row_new}`}>
                    <div className="col-lg-8">
                      <div className="row">
                        <div
                          className={`col-sm-12 col-md-5  col-lg-5 col-xl-5 ${style.profile_div}`}
                        >
                          <div className={style.D_Profile_div}>
                            {showFamily == false ? (
                              <>
                                {user?.profilepicture != "" &&
                                user?.profilepicture != null ? (
                                  <img
                                    className="profpic"
                                    src={`${process.env.REACT_APP_ASSET_URL}/${user?.profilepicture}`}
                                    alt="User"
                                  />
                                ) : (
                                  <img
                                    className="profpic"
                                    src={IMG02}
                                    alt="User"
                                  />
                                )}
                                <h4 className="py-2 m-0">
                                  {user?.FirstName?.charAt(0).toUpperCase() + user?.FirstName?.slice(1)} {user?.MiddleName !== null ? user?.MiddleName?.charAt(0).toUpperCase() + user?.MiddleName?.slice(1) : ''}{" "}
                                  {user?.LastName?.charAt(0).toUpperCase() + user?.LastName?.slice(1)}
                                </h4>
                                <h5 className="text-secondary">
                                  {user?.Email}
                                </h5>
                              </>
                            ) : (
                              <>
                                {selectedFamilyMembers.gender == "male" ? (
                                  <img className="profpic" src={Man} alt="" />
                                ) : (
                                  <img className="profpic" src={Woman} alt="" />
                                )}
                                <h4 className="py-2 m-0">
                                  {selectedFamilyMembers?.userName}
                                </h4>
                                {/* <h5 className="text-secondary">{selectedFamilyMembers?.userName}</h5> */}
                              </>
                            )}
                            {/* <div className="text-white rounded pb-3 pt-3" style={{ backgroundColor: '#ffff', width: '80%' }}>
                              <div>
                                <div className="d-flex mb-1">
                                  <div className="text-uppercase mr-auto fontColor">Balance</div>
                                  <div className="fontColor">TOTAL</div>
                                </div>
                                <div className="d-flex mb-2">
                                  <div className="mr-auto fontColor">{config?.currency_symbol} 3,000</div>
                                  <div className="fontColor">{config?.currency_symbol} 5,000</div>
                                </div>
                                <div className="progress">
                                  <div
                                    className="progress-bar fontColor"
                                    style={{
                                      width: '60%',
                                      backgroundColor: '#194f85'
                                    }}
                                    role="progressbar"
                                    aria-valuenow={60}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div
                          className={`col-sm-12 col-md-7  col-lg-7 col-xl-7 ${style.Pro_info_div}`}
                        >
                          {showFamily == false ? (
                            <>
                              <div className={`row pt-3 `}>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 ">
                                  <div className="d-flex flex-column justify-content-start align-items-start">
                                    <div>
                                      <p
                                        className={`${style.lbl_color} m-0 p-0`}
                                      >
                                        Gender
                                      </p>
                                      <p className="m-0 p-0" style={{textTransform : "capitalize"}}>{user?.gender && user?.gender != 'undefined' ? user?.gender : '-'}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Birthday
                                    </p>
                                    <p className="m-0 p-0">
                                      {user?.DOB == null || user?.DOB == ""
                                        ? "-"
                                        : moment(user?.DOB).format(
                                            "DD-MMM-YYYY"
                                          )}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Phone
                                    </p>
                                    <p className="m-0 p-0 ">
                                      {user?.phoneNumber == null ||
                                      user?.phoneNumber == ""
                                        ? "-"
                                        : user?.phoneNumber}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row pt-3">
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column ">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Street Address
                                    </p>
                                    <p className="m-0 p-0">
                                      {user?.Address1 == null ||
                                      user?.Address1 == ""
                                        ? "-"
                                        : user?.Address1}{" "}
                                      {user?.Address2}{" "}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column  ">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      City
                                    </p>
                                    <p className="m-0 p-0">
                                      {user?.City == null || user?.City == ""
                                        ? "-"
                                        : user?.City}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column  ">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      {" "}
                                      State/Province
                                    </p>
                                    <p className="m-0 p-0">
                                      {user?.State == null || user?.State == ""
                                        ? "-"
                                        : user?.State}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row pt-3">
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Country
                                    </p>
                                    <p className="m-0 p-0 ">
                                      {user?.Country == null ||
                                      user?.Country == ""
                                        ? "-"
                                        : user.Country}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Zipcode
                                    </p>
                                    <p className="m-0 p-0 ">
                                      {user?.PostalCode == null ||
                                      user?.PostalCode == ""
                                        ? "-"
                                        : user.PostalCode}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Languages
                                    </p>
                                    <p className="m-0 p-0 " style={{textTransform : "capitalize"}}>
                                      {user?.Languages == null ||
                                      user?.Languages == "" || user?.Languages == 'undefined'
                                        ? "-"
                                        : user?.Languages}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className={`row pt-3 `}>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 ">
                                  <div className="d-flex flex-column justify-content-start align-items-start">
                                    <div>
                                      <p
                                        className={`${style.lbl_color} m-0 p-0`}
                                      >
                                        Gender
                                      </p>
                                      <p className="m-0 p-0" style={{textTransform : "capitalize"}}>
                                        {selectedFamilyMembers?.gender}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Birthday
                                    </p>
                                    <p className="m-0 p-0">
                                      {moment(
                                        selectedFamilyMembers?.dateOfBirth
                                      ).format("DD-MMM-YYYY")}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4">
                                  {/* <div>
                                      <p className="m-0 p-0 text-secondary">Phone</p>
                                      <p className="m-0 p-0 ">{user?.phoneNumber}</p>
                                    </div> */}
                                </div>
                              </div>
                              <div className="row pt-3">
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column ">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Street Address
                                    </p>
                                    <p className="m-0 p-0">
                                      {selectedFamilyMembers?.address}{" "}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column  ">
                                  {/* <div>
                                      <p className="m-0 p-0 text-secondary">City</p>
                                      <p className="m-0 p-0">{user?.City}</p>
                                    </div> */}
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column  ">
                                  {/* <div>
                                      <p className="m-0 p-0 text-secondary">State</p>
                                      <p className="m-0 p-0">{user?.State}</p>
                                    </div> */}
                                </div>
                              </div>
                              <div className="row pt-3">
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column">
                                  {/* <div>
                                      <p className="m-0 p-0 text-secondary">Country</p>
                                      <p className="m-0 p-0 ">{user?.Country}</p>
                                    </div> */}
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column">
                                  {/* <div>
                                      <p className="m-0 p-0 text-secondary">Zipcode</p>
                                      <p className="m-0 p-0 ">{user?.PostalCode}</p>
                                    </div> */}
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column">
                                  {/* <div>
                                      <p className="m-0 p-0 text-secondary">Languages</p>
                                      <p className="m-0 p-0 ">{user?.Languages}</p>
                                    </div> */}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className={style.file_container_div}>
                        {loaderFamily ? (
                          <div className="spinner">
                            <Spin tip="Loading..."></Spin>
                          </div>
                        ) : (
                          <>
                            <div className={style.file_container}>
                              <div>
                                {showFamily == false ? (
                                  <h4 className="font-weight-bold">
                                    Family Members
                                  </h4>
                                ) : (
                                  <h4 className="font-weight-bold">
                                    Primary Member
                                  </h4>
                                )}
                              </div>
                              <div>
                                {familyMembers.length > 0 ? (
                                  ""
                                ) : (
                                  <Buttons
                                    onClick={() => addFamilyModelOpen()}
                                    // onClick={() => history.push("/dependent")}
                                    className="p-0 m-0"
                                    type="primary"
                                    shape="circle"
                                  >
                                    <div className="pb-1">
                                      <PlusOutlined />
                                    </div>
                                  </Buttons>
                                )}
                              </div>
                            </div>
                            <div className={style.inner_div}>
                              {familyMembers.length > 0 ? (
                                <>
                                  {familyMembers.map((el, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={`${style.file_in}`}
                                      >
                                        <div className={style.details}>
                                          {el.gender == "male" ? (
                                            <img
                                              className={style.fileIcon}
                                              src={Man}
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              className={style.fileIcon}
                                              src={Woman}
                                              alt=""
                                            />
                                          )}
                                          <div>
                                            <p
                                              className="pl-3 m-0 text-capitalize"
                                              style={{ width: 160 }}
                                            >
                                              {" "}
                                              {el?.userName}
                                            </p>
                                            <p
                                              className={`${style.lblName} pl-3 m-0 text-capitalize`}
                                            >
                                              {" "}
                                              {el?.relationship}
                                            </p>
                                          </div>
                                        </div>
                                        <div className={style.relationship}>
                                          <p
                                            className="pb-1 mb-1 text-uppercase"
                                            style={{ width: 80 }}
                                          >
                                            {el?.relationship}
                                          </p>
                                        </div>
                                        <div className="">
                                          <button
                                            className={style.buttonView1}
                                            role="button"
                                            onClick={() => {
                                              viewFamilyMembers(el);
                                            }}
                                          >
                                            view
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                      <>
                                        <span>
                                          No Data
                                          {/* <a className="" href="dependent">Add</a> */}
                                        </span>
                                        {/* <Buttons type="primary" shape="circle">
                                          <PlusOutlined />
                                        </Buttons> */}
                                      </>
                                    }
                                  />

                                  {/* <div className="d-flex justify-content-center align-item-center flex-column h-100">
                                      <img src="Images/problems/cancel.png" alt="" className={style.NoDataImg} />
                                      <p className="pt-2">No Member Available</p>
                                    </div> */}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={style.card}>
                    <div
                      className="card-body pt-0"
                      // style={{ marginBottom: 40, height: 520 }}
                      style={{ marginBottom: 40 }}
                    >
                      <div className={style.Segment_Div}>
                        <div
                          style={{
                            backgroundColor: "#eff1f7",
                            display: "flex",
                            padding: 5,
                            borderRadius: 10,
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className={`${
                              selectedCard === "Upcoming"
                                ? "tab_selected"
                                : "tab_static"
                            }`}
                            onClick={() => ClickTab("Upcoming")}
                          >
                            <p className="m-0 p-0" style={{ width: 200 }}>
                              Upcoming Appointments
                            </p>
                          </div>
                          <div
                            className={`${
                              selectedCard === "Previous"
                                ? "tab_selected"
                                : "tab_static"
                            }`}
                            onClick={() => ClickTab("Previous")}
                          >
                            <p className="m-0 p-0" style={{ width: 200 }}>
                              Previous Appointments
                            </p>
                          </div>
                          <div
                            className={`${
                              selectedCard === "Prescriptions"
                                ? "tab_selected"
                                : "tab_static"
                            }`}
                            onClick={() => ClickTab("Prescriptions")}
                          >
                            <p className="m-0 p-0" style={{ width: 200 }}>
                              Prescriptions
                            </p>
                          </div>
                          <div
                            className={`${
                              selectedCard === "CallBack"
                                ? "tab_selected"
                                : "tab_static"
                            }`}
                            onClick={() => ClickTab("CallBack")}
                          >
                            <p className="m-0 p-0" style={{ width: 200 }}>
                              CallBack Requests
                            </p>
                          </div>
                        </div>
                      </div>
                      {loader ? (
                        <div className="spinner">
                          <Spin tip="Loading..."></Spin>
                        </div>
                      ) : (
                        <>
                          {selectedCard == "Upcoming" ? (
                            <div className="pl-3">
                              <Table
                                size="small"
                                pagination={{
                                  defaultPageSize: 10,
                                  pageSizeOptions: ["30", "40"],
                                  showSizeChanger: false,
                                }}
                                columns={appointmentListColumn}
                                dataSource={doctorList}
                                scroll={{ x: 1000 }}
                                rowKey={(obj) => obj.id}
                              />
                            </div>
                          ) : selectedCard == "Previous" ? (
                            <div className="pl-3">
                              <Table
                                size="small"
                                pagination={{
                                  defaultPageSize: 10,
                                  pageSizeOptions: ["30", "40"],
                                  showSizeChanger: false,
                                }}
                                columns={PreviousappointmentListColumn}
                                dataSource={previousDoctorList}
                                scroll={{ x: 1000 }}
                                rowKey={(obj) => obj.id}
                              />
                            </div>
                          ) : selectedCard == "Prescriptions" ? (
                            <div className="pl-3">
                              <Table
                                dataSource={priscriptionsDetails}
                                size="small"
                                pagination={{
                                  defaultPageSize: 10,
                                  pageSizeOptions: ["30", "40"],
                                  showSizeChanger: false,
                                }}
                                columns={priscriptionsListColumn}
                                scroll={{ x: 1000 }}
                              />
                            </div>
                          ) : (
                            <Table
                              size="small"
                              pagination={{
                                defaultPageSize: 10,
                                pageSizeOptions: ["30", "40"],
                                showSizeChanger: false,
                              }}
                              columns={callBackColumn}
                              dataSource={callBackDetails}
                              scroll={{ x: 1000 }}
                            />
                          )}

                          {loader ? (
                            <></>
                          ) : (
                            <>
                              <Modal
                                centered
                                visible={pdfUrl?.length > 0 && isOpenPdfModal}
                                width={1000}
                                onCancel={() => {
                                  setPdfUrl(null)
                                  setIsOpenPdfModal(false)
                                }}
                              >
                                <Worker
                                  workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"
                                  // className={style.pdf_viever_padding}
                                >
                                  <div style={{ height: '750px' }}>
                                    <Viewer fileUrl={pdfUrl} />
                                  </div>
                                </Worker>
                              </Modal>
                            </>
                          )}

                          <Modal
                            centered
                            visible={isModalVisible}
                            width={1000}
                            onOk={() => setIsModalVisible(false)}
                            onCancel={() => setIsModalVisible(false)}
                            footer={null}
                          >
                            {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"> */}
                            {/* <div style={{ height: "100%", width: '100%' }}> */}
                            {/* <Viewer fileUrl={PdfString} /> */}
                            {/* </div> */}
                            {/* </Worker> */}

                            <div className="pt-4">
                              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                                <Viewer
                                  fileUrl={PdfString}
                                  plugins={[
                                    // Register plugins
                                    defaultLayoutPluginInstance,
                                  ]}
                                />
                              </Worker>
                            </div>
                          </Modal>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {addClick == true ? (
                <AddFamilyMember
                  title="Add Family Member"
                  open={familyModel}
                  close={familyModelClose}
                  getFamilyMember={getFamilyMember}
                  editFamilyMember={{}}
                />
              ) : null}

              {/* {editClick == true ? (
                <AddFamilyMember
                  title="Edit Family Member"
                  open={familyModel}
                  close={familyModelClose}
                  getFamilyMember={getFamilyMember}
                  editFamilyMember={selectedFamilyMember}
                />
              ) : null} */}
              {/* {click !== true ? (
                <AddFamilyMember
                  title="Add Family Member"
                  open={familyModel}
                  close={familyModelClose}
                  getFamilyMember={getFamilyMember}
                  editFamilyMember={{}}
                />
              ) : (
                <AddFamilyMember
                  title="Edit Family Member"
                  open={familyModel}
                  close={familyModelClose}
                  getFamilyMember={getFamilyMember}
                  editFamilyMember={selectedFamilyMember}
                />
              )
              } */}
              <PatientDetail
                // title="Appointment Detail"
                title=""
                open={isDetailVisible}
                close={patientDetailClose}
                appointmentId={selectAppointmentId}
                type={type}
              />
              {/* event modal */}
              <Modal
                title="Workshop Information"
                visible={eventModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={900}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="listinfo">
                      <strong>Faciliator Name :</strong>
                      {particluarEvent?.facilitator_name}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="listinfo">
                      <strong>Date :</strong>
                      {/* {moment(particluarEvent?.start_time).format("ll")} */}
                      {moment(particluarEvent?.start_time).format(
                        "DD-MMM-YYYY"
                      )}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="listinfo">
                      <strong>Start Time :</strong>
                      {moment(particluarEvent?.start_time).format("hh:mm A")}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="listinfo">
                      <strong>End Time :</strong>
                      {moment(particluarEvent?.end_time).format("hh:mm A")}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="listinfo">
                      <strong>Workshop Name :</strong>
                      {particluarEvent?.name}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="listinfo">
                      <strong>Description :</strong>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: particluarEvent?.description,
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="listinfo">
                      <strong>What will you learn :</strong>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: particluarEvent?.what_will_you_learn,
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="listinfo">
                      <strong>Recurrance :</strong>
                      {particluarEvent?.recurrence_frequency?.toUpperCase() ||
                        ""}
                    </div>
                  </div>
                </div>
              </Modal>
            </div>

            {/* Chat Modal */}
            {openChat ? (
              <div className={style.chat_popup} id="myForm">
                <div className={style.form_container}>
                  <div className="col-lg-12 col-xl-12" style={{ padding: 0 }}>
                    <div className="chat-window">
                      <div className="chat-cont-right">
                        <div className="chat-header">
                          <a
                            id="back_user_list"
                            href="#0"
                            className="back-user-list"
                          >
                            <i className="material-icons">chevron_left</i>
                          </a>
                          <div className="media">
                            <div className="media-img-wrap">
                              <div className="avatar avatar-online">
                                <img
                                  src={IMG011}
                                  alt="User"
                                  className="avatar-img rounded-circle"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <div className="user-name">Appointment with Dr {selectedChatHead?.doctorName}</div>
                              {/* <div className="user-name">
                                Appointment with Dr ABC
                              </div> */}
                              <div className="user-status">online</div>
                            </div>
                          </div>
                          <div className="chat-options">
                            <Button
                              className={style.btnTransparent}
                              onClick={handleCancelChatModal}
                            >
                              <img
                                src={IMG012}
                                alt="User"
                                className={`${style.cross_img} avatar-img`}
                              />
                            </Button>
                          </div>
                        </div>
                        {/* <div className="chat-body" style={{height : 650}}> */}
                        <div className="chat-body">
                          <div className="chat-scroll">
                            <ul className="list-unstyled">
                              {allMessages?.map((itemm, index) => (
                                <div key={index}>
                                  {parseInt(itemm?.author) === user?.id ? (
                                    <li className="media sent">
                                      <div className="media-body">
                                        <div className="msg-box">
                                          <div>
                                            <h6>
                                              {itemm?.authorName} (
                                              {itemm?.authorType})
                                            </h6>
                                            <p>{itemm?.content}</p>
                                            <ul className="chat-msg-info">
                                              <li>
                                                <div className="chat-time">
                                                  <span>
                                                    {getTime(itemm?.createdAt)}
                                                  </span>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ) : (
                                    <li className="media received" style={{marginTop : 10}}>
                                      <div className="avatar">
                                        <img
                                          src={IMG011}
                                          alt="User"
                                          className="avatar-img rounded-circle"
                                        />
                                      </div>
                                      <div className="media-body">
                                        <div className="msg-box">
                                          <div>
                                            <h6>
                                              {itemm?.authorName} (
                                              {itemm?.authorType})
                                            </h6>
                                            <p>{itemm?.content}</p>
                                            <ul className="chat-msg-info">
                                              <li>
                                                <div className="chat-time">
                                                  <span>
                                                    {getTime(itemm?.createdAt)}
                                                  </span>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  )}
                                </div>
                              ))}

                              <div ref={messagesEndRef}></div>
                            </ul>
                          </div>
                        </div>
                        <div className="chat-footer">
                          <form name="f2" id="f12">
                            <div className="input-group">
                              <input
                                type="text"
                                className="input-msg-send form-control"
                                placeholder="Type something"
                                value={messageInput}
                                onChange={(evt) =>
                                  setMessageInput(evt?.target?.value)
                                }
                              />
                              <div className="input-group-append">
                                <button
                                  type="submit"
                                  onClick={(e) => {
                                    sendMessage(e);
                                  }}
                                  className=" w-25 btn msg-send-btn"
                                >
                                  <i>
                                    <FontAwesomeIcon
                                      className="mr-1"
                                      icon={faShare}
                                    />
                                  </i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <form  >
                  <h1>Chat</h1>

                  <label><b>Message</b></label>
                  <textarea placeholder="Type message.." name="msg" required></textarea>

                  <div className="chat-footer">
                    <form name="f2" id="f12">
                      <button type="submit" className="btn">Send</button>
                    </form>
                  </div>
                </form> */}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <Modal
        footer={null}
        title="Note"
        visible={
          noteModal &&
          isProfileComplate === "false" &&
          showEdit &&
          (user?.Insurancesvcnum === "" || user?.Insurancesvcnum == null)
        }
        onCancel={handleCancelModal}
        width="1100px"
      >
        <div>Your profile is incomplete</div>
        <br />
        <div>
          An incomplete profile may prevent you from creating appointment with
          doctor
        </div>
        <br />
        <div>
          please update your{" "}
          <span style={{ color: "#1a6ab1" }}>
            Address, Date-of-birth, Gender, PhoneNumber, Ohip Card, City,
            CanadaProvinces
          </span>{" "}
          in your profile.
        </div>
        <br />

        <div className="d-flex justify-content-end">
          <Button
            type="primary"
            style={{ width: "200px" }}
            // loading={btnLoader}
            onClick={() => EditProfile()}
            // htmlType="submit"
          >
            Complete My Profile
          </Button>
        </div>
      </Modal>

      {/* <Modal
        footer={null}
        title="Note"
        style={{ top: 800,right : 10 }}
        visible={openChat}
        onCancel={handleCancelChatModal}
        // width="1100px"
        width="500px"
        
        >
        <div>
          Appointment With Dr Mdoctor
        </div>
      </Modal> */}

      <div className="footer">
        <Footer />
      </div>
    </>
  );
};
export default Dashboard;
